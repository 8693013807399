import SearchResults from './search-results';


class ServerSideSearchResults extends SearchResults {

	// constructor({
	// 	element,
	// 	root
	// }) {
	// 	super({element: element, root: root});
	// }

	// prepare() {
	// }


	processResults(results) {
		return new Promise((resolve) => {
			console.log('processing results', results);
			this.element.innerHTML = results.content;
			resolve(results);
		});
	}


}


export default ServerSideSearchResults;
