import TermCounter from './term-counter';
import GlossaryFilter from './glossary-filter';



export default (di) => {
	di
		.setType({type: TermCounter, name: 'TermCounter', parent: 'PageComponent'})
		.setType({type: GlossaryFilter, name: 'GlossaryFilter', parent: 'PageComponent'})

	;
};
