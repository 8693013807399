/* eslint-disable */

export const PlotTypes = {
	INDICATOR_VS_TIMESLICES: 'indicator_vs_timeslices',
	INDICATOR_VS_TIMELINE: 'indicator_vs_timeline',
	INDICATOR_VS_TEMPERATURE: 'indicator_vs_temperature'
};

export const Aspects = {
	median: 'median',
	mean: 'mean', // yes the data contains both :-( we will normalize to median
	shading_upper_border: 'shading_upper_border',
	shading_lower_border: 'shading_lower_border'
};

export const SetTypes = {
	individualSimulation: 'individualSimulation',
	gcmMedian: 'gcmMedian',
	multiModelMedian: 'multiModelMedian',
	interAnnualVariability: 'interAnnualVariability'
};

const blankTick = '';

/**
 * A function to attach to a dataset to calculate an rgba value.
 * @param rgb base rgb value
 * @param opacity
 * @return {string}
 */

const rgbaCalc = (rgb, opacity = 1) => `rgba(${rgb.concat(opacity).join(',')})`;

const padArrayFrom = (existing, from, padBy = 2, value = blankTick) => {
	const padded = [];
	for (let i = 0 ; i < existing.length; i++) {
		padded.push(existing[i]);
		if (i >= from) {
			for (let j = 0; j < padBy; j++) {
				padded.push(value);
			}
		}
	}
	return padded;
};

/*
 *  Munge the JSON from the data cube into a more generic, charting-friendly format
 */
export function preparePlotData(data, config) {
	const {yearConstraints, scenario: scenarioMap} = config;
	let xAxis = [];
	let xAxisOriginal = [];
	let cropXStart = 0;
	let cropXEnd = 0;
	let scenarios = data.climate_scenario_list;
	const impactModels = data.impact_model_list;
	let climateModels = data.climate_model_list;
	const datasets = [];
	const pointData = data.data;
	const aspects = Object.keys(Aspects);

	const updateYearConstraints = () => {
		if (yearConstraints) {
			const startXAtIdx = xAxis.findIndex(v => v > yearConstraints.min);
			cropXStart = startXAtIdx === -1 ? cropXStart : startXAtIdx;
			const stopXAtIdx = xAxis.findIndex(v => v > yearConstraints.max);
			cropXEnd = stopXAtIdx === -1 ? cropXEnd : stopXAtIdx;
		}
	};

	const getDataPoints = keys => {
		let pts = pointData;
		// find the node in the object tree, if we can
		for (const k of keys) {
			pts = pts[k];
			if (!pts) {
				return false;
			}
		}
		let vIdx = 0;
		// create the data points
		return xAxis.map((tick, idx) => {
			let v;
			if (tick === blankTick) {
				v = null;
			} else {
				v = pts[vIdx];
				vIdx++;
			}
			return {
				x: tick,
				y: v === null ? NaN : v
			}
		}).slice(cropXStart, cropXEnd);
	};

	const addDataset = (keys, aspect, rgba, filters = {}, type) => {
		if (aspect === Aspects.mean) {
			aspect = Aspects.median;
		}
		const pts = getDataPoints(keys);
		if (pts === false) return;
		const normalizedFilters = Object.assign({
			scenario: 'median',
			climateModel: 'median',
			impactModel: 'median'
		}, filters);
		if (normalizedFilters.climateModel === 'overall') {
			normalizedFilters.climateModel = 'median';
		}
		if (normalizedFilters.scenario === 'overall') {
			normalizedFilters.scenario = 'median';
		}
		const fillKey = filters.scenario ? filters.scenario : filters.climateModel;
		datasets.push({
			data: pts,
			aspect: aspect,
			rgba: rgba,
			fillKey: aspect === Aspects.median ? fillKey : false,
			spreadKey: keys.slice(0, keys.length - 1).join('-'),
			filters: normalizedFilters,
			isiType: type
		});
	};

	if (data.plot_type === PlotTypes.INDICATOR_VS_TEMPERATURE) {
		xAxis = xAxisOriginal = data.temperature_list;
		cropXEnd = xAxis.length;
		climateModels.unshift('overall');
		climateModels.forEach(climateModel => {
			aspects.forEach(aspect => {
				addDataset(
					[climateModel, aspect],
					aspect,
					rgbaCalc.bind(null, scenarioMap.all.color.rgb),
					{
						climateModel: climateModel
					},
					aspect === Aspects.median? (climateModel === 'overall'? SetTypes.multiModelMedian : SetTypes.gcmMedian) : SetTypes.interAnnualVariability
				);
			});
			if (climateModel === 'overall') return;
			impactModels.forEach(impactModel => {
				aspects.forEach(aspect => {
					addDataset(
						[climateModel, 'runs', impactModel, aspect],
						aspect,
						rgbaCalc.bind(null, scenarioMap.all.color.rgb),
						{
							climateModel: climateModel,
							impactModel: impactModel
						},
						SetTypes.individualSimulation
					);
				});
			});
		});
	} else if (data.plot_type === PlotTypes.INDICATOR_VS_TIMESLICES) {
		xAxis = data.timeslices_list;
		xAxisOriginal = xAxis.map(v => `${v[0]}-${v[1]}`);
		cropXEnd = xAxis.length;
		xAxis = xAxis.map(slice => {
			const mid = slice[0] + ((slice[1] - slice[0]) / 2);
			return Math.round(Math.round(mid) / 10) * 10;
		});
		// add some fake ticks to compress historical data (by expanding the future)
		const cutoff = xAxis.indexOf(2010);
		xAxis = padArrayFrom(xAxis, cutoff);
		xAxisOriginal = padArrayFrom(xAxisOriginal, cutoff);
		updateYearConstraints();
		cropXEnd -= 2;
		// For all climate models we have
		// pointData[scenario][climateModel][aspect][n points]
		// For all climate models except “overall” we also have runs:
		// pointData[scenario][climateModel][runs][impactModel][aspect][n points]
		climateModels.unshift('overall');
		scenarios.forEach(scenario => {
			if (scenario === 'piControl') return;
			climateModels.forEach(climateModel => {
				aspects.forEach(aspect => {
					addDataset(
						[scenario, climateModel, aspect],
						aspect,
						rgbaCalc.bind(null, scenarioMap[scenario].color.rgb),
						{
							scenario: scenario,
							climateModel: climateModel
						},
						aspect === Aspects.median? (climateModel === 'overall'? SetTypes.multiModelMedian : SetTypes.gcmMedian) : SetTypes.interAnnualVariability
					);
				});
				if (climateModel === 'overall') return;
				impactModels.forEach(impactModel => {
					aspects.forEach(aspect => {
						addDataset(
							[scenario, climateModel, 'runs', impactModel, aspect],
							aspect,
							rgbaCalc.bind(null, scenarioMap[scenario].color.rgb),
							{
								scenario: scenario,
								climateModel: climateModel,
								impactModel: impactModel
							},
							SetTypes.individualSimulation
						);
					});
				});
			});
		});
	}

	return {
		type: data.plot_type,
		datasets: datasets,
		xAxis: xAxis.slice(cropXStart, cropXEnd),
		xAxisOriginal: xAxisOriginal.slice(cropXStart, cropXEnd),
		labelX: data.plot_label_x,
		labelY: `${data.plot_label_y} (${data.plot_unit_y})`,
		tipY: data.plot_unit_y,
		title: data.plot_title
	};
}


