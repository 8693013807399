import PageComponent from '../../common/component/page-component';


class TermCounter extends PageComponent {

	constructor({
		root,
		element,
		termsAttribute = 'terms',
		glossaryColumnModifier = 'multiColumned'
	}) {
		super({root: root, element: element});
		this.termsAttribute = termsAttribute;
		this.glossaryColumnModifier = glossaryColumnModifier;
	}


	prepare() {
		const termsNode = this.element.querySelectorAll(this.dataSelector(this.termsAttribute));
		for (const glossaryTermNode of termsNode) {
			const childrenAmount = glossaryTermNode.children.length;

			if (childrenAmount > 3) {
				this.classList(glossaryTermNode).add(this.glossaryColumnModifier);
			}
		}
	}
}


export default TermCounter;
