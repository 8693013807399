import httpRequest from 'superagent';
import responseMethods from './response-methods';


class Api {
    constructor({requestFactory, baseUrl}) {
		this.requestFactory = requestFactory;
        this.baseUrl = baseUrl;
	}


    execute(action, params = {}) {
		const remoteCall = httpRequest
			.post(this.baseUrl + '/' + action)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Accept', 'application/json')
		;
		remoteCall.send(params);
		const remoteCallPromise = remoteCall.then((response) => {
			const body = 'body' in response ? response.body : {};
			return Object.assign({}, responseMethods, body);
		});
		return this.requestFactory({remoteCall: remoteCall, remoteCallPromise: remoteCallPromise, action: action, params: params});
	}

}


export default Api;
