import {trim} from '../../common/utils/string';
import PageComponent from '../../common/component/page-component';


class ConfiguratorSearch extends PageComponent {

	constructor({
		root,
		element,
		inputAttribute = 'searchInput',
		cancelAttribute = 'searchCancel',
		forceHiddenAttribute = 'searchForceHidden',
		highlightClass = 'highlight',
		filledClass = 'filled',
		typeDelay = 0.25, // sec.
		forceHidden = true
	}) {
		super({root: root, element: element});
		this.inputAttribute = inputAttribute;
		this.cancelAttribute = cancelAttribute;
		this.forceHiddenAttribute = forceHiddenAttribute;
		this.highlightClass = highlightClass;
		this.filledClass = filledClass;
		this.typeDelay = typeDelay;
		this.defaults[this.forceHiddenAttribute] = forceHidden;

		this.typeDelayMs = typeDelay * 1000;
		this.typeTimeout = null;

		this.input = null;
		this.index = null;
		this.lastSearchString = '';
		this.isMobile = false;
	}


	prepare() {
		this.input = this.element.querySelector(this.dataSelector(this.inputAttribute));
		this.forceHidden = this.dataAttr().get(this.forceHiddenAttribute);

		this.listeners.focusin = this.events.on(this.input, 'focusin', this.onFocus.bind(this));
		this.listeners.focusout = this.events.on(this.input, 'focusout', this.onBlur.bind(this));
		this.listeners.change = this.events.on(this.input, 'keyup', this.onChange.bind(this));
		this.listeners.cancel = this.events.on(this.element.querySelector(this.dataSelector(this.cancelAttribute)), 'click', this.onCancel.bind(this));
	}


	onFocus(event) {
		this.events.trigger(this.element, 'search:focus');
	}


	onBlur(event) {
		this.events.trigger(this.element, 'search:blur');
	}


	onChange(event) {
		const trimmedValue = trim(this.input.value);
		this.classList().toggle(this.filledClass, trimmedValue.length > 0);
		if (this.index !== null) {
			if (this.typeTimeout) {
				clearTimeout(this.typeTimeout);
			}
			this.typeTimeout = setTimeout(() => {
					this.search(trim(trimmedValue));
				},
				this.typeDelayMs
			);
		}
	}


	onCancel(event) {
		this.cancel();
	}


	setIndex(index) {
		this.index = index;
	}


	cancel() {
		if (this.typeTimeout) {
			clearTimeout(this.typeTimeout);
			this.typeTimeout = null;
		}
		this.classList().remove(this.filledClass);
		this.input.value = '';
		this.search('');
		this.events.trigger(this.element, 'search:cancel');
	}


	search(searchString) {
		searchString = searchString.toLowerCase();
		if (searchString !== this.lastSearchString) {
			this.lastSearchString = searchString;
			if (searchString.length) {
				this.sort(searchString);
			}
			this.update(searchString);
		}
	}


	sort(searchString) {
		const end = this.index.length;
		for (let i = 0; i < end; i++) {
			this.index[i].matchingPosition = this.index[i].searchText.indexOf(searchString);
			this.index[i].displayMatchingPosition = (this.index[i].searchText !== this.index[i].lcText ? this.index[i].lcText.indexOf(searchString) : this.index[i].matchingPosition);
		}

		this.index.sort((a, b) => (a.matchingPosition - b.matchingPosition));
	}


	update(searchString) {
		const len = searchString.length;
		const end = this.index.length;
		let position = 1;
		for (let i = 0; i < end; i++) {
			const entry = this.index[i];
			const hidden = (len > 0 && entry.matchingPosition === -1);
			entry.itemNode.style.display = (hidden ? 'none' : 'block');

			if (len > 0 && !hidden) {
				entry.itemNode.style.order = position;
				if (entry.displayMatchingPosition >= 0) {
					const highlighted = document.createElement('span');
					this.classList(highlighted).add(this.highlightClass);
					highlighted.textContent = entry.text.substr(entry.displayMatchingPosition, len);
					entry.labelNode.innerHTML = '';
					if (entry.displayMatchingPosition > 0) {
						entry.labelNode.appendChild(document.createTextNode(entry.text.substr(0, entry.displayMatchingPosition)));
					}
					entry.labelNode.appendChild(highlighted);
					if (entry.displayMatchingPosition + len < entry.text.length) {
						entry.labelNode.appendChild(document.createTextNode(entry.text.substr(entry.displayMatchingPosition + len)));
					}
				} else {
					entry.labelNode.textContent = entry.text;
				}
				position ++;
			} else {
				if (len === 0 || !this.forceHidden) {
					entry.itemNode.style.removeProperty('display');
				}
				entry.itemNode.style.removeProperty('order');
				entry.labelNode.textContent = entry.text;
			}
		}
	}

}

export default ConfiguratorSearch;
