import {TweenMax} from 'gsap';
import 'gsap/ScrollToPlugin';
import {trim} from '../../common/utils/string';
import PageComponent from '../../common/component/page-component';
import constants from './constants';


class Configurator extends PageComponent {

	constructor({
		root,
		element,
		inputGroupAttribute = 'inputGroup',
		inputPkAttribute = 'inputPk',
		inputNameAttribute = 'inputName',
		inputAreaType = 'inputAreaType',
		activeAreasCountAttribute = 'activeAreasCount',
		activeAreasCountLabelsAttribute = 'activeAreasCountLabels',
		topicsIndicatorsAttribute = 'topicsIndicators',
		indicatorAttribute = 'indicator',
		areaAttribute = 'area',
		searchTextAttribute = 'searchText',
		searchComponentAttribute = 'searchGroup',
		searchLabelAttribute = 'searchLabel',
		searchIndicatorIdAttribute = 'searchIndicatorId',
		searchTopicIdAttribute = 'searchTopicId',
		indicatorTopicsAttribute = 'indicatorTopics',
		baseUrlAttribute = 'baseUrl',
		baseReportUrlAttribute = 'baseReportUrl',
		emptyPlaceholderAttribute = 'emptyPlaceholder',
		selectedNameSlotAttribute = 'selectedNameSlot',
		selectedIndicatorsCountAttribute = 'selectedIndicatorsCount',
		controlsAttribute = 'controls',
		typeDescriptionAttribute = 'typeDescription',
		enabledClass = 'enabled',
		disabledClass = 'disabled',
		selectedClass = 'selected',
		emptyClass = 'empty',
	}) {
		super({root: root, element: element});
		this.inputGroupAttribute = inputGroupAttribute;
		this.inputPkAttribute = inputPkAttribute;
		this.inputNameAttribute = inputNameAttribute;
		this.inputAreaType = inputAreaType;
		this.activeAreasCountAttribute = activeAreasCountAttribute;
		this.activeAreasCountLabelsAttribute = activeAreasCountLabelsAttribute;
		this.topicsIndicatorsAttribute = topicsIndicatorsAttribute;
		this.indicatorAttribute = indicatorAttribute;
		this.areaAttribute = areaAttribute;
		this.searchTextAttribute = searchTextAttribute;
		this.searchLabelAttribute = searchLabelAttribute;
		this.searchComponentAttribute = searchComponentAttribute;
		this.searchIndicatorIdAttribute = searchIndicatorIdAttribute;
		this.searchTopicIdAttribute = searchTopicIdAttribute;
		this.indicatorTopicsAttribute = indicatorTopicsAttribute;
		this.baseUrlAttribute = baseUrlAttribute;
		this.baseReportUrlAttribute = baseReportUrlAttribute;
		this.emptyPlaceholderAttribute = emptyPlaceholderAttribute;
		this.selectedNameSlotAttribute = selectedNameSlotAttribute;
		this.selectedIndicatorsCountAttribute = selectedIndicatorsCountAttribute;
		this.controlsAttribute = controlsAttribute;
		this.typeDescriptionAttribute = typeDescriptionAttribute;
		this.enabledClass = enabledClass;
		this.disabledClass = disabledClass;
		this.selectedClass = selectedClass;
		this.emptyClass = emptyClass;

		this.itemsById = new Map();
		this.itemsById.set(constants.type, new Map());
		this.itemsById.set(constants.area, new Map());
		this.itemsById.set(constants.topic, new Map());
		this.itemsById.set(constants.indicator, new Map());

		this.itemsByPk = new Map();
		this.itemsByPk.set(constants.type, new Map());
		this.itemsByPk.set(constants.area, new Map());
		this.itemsByPk.set(constants.topic, new Map());
		this.itemsByPk.set(constants.indicator, new Map());

		this.searchIndex = {};
		this.searchIndex[constants.area] = [];
		this.searchIndex[constants.indicator] = [];
		this.search = [];

		this.itemsByNode = new Map();

		this.topicsIndicatorsMap = {};

		this.selectedValues = new Map();
		this.selectedValues.set(constants.type, constants.empty);
		this.selectedValues.set(constants.area, constants.empty);
		this.selectedValues.set(constants.topic, constants.empty);
		this.selectedValues.set(constants.indicator, []);

		this.nameSlots = {};
		this.indicatorsCount = [];

		this.selectedTypeDescription = null;

		this.request = null;
		this.frame = null;
		this.baseUrl = '';
		this.baseReportUrl = '';
	}


	injectApi(api) {
		this.api = api;
	}


	injectHistory(history) {
		this.history = history;
	}


	prepare() {
		const dataAttr = this.dataAttr();
		this.baseUrl = dataAttr.get(this.baseUrlAttribute);
		this.baseReportUrl = dataAttr.get(this.baseReportUrlAttribute);
		this.emptyPlaceholder = dataAttr.get(this.emptyPlaceholderAttribute);

		this.activeAreasCount = this.element.querySelector(this.dataSelector(this.activeAreasCountAttribute));
		console.log('labels', this.activeAreasCountAttr, this.activeAreasCountLabels);

		this.activeAreasCountLabels = this.dataAttr(this.activeAreasCount).get(this.activeAreasCountLabelsAttribute);

		const inputs = this.element.querySelectorAll(this.dataSelector(this.inputGroupAttribute));
		for (const node of inputs) {
			const data = this.dataAttr(node);
			const groupName = data.get(this.inputGroupAttribute);
			const id = node.value;
			const pk = data.get(this.inputPkAttribute);
			const name = data.get(this.inputNameAttribute);
			const entry = {
				groupName: groupName,
				pk: pk,
				id: id,
				name: name,
				node: node,
				enabled: true
			};
			if (groupName === constants.area) {
				entry.type = data.get(this.inputAreaType);
			} else if (groupName === constants.type) {
				entry.descriptionNode = this.element.querySelector(this.dataSelector(this.typeDescriptionAttribute, entry.id));
			}
			this.itemsById.get(groupName).set(id, entry);
			this.itemsByPk.get(groupName).set(pk, entry);
			this.itemsByNode.set(node, entry);

			if (groupName === constants.area) {
				entry.itemNode = node.closest(this.dataSelector(this.areaAttribute, id));
				entry.searchText = this.dataAttr(entry.itemNode).get(this.searchTextAttribute);
				entry.labelNode = entry.itemNode.querySelector(this.dataSelector(this.searchLabelAttribute));
				if (entry.labelNode) {
					entry.text = trim(entry.labelNode.textContent);
					entry.lcText = entry.text.toLowerCase();
					entry.matchingPosition = -1;
					this.searchIndex[constants.area].push(entry);
				}
			}
		}

		const indicatorSearchItems = this.element.querySelectorAll(this.dataSelector(this.searchIndicatorIdAttribute));
		for (const itemNode of indicatorSearchItems) {
			const data = this.dataAttr(itemNode);
			const labelNode = itemNode.querySelector(this.dataSelector(this.searchLabelAttribute));
			const text = trim(labelNode.textContent);

			const entry = {
				groupName: constants.indicator,
				topicGroupName: constants.topic,
				id: data.get(this.searchIndicatorIdAttribute),
				topicId: data.get(this.searchTopicIdAttribute),
				searchText: this.dataAttr(itemNode).get(this.searchTextAttribute),
				itemNode: itemNode,
				labelNode: labelNode,
				text: text,
				lcText: text.toLowerCase(),
				matchingPosition: -1
			};
			this.searchIndex[constants.indicator].push(entry);
		}


		const indicatorItems = this.element.querySelectorAll(this.dataSelector(this.indicatorAttribute));
		for (const indicatorItem of indicatorItems) {
			const data = this.dataAttr(indicatorItem);
			const indicatorId = data.getRaw(this.indicatorAttribute);
			const topicIds = data.get(this.indicatorTopicsAttribute);
			for (const topicId of topicIds) {
				if (!(topicId in this.topicsIndicatorsMap)) {
					this.topicsIndicatorsMap[topicId] = new Map();
				}
				this.topicsIndicatorsMap[topicId].set(indicatorId, indicatorItem);
			}
		}
		this.disableEmptyTopics();

		const nameSlots = this.element.querySelectorAll(this.dataSelector(this.selectedNameSlotAttribute));
		for (const slot of nameSlots) {
			const name = this.dataAttr(slot).get(this.selectedNameSlotAttribute);
			if (!(name in this.nameSlots)) {
				this.nameSlots[name] = [];
			}
			this.nameSlots[name].push(slot);
		}

		this.indicatorsCount = this.element.querySelectorAll(this.dataSelector(this.selectedIndicatorsCountAttribute));
		this.areaScrollBox = this.element.querySelector(this.dataSelector(this.controlsAttribute, constants.area));

		this.sections = this.getComponent('ConfiguratorSections');
		this.summary = this.getComponent('ConfiguratorSummary');
		this.map = this.getComponent('ConfiguratorMap');
		this.search[constants.area] = this.queryComponent(this.dataSelector(this.searchComponentAttribute, constants.area));
		this.search[constants.area].setIndex(this.searchIndex[constants.area]);
		this.search[constants.indicator] = this.queryComponent(this.dataSelector(this.searchComponentAttribute, constants.indicator));
		this.search[constants.indicator].setIndex(this.searchIndex[constants.indicator]);

		const urlParams = this.getUrlParams(window.location.href);
		this.requestUpdate(urlParams, true).then(() => {
			let changeSection = false;
			if (this.selectedValues.get(constants.topic) !== constants.empty) {
				changeSection = constants.topic;
			} else if (this.selectedValues.get(constants.area) !== constants.empty) {
				changeSection = constants.area;
			} else if (this.selectedValues.get(constants.type) !== constants.empty) {
				changeSection = constants.type;
			}
			if (changeSection !== false) {
				this.sections.changeSection(changeSection, true);
			}

			// this.sections.changeSection('topic', true);

			this.map.on('load').then(() => {
				this.updateMapItems();
				this.listeners.areaClick = this.events.on(this.element, 'area:click', this.onAreaClick.bind(this));
			});
		});

		this.topicsToggler = this.queryComponent(this.dataSelector('for', 'topicControls'));
		this.indicatorsToggler = this.queryComponent(this.dataSelector('for', 'indicatorControls'));

		this.listeners.inputChange = this.events.on(this.element, this.dataSelector(this.inputGroupAttribute), 'change', this.onInputChange.bind(this));
		this.listeners.searchCancel = this.events.on(this.element, 'search:cancel', this.onSearchCancel.bind(this));
		this.listeners.searchIndicatorSelect = this.events.on(this.element, this.dataSelector(this.searchIndicatorIdAttribute), 'click', this.onIndicatorSearchClick.bind(this));
	}


	clear() {
		this.sections.clear();
		this.itemsById.clear();
		this.itemsByPk.clear();
		this.itemsByNode.clear();
		this.selectedValues.clear();
		this.topicsIndicatorsMap = null;

		if (this.request) {
			this.request.abort();
		}
	}


	onInputChange(event, target) {
		const entry = this.itemsByNode.get(target);
		this.select(entry, target.checked, false);
	}


	onAreaClick(event) {
		const pk = event.detail.pk;
		const areas = this.itemsByPk.get(constants.area);
		if (areas.has(pk)) {
			this.select(areas.get(pk), true, true);
		}
	}


	onIndicatorSearchClick(event, target) {
		const data = this.dataAttr(target);
		const topicId = data.getRaw(this.searchTopicIdAttribute);
		const indicatorId = data.getRaw(this.searchIndicatorIdAttribute);
		this.select(this.itemsById.get(constants.topic).get(topicId), true).then(
			() => this.select(this.itemsById.get(constants.indicator).get(indicatorId), true)
		);
	}


	onSearchCancel(event, target) {
		const name = this.dataAttr(target).get(this.searchComponentAttribute);
		if (name === constants.area && this.selectedValues.get(constants.area) !== constants.empty) {
			this.scrollAreaIntoView(this.itemsById.get(constants.area).get(this.selectedValues.get(constants.area)));
		}
	}


	getUrlParams(url) {
		const params = {};
		params[constants.type] = constants.empty;
		params[constants.area] = constants.empty;
		params[constants.topic] = constants.empty;
		params[constants.indicator] = [];

		const path = trim(url.substr(this.baseUrl.length), '/');
		const parts = path.split(constants.urlParamSeparator);
		for (const part of parts) {
			const subParts = part.split(constants.urlKeySeparator);
			if (subParts.length > 1) {
				const key = subParts.shift();
				if (key in constants.urlKeys) {
					const name = constants.urlKeys[key];
					const pk = subParts.join(constants.urlKeySeparator);
					if (this.itemsByPk.get(name).has(pk)) {
						const id = this.itemsByPk.get(name).get(pk).id;
						if (name === constants.indicator) {
							if (params[name].indexOf(id) === -1) {
								params[name].push(id);
							}
						} else {
							params[name] = id;
						}
					}
				}
			}
		}
		return params;
	}


	extractSelectedValues() {
		const selected = {};
		selected[constants.type] = this.selectedValues.get(constants.type);
		selected[constants.area] = this.selectedValues.get(constants.area);
		selected[constants.topic] = this.selectedValues.get(constants.topic);
		selected[constants.indicator] = this.selectedValues.get(constants.indicator).slice();
		return selected;
	}


	select(entry, checked, scrollIntoView = false) {
		if (this.request) {
			this.request.abort();
		}

		const selected = this.extractSelectedValues();

		let queryRequired = false;
		if (entry.groupName === constants.topic) {
			const changed = checked === false || entry.id !== selected[constants.topic];
			if (changed) {
				this.toggleTopicIndicators(selected[constants.topic], false);
				selected[constants.topic] = checked ? entry.id : constants.empty;
				this.toggleTopicIndicators(selected[constants.topic], checked);
				if (selected[constants.indicator].length) {
					selected[constants.indicator] = [];
					queryRequired = true;
				} else {
					this.selectedValues.set(constants.topic, selected[constants.topic]);
					this.updateSelected();
				}
			}
			if (checked) {
				entry.node.checked = true;
				this.search[constants.indicator].cancel();
				this.topicsToggler.toggle(true);
				this.classList(this.indicatorsToggler.getElement()).remove(this.disabledClass);
				this.indicatorsToggler.toggle(false);
			} else {
				this.indicatorsToggler.toggle(true);
				this.classList(this.indicatorsToggler.getElement()).add(this.disabledClass);
			}
		} else {
			queryRequired = true;
			if (entry.groupName === constants.indicator) {
				if (checked) {
					this.search[constants.indicator].cancel();
					if (selected[constants.indicator].indexOf(entry.id) === -1) {
						selected[constants.indicator].push(entry.id);
					}
					entry.node.checked = true;
				} else {
					const pos = selected[constants.indicator].indexOf(entry.id);
					if (pos >= 0) {
						selected[constants.indicator].splice(pos, 1);
					}
				}
			} else {
				if (entry.groupName === constants.area) {
					entry.node.checked = checked;
					if (checked) {
						if (selected[entry.groupName] !== constants.empty) {
							this.itemsById.get(entry.groupName).get(selected[entry.groupName]).node.checked = false;
						}
						if (scrollIntoView) {
							this.scrollAreaIntoView(entry);
						}
					}
				}
				selected[entry.groupName] = checked ? entry.id : constants.empty;
			}
		}
		if (queryRequired) {
			return this.requestUpdate(selected);
		}
		return Promise.resolve();
	}


	removeSelected(name, id) {
		const entry = this.itemsById.get(name).get(id);
		entry.node.checked = false;
		this.select(entry, false);
	}


	requestUpdate(selected, checkSelected = false) {
		this.request = this.api.execute('report/getAvailableCombinations', {selected: selected});
		return this.request.then((response) => {
			if (!this.request.isAborted()) {
				this.request = null;
				this.selectedValues.set(constants.type, selected.type);
				this.selectedValues.set(constants.area, selected.area);
				this.selectedValues.set(constants.topic, selected.topic);
				this.selectedValues.set(constants.indicator, selected.indicator);
				if (response.isSuccess()) {
					this.updateInputs(response.output, checkSelected);
					this.updateSelected();
				}
			}
		});
	}


	updateInputs(groups, checkSelected = false) {
		if (this.frame) {
			cancelAnimationFrame(this.frame);
		}
		this.frame = requestAnimationFrame(() => {
			let activeAreasCount = 0;
			for (const [node, entry] of this.itemsByNode) {
				if (entry.groupName in groups) {
					const enabled = (entry.id in groups[entry.groupName]);
					entry.enabled = enabled;
					node.disabled = !enabled;
					if (entry.groupName === constants.area && entry.enabled) {
						activeAreasCount++;
					}
				}
				let selected = null;
				if (checkSelected) {
					selected = (entry.groupName === constants.indicator ?
						this.selectedValues.get(entry.groupName).indexOf(entry.id) >= 0 :
						this.selectedValues.get(entry.groupName) === entry.id
					);
					node.checked = selected;
					if (entry.groupName === constants.topic && selected) {
						this.toggleTopicIndicators(entry.id, true);
					}
				}
				if (entry.groupName === constants.area) {
					this.map.updateItem(entry.pk, entry.enabled, selected);
				}
			}
			this.activeAreasCount.textContent = activeAreasCount + ' ' + this.activeAreasCountLabels[activeAreasCount === 1 ? 1 : 0];
		});
	}


	updateSelected() {
		for (const [name, value] of this.selectedValues) {
			if (name !== constants.indicator) {
				if (name === constants.topic) {
					if (this.selectedValues.get(constants.topic) !== constants.empty) {
						this.topicsToggler.toggle(true);
						this.classList(this.indicatorsToggler.getElement()).remove(this.disabledClass);
						this.indicatorsToggler.toggle(false);
					} else {
						this.indicatorsToggler.toggle(true);
						this.classList(this.indicatorsToggler.getElement()).add(this.disabledClass);
					}
				}
				// update names slots
				const nameValue = (value !== constants.empty ?
					this.itemsById.get(name).get(value).name :
					this.emptyPlaceholder
				);
				for (const slot of this.nameSlots[name]) {
					slot.textContent = nameValue;
					this.classList(slot.parentNode).toggle(this.emptyClass, value === constants.empty);
				}
				if (name === constants.area) {
					this.map.updateSelected(value === constants.empty ? null : this.itemsById.get(name).get(value).pk);
				}
				if (name === constants.type) {
					if (this.selectedTypeDescription) {
						this.classList(this.selectedTypeDescription).remove(this.selectedClass);
						this.selectedTypeDescription = null;
					}
					if (value !== constants.empty) {
						const descriptionNode = this.itemsById.get(name).get(value).descriptionNode;
						if (descriptionNode) {
							this.classList(descriptionNode).add(this.selectedClass);
							this.selectedTypeDescription = descriptionNode;
						}
					}
				}
			} else {
				// update counters
				for (const indicatorsCount of this.indicatorsCount) {
					indicatorsCount.textContent = value.length;
				}
			}
		}
		this.summary.updateSelected(this.selectedValues);
		this.updateAction();
		this.updateUrl();
	}


	updateAction() {
		const getPk = (name, value) => (value === constants.empty ? '' : this.itemsById.get(name).get(value).pk);

		const parts = [
			getPk(constants.type, this.selectedValues.get(constants.type)),
			getPk(constants.area, this.selectedValues.get(constants.area)),
			getPk(constants.topic, this.selectedValues.get(constants.topic)),
			...this.selectedValues.get(constants.indicator).map((value) => getPk(constants.indicator, value))
		];

		let validCount = 0;
		for (const part of parts) {
			if (part === '') {
				break;
			}
			validCount++;
		}
		const valid = validCount >= 4;
		this.summary.updateAction(this.baseReportUrl + '/' + parts.join('.'), valid);
		this.summary.toggleReady(valid);
	}


	updateUrl() {
		if (this.history.isSupported()) {
			const parts = [];
			for (const key in constants.urlKeys) {
				if (constants.urlKeys.hasOwnProperty(key)) {
					const name = constants.urlKeys[key];
					let values = this.selectedValues.get(name);
					if (name !== constants.indicator) {
						values = [values];
					}
					for (const value of values) {
						if (value !== constants.empty) {
							const pk = this.itemsById.get(name).get(value).pk;
							parts.push(key + constants.urlKeySeparator + pk);
						}
					}
				}
			}
			const paramsStr = parts.join(constants.urlParamSeparator);
			const url = this.baseUrl + (paramsStr.length ? '/' + paramsStr : '');
			this.history.replace(url);
		}
	}


	updateMapItems() {
		for (const entry of this.itemsById.get(constants.area).values()) {
			this.map.updateItem(entry.pk, entry.enabled, entry.id === this.selectedValues.get(constants.area));
		}
	}



	toggleTopicIndicators(topicId, value) {
		if (topicId !== constants.empty) {
			const map = this.topicsIndicatorsMap[topicId];
			for (const [indicatorId, indicatorItem] of map) {
				this.classList(indicatorItem).toggle(this.enabledClass, value);
				if (value === false) {
					this.itemsById.get(constants.indicator).get(indicatorId).node.checked = false;
				}
			}
		}
	}


	getEntry(name, id) {
		return this.itemsById.get(name).get(id);
	}


	disableEmptyTopics() {
		for (const [id, entry] of this.itemsById.get(constants.topic)) {
			if (!(id in this.topicsIndicatorsMap)) {
				entry.node.disabled = true;
			}
		}
	}


	scrollAreaIntoView(entry) {
		TweenMax.to(this.areaScrollBox, 0.4, {scrollTo: {y: entry.node.parentNode}});
	}

}

export default Configurator;
