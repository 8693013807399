import httpRequest from 'superagent';
import PageComponent from '../../common/component/page-component';
import plotRelatedMixin from './plot-related-mixin';


class CountryRankingMap extends plotRelatedMixin(PageComponent) {

	constructor({
		root,
		element,
		variablesAttribute = 'variables',
		imageUrlAttribute = 'imageUrl',
		imageContainerAttribute = 'imageContainer',
		typeIdAttribute = 'typeId',
		indicatorIdAttribute = 'indicatorId',
		areaIdAttribute = 'areaId',
		gridAttribute = 'grid',
		gridTileAttribute = 'gridTile',
		mapAreaAttribute = 'mapArea',
		layersAttribute = 'layers',
		canvasAttribute = 'canvas',

		visibleClass = 'visible',
		showClass = 'show',
		useGlobalRange = true,
	}) {
		super({root: root, element: element});
		this.variablesAttribute = variablesAttribute;
		this.imageUrlAttribute = imageUrlAttribute;
		this.imageContainerAttribute = imageContainerAttribute;
		this.typeIdAttribute = typeIdAttribute;
		this.indicatorIdAttribute = indicatorIdAttribute;
		this.areaIdAttribute = areaIdAttribute;
		this.gridAttribute = gridAttribute;
		this.gridTileAttribute = gridTileAttribute;
		this.mapAreaAttribute = mapAreaAttribute;
		this.layersAttribute = layersAttribute;
		this.canvasAttribute = canvasAttribute;

		this.visibleClass = visibleClass;

		this.showClass = showClass;

		this.useGlobalRange = useGlobalRange;
		this.request = null;
		this.dummy = false;
		this.maxRangeValue = null;
	}


	injectApi(api) {
		this.api = api;
	}


	setMapLegend(mapLegend) {
		this.mapLegend = mapLegend;
	}


	prepare() {
		const data = this.dataAttr();
		this.imageUrl = data.get(this.imageUrlAttribute);
		const variables = data.get(this.variablesAttribute);
		this.varIds = [];
		for (const variable of variables) {
			this.varIds.push(variable.id);
		}
		this.indicatorId = data.getRaw(this.indicatorIdAttribute);
		this.typeId = data.getRaw(this.typeIdAttribute);
		this.areaId = data.getRaw(this.areaIdAttribute);
		this.mapArea = this.element.querySelector(this.dataSelector(this.mapAreaAttribute));
		this.layers = this.element.querySelector(this.dataSelector(this.layersAttribute));
		this.imageContainer = this.element.querySelector(this.dataSelector(this.imageContainerAttribute));
		this.canvas = this.element.querySelector(this.dataSelector(this.canvasAttribute));
		this.canvasContext = this.canvas.getContext('2d');

		this.openAsyncEvent('load');
		this.load();
	}


	onResize(event) {
		this.updateGeometry();
	}


	load() {
		httpRequest
			.get(this.imageUrl)
			.then((response) => {
				this.imageContainer.innerHTML = response.text;
				this.svg = this.imageContainer.querySelector('svg');
				this.imageWidth = parseFloat(this.svg.getAttribute('width'));
				this.imageHeight = parseFloat(this.svg.getAttribute('height'));
				this.imageRatio = (this.imageHeight > 0 ? this.imageWidth / this.imageHeight : 1);
				this.updateGeometry();
				this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
				this.closeAsyncEvent('load');
			})
		;
		return this.on('load');
	}


	show() {
		this.classList().add(this.visibleClass);
	}


	hide() {
		this.classList().remove(this.visibleClass);
	}


	updateGeometry() {
		const containerRect = this.mapArea.getBoundingClientRect();
		const containerRatio = (containerRect.height > 0 ? containerRect.width / containerRect.height : 1);
		if (this.imageRatio >= containerRatio) {
			this.width = containerRect.width;
			this.height = Math.round(containerRect.width / this.imageRatio);
		} else {
			this.width = Math.round(containerRect.height * this.imageRatio);
			this.height = containerRect.height;
		}
		this.canvas.width = this.width;
		this.canvas.height = this.height;
		this.layers.style.width = this.width + 'px';
		this.layers.style.height = this.height + 'px';
	}


	update(value) {
		const valueNames = this.getFilterEntities().getValueNames();
		const requestValues = {};
		for (const type of ['scenario', 'climateModel', 'impactModel', 'selector']) {
			const group = valueNames[type];
			const id = value[type];
			let textValue = '';
			if (id === '0') {
				textValue = (type === 'scenario' ? 'all' : 'median');
			} else if (id in group) {
				textValue = group[id];
			}
			if (type === 'selector' && value.variable.type === 'temperature' && textValue.indexOf('.') < 0) {
				textValue += '.0';
			}
			requestValues[type] = textValue;
		}
		requestValues.indicatorId = this.indicatorId;
		requestValues.typeId = this.typeId;
		requestValues.areaId = this.areaId;
		requestValues.variableId = value.variable.id;
		requestValues.dummy = this.dummy;

		if (this.maxRangeValue === null) {
			requestValues.variables = this.varIds;
			requestValues.countryBasedMax = ('countryMapCountryBasedMax' in this.config ? !!this.config.countryMapCountryBasedMax : true);
		}

		this.request = this.api.execute('report/getCountryGridRankings', requestValues);
		return this.request.then((response) => {
			if (!this.request.isAborted()) {
				this.request = null;
				if (response.isSuccess()) {
					if (this.maxRangeValue === null) {
						this.maxRangeValue = parseFloat(response.output.maxValue);
						this.mapLegend.setDirection(value.variable.direction);
						this.mapLegend.updateMaxRangeValue(this.maxRangeValue);
					}
					this.updateGrid(response.output.values, this.maxRangeValue, value.variable.direction);
				}
			}
		});
	}


	updateGrid(values, maxRangeValue, direction) {
		if (!values.length) {
			return;
		}
		const rows = values.length;
		const cols = values[0].length;
		const rectWidth = this.width / cols; //Math.round(this.width / cols);
		const rectHeight = this.height / rows; //Math.round(this.height / rows);
		const colors = this.mapLegend.getColors();
		for (let row = 0; row < rows; row++) {
			for (let col = 0; col < cols; col++) {
				let value = values[row][col];
				let color;
				if (this.dummy) {
					if (value === '') {
						color = '#0000FF';
					} else {
						value = parseInt(value, 10);
						color = 'rgb(' + [value, value, value].join(', ') + ')';
					}
				} else {
					if (value === '') {
						color = this.parseColorValue(colors.noData);
					} else {
						value = parseFloat(value);
						if (value === 0) {
							color = this.parseColorValue(colors.zero);
						} else {
							const valueRatio = Math.min(1, Math.abs(value) / maxRangeValue);
							const colorDirection = ((value >= 0 && direction === 'desc') || (value < 0 && direction === 'asc') ? 'positive' : 'negative');
							const colorDef = colors[colorDirection];
							const colorProps = {};
							for (const property of ['hue', 'saturation', 'lightness']) {
								colorProps[property] = colorDef[property + 'RangeSize'] * valueRatio + colorDef[property + 'Range'][0];
							}
							color = 'hsl(' + [Math.round(colorProps.hue) + 'deg', Math.round(colorProps.saturation * 10000) / 100 + '%', Math.round(colorProps.lightness * 10000) / 100 + '%'].join(', ') + ')';
						}
					}
				}
				this.canvasContext.fillStyle = color;
				this.canvasContext.fillRect(
					rectWidth * col,
					rectHeight * row,
					rectWidth,
					rectHeight
				);
			}
		}
	}

}

export default CountryRankingMap;
