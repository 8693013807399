import PageComponent from '../../common/component/page-component';


class Loading extends PageComponent {

	constructor({
		root,
		element,
		hiddenClass = 'hidden'
	}) {
		super({root: root, element: element});
		this.hiddenClass = hiddenClass;
	}


	removeAnimation() {
		return this.threeStateTransition(this.element).add(this.hiddenClass);
	}


}


export default Loading;
