const plotRelatedMixin = (Base = null) => class extends Base {

	setConfig(config) {
		this.config = config;
	}


	getFilterEntities(filtersAttribute = 'filters') {
		if (!('filterEntities' in this)) {
			const parentComponentElement = this.element.closest(this.dataSelector(filtersAttribute));
			const parentComponent = (parentComponentElement ? this.getComponent(parentComponentElement) : null);
			if (parentComponent) {
				this.filterEntities = parentComponent.getFilterEntities();
			} else {
				throw new Error('filters provider not found');
			}
		}
		return this.filterEntities;
	}


	parseColorValue(value) {
		let color = '';
		const isRgb = ('rgb' in value);
		const isRgba = !isRgb && ('rgba' in value);
		if (isRgb || isRgba) {
			const name = 'rgb' + (isRgb ? '' : 'a');
			color = name + '(' + value[name].join(', ') + ')';
		}
		return color;
	}

};

export default plotRelatedMixin;
