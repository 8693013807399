const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];

const ordinal = function (number) {
	number = parseInt(number, 10);
    if ((number % 100 >= 11) && (number % 100 <= 13)) {
        return number + 'th';
	}
    return number + ends[number % 10];
};


export default ordinal;

