import Chart from 'chart.js';

function options(chart) {
	return chart.options.plugins.historicalOverlay;
}

const HistoricalOverlayPlugin = {
	id: 'historicalOverlay',
	_getOverlayBox: function (chart) {
		const yAxis = chart.scales['y-axis-0'];
		const xAxis = chart.scales['x-axis-0'];
		const tickIdx = xAxis.ticks.findIndex(tick => tick === options(chart).endOfHistory);
		const width = (xAxis.width / (xAxis.ticks.length - 1)) * tickIdx;
		return {
			left: yAxis.right,
			top: yAxis.top,
			width: width,
			height: yAxis.height,
			right: yAxis.right + width,
			bottom: yAxis.top + yAxis.height
		};
	},

	beforeDraw: function (chart) {
		if (!options(chart).enabled) return;
		// draw underlay
		const ctx = chart.ctx;
		ctx.save();
		const box = this._getOverlayBox(chart);
		ctx.beginPath();
		ctx.fillStyle = options(chart).fillStyle || '#E2E9E9';
		ctx.fillRect(box.left, box.top, box.width, box.height);
		ctx.strokeStyle = options(chart).strokeStyle || '#596463';
		ctx.lineWidth = 1;
		ctx.moveTo(box.right, box.top);
		ctx.lineTo(box.right, box.bottom);
		ctx.setLineDash(options(chart).lineDash || [4, 4]);
		ctx.stroke();
		ctx.restore();
	},
	afterDraw: function (chart) {
		if (!options(chart).enabled) return;
		// draw labels
		const ctx = chart.ctx;
		ctx.save();
		const box = this._getOverlayBox(chart);
		ctx.fillStyle = '#8A9393';
		ctx.textBaseline = 'top';
		if (ctx.font.indexOf('bold') === 0) {
			ctx.font = ctx.font.replace('bold', '');
		}
		ctx.fillText(options(chart).historyLabel, box.left + 10, box.top + 10);
		ctx.fillText(options(chart).futureLabel, box.right + 10, box.top + 10);
		ctx.restore();
	}
};

export default HistoricalOverlayPlugin;

// https://www.chartjs.org/samples/master/charts/line/point-sizes.html

// nasty but useful hack which stops chart.js from drawing unlabelled ticks
Chart.helpers.isNullOrUndef = function (value) {
	return value === null || typeof value === 'undefined' || (typeof value === 'string' && value.length === 0);
};

