import PageComponent from '../../common/component/page-component';


class PageMenuNav extends PageComponent {

	constructor({
		root,
		element,
		stickyModifier = 'sticky',
		pageMenuAttribute = 'pageMenu'

	}) {
		super({root: root, element: element});
		this.stickyModifier = stickyModifier;
		this.pageMenuAttribute = pageMenuAttribute;
	}


	prepare() {
		this.pageMenuNode = this.element.querySelector(this.dataSelector(this.pageMenuAttribute));
		this.listeners.scroll  = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
	}


	onScroll() {
		const elementPos = this.element.getBoundingClientRect().top;
		const elementParent = this.element.nextSibling;
		const elementParentPos = elementParent.getBoundingClientRect().top;
		const isSticky = elementPos > elementParentPos;
		this.classList(this.element).toggle(this.stickyModifier, isSticky);
	}

}


export default PageMenuNav;
