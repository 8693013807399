import {getScrollTop} from '../../common/utils/get-scroll';
import {getViewportHeight} from '../../common/utils/size';
import Accordion from '../../common/widget/accordion';


// TO DO: deal with IE not supporting position sticky
class PageMenuItems extends Accordion {

	constructor({
		root,
		element,
		togglerComponentName = 'Toggler',
		allowOnlyOneOpened = true,
		beforeToggleEvent = 'accordion:beforetoggle',
		toggleEvent = 'accordion:toggle',
		// when allowing only one, it's better to use a pure JS animation
		// to obtain a perfect sync with the scroll animation
		disableCssTransition = true,
		pageAttribute = 'page',
		sectionAttribute = 'stickyMenu',
		stickyClass = 'sticky'
	}) {
		super({
			root: root,
			element: element,
			togglerComponentName: togglerComponentName,
			allowOnlyOneOpened: allowOnlyOneOpened,
			beforeToggleEvent: beforeToggleEvent,
			toggleEvent: toggleEvent,
			disableCssTransition: disableCssTransition
		});
		this.pageAttribute = pageAttribute;
		this.sectionAttribute = sectionAttribute;
		this.stickyClass = stickyClass;

		this.enabled = false;
		this.listeningScroll = false;
		this.viewportHeight = 0;
		this.previousScrollTop = 0;
		this.refScrollTop = 0;
		this.sectionTop = 0;
		this.menuTop = 0;
		this.menuHeight = 0;
		this.positioning = false;
		this.offset = 0;
	}


	prepare() {
		super.prepare();
		// abort quickly if it is inside the context, not inside a page
		if (!this.element.closest(this.dataSelector(this.pageAttribute))) {
			return;
		}
		this.section = this.element.closest(this.dataSelector(this.sectionAttribute));
		if (this.section) {
			this.previousSection = this.section.previousElementSibling;
			if (this.previousSection) {
				this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
				this.listeners.contentResize = this.events.on(this.root, 'content:resize', this.onResize.bind(this));
				this.updateGeometry();
			}
		}
	}


	onResize(event) {
		this.updateGeometry();
	}


	onScroll(event) {
		this.updatePosition();
	}


	updateGeometry() {
		const enabled = this.cssData(this.element).get('enabled');
		if (enabled) {
			// console.log('updating geometry');
			const sectionRect = this.section.getBoundingClientRect();
			const rect = this.element.getBoundingClientRect();
			const scrollTop = getScrollTop();
			this.menuHeight = rect.height;
			this.viewportHeight = getViewportHeight();
			this.sectionOffset = parseInt(getComputedStyle(this.section).top, 10);
			this.stickyPosition = this.previousSection.getBoundingClientRect().bottom + scrollTop - this.sectionOffset;
			this.sectionTop = scrollTop + sectionRect.top;
			this.menuTop = scrollTop + rect.top;
			// this.positioning = (this.viewportHeight - this.sectionOffset < this.menuHeight);
			this.enable();
			this.updatePosition();
		} else {
			this.positioning = false;
			this.disable();
			this.resetPosition();
		}
	}


	updatePosition() {
		// if (!this.positioning) {
		// 	return;
		// }
		const scrollTop = getScrollTop();
		const isSticky = scrollTop > this.stickyPosition;
		const scrollDiff = scrollTop - this.previousScrollTop;
		this.previousScrollTop = scrollTop;
		this.classList().toggle(this.stickyClass, isSticky);
		if (isSticky) {
			const diff = this.menuHeight + this.sectionOffset * 2 - this.viewportHeight;
			// console.log('positioning', this.menuHeight);
			const isFitting = diff < 0;
			if (!isFitting) {
				this.offset += scrollDiff;
				this.offset = Math.max(0, Math.min(diff, this.offset));
				this.element.style.transform = 'translateY(-' + this.offset + 'px)';
			} else {
				this.element.style.transform = 'translateY(0)';
			}
		} else {
			// if (this.isSticky) {
			// 	this.nav.style.removeProperty('transform');
			// }
		}
	}


	resetPosition() {
		//
	}


	enable() {
		if (!this.enabled) {
			this.enabled = true;
			this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
			this.listeningScroll = true;
			// console.log('enabling');
		}
	}


	disable() {
		if (this.enabled) {
			this.enabled = false;
			if (this.listeningScroll) {
				this.listeningScroll = false;
				this.listeners.scroll.destroy();
				delete this.listeners.scroll;
			}
			// console.log('disabling');
		}
	}

}


// class SecondaryMenu extends PageComponent {

// 	constructor({
// 		root,
// 		element,
// 		navAttribute = 'secondaryMenuNav'
// 	}) {
// 		super({root: root, element: element});
// 		this.navAttribute = navAttribute;
// 		this.isScrollEnabled = false;
// 		this.isSticky = false;
// 		this.previousScrollTop = 0;
// 		this.offset = 0;
// 	}


// 	prepare() {
// 		// not doing anything if the menu is not sticky (on IE)
// 		if (document.querySelector('html.csspositionsticky')) {
// 			this.nav = this.element.querySelector(this.dataSelector(this.navAttribute));

// 			this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
// 			this.listeners.contentResize = this.events.on(this.element, 'content:resize', this.onResize.bind(this));
// 			this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this));

// 			this.updateSize();
// 		}
// 	}


// 	onResize(event) {
// 		this.updateSize();
// 	}


// 	onScroll(event) {
// 		const scrollTop = getScrollTop();
// 		this.updatePosition(scrollTop);
// 	}


// 	updateSize() {
// 		if (getComputedStyle(this.element).display !== 'none') {
// 			const elementRect = this.element.getBoundingClientRect();
// 			const scrollTop = getScrollTop();
// 			this.stickyTopPosition = scrollTop + elementRect.top;
// 			this.viewportHeight = getViewportHeight();
// 			this.menuHeight = this.nav.getBoundingClientRect().height;
// 			this.menuTop = parseInt(getComputedStyle(this.nav).top, 10);
// 			this.updatePosition(scrollTop);
// 			this.enableScrollMonitor();
// 		} else {
// 			this.disableScrollMonitor();
// 		}
// 	}


// 	updatePosition(scrollTop) {
// 		const scrollDiff = scrollTop - this.previousScrollTop;
// 		this.previousScrollTop = scrollTop;
// 		const isSticky = scrollTop > this.stickyTopPosition;
// 		if (isSticky) {
// 			const diff = this.menuTop + this.menuHeight - this.viewportHeight;
// 			const isFitting = diff < 0;
// 			if (!isFitting) {
// 				this.offset += scrollDiff;
// 				this.offset = Math.max(0, Math.min(diff, this.offset));
// 				this.nav.style.transform = 'translateY(-' + this.offset + 'px)';
// 			} else {
// 				this.nav.style.transform = 'translateY(0)';
// 			}
// 		} else {
// 			if (this.isSticky) {
// 				this.nav.style.removeProperty('transform');
// 			}
// 		}

// 		this.isSticky = isSticky;
// 	}


// 	enableScrollMonitor() {
// 		if (!this.isScrollEnabled) {
// 			this.isScrollEnabled = true;
// 			this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
// 		}
// 	}


// 	disableScrollMonitor() {
// 		if (this.isScrollEnabled) {
// 			this.isScrollEnabled = false;
// 			this.listeners.scroll.destroy();
// 			delete this.listeners.scroll;
// 		}
// 	}

// }


export default PageMenuItems;
