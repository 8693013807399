import PageComponent from '../../common/component/page-component';


class ReportPlotLegend extends PageComponent {

	constructor({
		root,
		element,
		variablesAttribute = 'variables',
		variableContainerAttribute = 'variableContainer',
		selectedClass = 'selected'
	}) {
		super({root: root, element: element});
		this.variablesAttribute = variablesAttribute;
		this.variableContainerAttribute = variableContainerAttribute;
		this.selectedClass = selectedClass;
	}


	prepare() {
		const data = this.dataAttr();
		const variables = data.get(this.variablesAttribute, []);
		this.variables = {};
		this.containers = new Map();
		for (const variable of variables) {
			this.variables[variable.name] = variable;
			this.containers.set(variable.name, this.element.querySelector(this.dataSelector(this.variableContainerAttribute, variable.id)));
		}
	}


	setVariable(variable) {
		for (const [name, container] of this.containers) {
			if (container) {
				this.classList(container).toggle(this.selectedClass, name === variable.name);
			}
		}
	}



}

export default ReportPlotLegend;
