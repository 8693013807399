import {Aspects, preparePlotData} from './data/plot-data';
import ChartJSPlot from './renderer/chartjs/ChartJSPlot';
import PageComponent from '../../common/component/page-component';

class ReportPlot extends PageComponent {

	constructor({
								root,
								element,
								dataUrlAttribute = 'dataUrl',
								requestNameAttribute = 'requestName',
								filesAttribute = 'files',
								variablesAttribute = 'variables',
								basePathAttribute = 'basePath',
								pdfModeAttribute = 'pdfMode',
								loadedClass = 'loaded',
								renderedClass = 'rendered',
								autoload = false,
							}) {
		super({root: root, element: element});
		this.dataUrlAttribute = dataUrlAttribute;
		this.requestNameAttribute = requestNameAttribute;
		this.variablesAttribute = variablesAttribute;
		this.basePathAttribute = basePathAttribute;
		this.filesAttribute = filesAttribute;
		this.pdfModeAttribute = pdfModeAttribute;
		this.loadedClass = loadedClass;
		this.renderedClass = renderedClass;
		this.defaults.autoload = autoload;

		this.response = null;
		this.loading = false;
		this.loaded = false;
		this.rendered = false;
		this.allowedToRender = false;
		this.readyToRender = false;
		this.variable = null;
		this.filters = null;
		this.config = {};
		this.plotData = [];
		this.activeIdx = 0;

		this.pendingSelectedPoint = null;
	}


	injectApi(api) {
		this.api = api;
	}


	prepare() {
		this.openAsyncEvent('load');
		const dataAttr = this.dataAttr();
		this.pdfMode = !!dataAttr.get(this.pdfModeAttribute, false);
		this.dataUrl = dataAttr.get(this.dataUrlAttribute);
		this.variables = dataAttr.get(this.variablesAttribute);

		this.listeners.beforePrint = this.events.on(window, 'beforeprint', this.onBeforePrint.bind(this));
		this.listeners.afterPrint = this.events.on(window, 'afterprint', this.onAfterPrint.bind(this));

		if (this.dataAttr().get('autoload')) {
			this.load();
		}
	}


	clear() {
		if (this.plot) {
			this.plot.destroy();
		}
	}


	onBeforePrint(event)  {
		this.setAllowedToRender(true);
		this.load().then(() => {
			this.plot.forcePixelRatio(2400);
		});
	}


	onAfterPrint(event) {
		this.plot.restorePixelRatio();
	}



	setConfig(config) {
		this.config = config;
	}


	load() {
		if (!this.loaded && !this.loading) {
			this.loading = true;
			const dataAttr = this.dataAttr();
			this.api.execute(dataAttr.get(this.requestNameAttribute, ''), {
				files: dataAttr.get(this.filesAttribute, []),
				basePath: dataAttr.get(this.basePathAttribute, '')
			}).then((response) => {
				if (response.isSuccess()) {
					this.filesData = response.output.plots;
					const config = Object.assign({
						yearConstraints: {
							min: 1850,
							max: 2100
						}
					}, this.config);
					for (const data of this.filesData) {
						try {
							const plot = preparePlotData(data, config);
							this.plotData.push(plot);
						} catch (e) {
							console.log(`Unable to process JSON from ${this.dataUrl}`, e);
							return;
						}
					}
					this.readyToRender = true;
					this.loaded = true;
					this.loading = false;
					this.render();
					this.classList(this.element.parentNode).add(this.loadedClass);
					this.closeAsyncEvent('load');
					// setTimeout(() => {
					// 	this.selectPoint('rcp26', 'IPSL-CM5A-LR', 'CLM45', '2021-2040');
					// 	this.selectPoint(false, 'IPSL-CM5A-LR', 'CLM45', 3);
					// }, 1000);
				} else {
					console.log('Unable to find plot data', dataAttr.get('files'), dataAttr.get('basePath'));
				}
			});
		}
		return this.on('load');
	}

	render() {
		if (!this.rendered && this.allowedToRender && this.readyToRender) {
			this.rendered = true;
			this._filterDatasets();
			const activeData = this.plotData[this.activeIdx];
			this.plot = new ChartJSPlot(
				this.element,
				activeData,
				Object.assign({onClick: this.triggerSelectEvent.bind(this), pdfMode: this.pdfMode}, this.config)
			);
			this.classList(this.element.parentNode).add(this.renderedClass);
			if (this.pendingSelectedPoint !== null) {
				this.selectPoint(...this.pendingSelectedPoint);
			}
		}
	}


	getImageData(minWidth) {
		return this.plot.getImageData(minWidth);
	}


	getFileData() {
		return this.filesData;
	}


	setAllowedToRender(value) {
		this.allowedToRender = value;
		if (value) {
			this.render();
		}
	}

	_filterDatasets() {
		const activeData = this.plotData[this.activeIdx];
		if (!activeData) return;
		const activeFilters = this.filters;
		if (!activeFilters) {
			return;
		}
		activeData.datasets.forEach(dataset => {
			if (dataset.aspect !== Aspects.median) {
				// completely hide interannual variation by default
				dataset.hidden = true;
			}
			// zero opacity for every dataset by default
			dataset.visible = false;
			const setFilters = dataset.filters;
			let scenarioFilterFits = false;
			activeFilters.scenario.forEach(scenario => {
				const isOverallScenario = setFilters.scenario === scenario === 'median';
				if (
					setFilters.scenario === false
					|| setFilters.scenario === scenario
					|| isOverallScenario
				) {
					scenarioFilterFits = true;
					if (isOverallScenario) {
						dataset.visible = true;
					}
				}
			});

			let climateFilterFits = false;
			activeFilters.climateModel.forEach(climateModel => {
				const isOverallClimateModel = setFilters.climateModel === climateModel === 'median';
				if (
					scenarioFilterFits && (
						setFilters.climateModel === climateModel
						|| isOverallClimateModel
					)
				) {
					climateFilterFits = true;
					if (isOverallClimateModel) {
						dataset.visible = true;
					}
				}
			});

			activeFilters.impactModel.forEach(impactModel => {
				if (setFilters.impactModel === impactModel && climateFilterFits) {
					dataset.visible = true;
				}
			});
		});
	}

	updateFilters(variable, filters) {
		delete filters.selector;
		this.variable = variable;
		this.filters = filters;
		// historical always shown by default
		this.filters.scenario.push('historical');
		const index = (this.variables.length === 1 || this.variables[0].id === variable.id ? 0 : 1);
		const indexChanged = index !== this.activeIdx;
		this.activeIdx = index;
		this._filterDatasets();
		if (this.plot) {
			this.plot.changeData(this.plotData[index], indexChanged);
		}
		/*
		activeIdx is the selection of the file to be rendered, can be 0 or 1.
		(each variable maps to a file)
		filters is the list of the selected elements (scenarios, climate models, impact models, seleectors) to be rendered.
		By selectors we mean temperature values OR timeslices, depending on the selected file
		this function can be called before or after the fetching of the files content
		in order to render / update the plots you need both the file contents and the filters values, so you need to wait for both before the first rendenring
		*/
	}


	/*
		to be called when the user clicks one of the plot point
		the values need to be provided in the same format they are received from updateFilters().
	 	The value to be passed for medians is 'median'
	*/
	triggerSelectEvent(scenario, climateModel, impactModel, selector) {
		this.events.trigger(this.element, 'plot:select', {
			variable: this.variables[this.activeIdx],
			scenario: scenario,
			climateModel: climateModel,
			impactModel: impactModel,
			selector: selector
		});
	}


	selectPoint(scenario, climateModel, impactModel, selector) {
		if (this.plot) {
			// console.log('selecting point', scenario, climateModel, impactModel, selector);
			this.plot.selectPoint(scenario, climateModel, impactModel, selector);
			this.pendingSelectedPoint = null;
		} else {
			this.pendingSelectedPoint = [scenario, climateModel, impactModel, selector];
		}
		// selector is a value of temperature or timeslice (in the form $firstYear-$lastYear) depending on the currently selected variable.
		// it should not happen, but if the function tries to select a point on a disabled (filtered out) line or it is not matching with any available point,
		// it should fail silently
	}


	getPlotsInfo() {
		return this.on('load').then(() => {
			const info = [];
			for (const file of this.filesData) {
				info.push({
					title: ('plot_title' in file ? file.plot_title : ''),
					rawUrl: ('esgf_search_url' in file ? file.esgf_search_url : ''),
					unitLabel: ('plot_label_x' in file ? file.plot_label_x : '')
				});
			}
			return info;
		});
	}


	getFileNames() {
		return this.dataAttr().get(this.filesAttribute);
	}

}


export default ReportPlot;
