import DefaultPage from './default-page';


class ReportPage extends DefaultPage {

	constructor({root, element, url = '', title = '', plotLoadThreshold = 0, plotRenderThreshold = 0.5}) {
		super({root: root, element: element, url: url, title: title});
		this.plotLoadThreshold = plotLoadThreshold;
		this.plotRenderThreshold = plotRenderThreshold;
	}


	injectIntersectionObservers(intersectionObservers) {
		this.intersectionObservers = intersectionObservers;
	}


	onLoad() {
		return super.onLoad().then(() => {
			const plots = this.getComponents('ReportPlot');
			const plotElements = plots.map((plot) => plot.getElement());
			this.intersectionObservers.initObserver(
				this,
				[this.plotLoadThreshold, this.plotRenderThreshold],
				this.onPlotsIntersect.bind(this)
			);
			this.intersectionObservers.observe(this, plotElements);
		});
	}


	onUnload() {
		return super.onUnload().then(() => {
			this.intersectionObservers.destroyObserver(this);
		});
	}


	onPlotsIntersect(entries) {
		for(const entry of entries) {
			const plot = this.getComponent(entry.target);
			if (plot) {
				if (entry.intersectionRatio > this.plotLoadThreshold) {
					plot.load();
				}
				plot.setAllowedToRender(entry.intersectionRatio >= this.plotRenderThreshold);
			}
		}
	}

}


export default ReportPage;
