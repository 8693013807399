import {TweenMax} from 'gsap';
import 'gsap/ScrollToPlugin';
import {ucFirst} from '../../common/utils/string';
import {getScrollTop} from '../../common/utils/get-scroll';
import {getViewportHeight} from '../../common/utils/size';
import {waitFrame} from '../../common/utils/wait';
import PageComponent from '../../common/component/page-component';
import constants from './constants';


class ConfiguratorSections extends PageComponent {

	constructor({
		root,
		element,
		sectionAttribute = 'section',
		sectionTitleAttribute = 'sectionTitle',
		sectionDescriptionAttribute = 'sectionDescription',
		sectionContentAttribute = 'sectionContent',
		currentSectionAttribute = 'currentSection',
		decoratorAttribute = 'sectionDecorator',
		selectedClass = 'selected',
		firstSelectionClass = 'firstSelection',
		animateClass = 'animate',
		showContentClass = 'showContent',
		hideContentClass = 'hideContent',
		hideTitleClass = 'hideTitle',
		hideDescriptionClass = 'hideDescription',
		removeDescriptionClass = 'removeDescription',
		closeDownClass = 'closeDown',
		mapPadding = 100,
		searchScrollAnimationDuration = 0.4, // sec
		searchScrollOffset = -10 // px
	}) {
		super({root: root, element: element});
		this.sectionAttribute = sectionAttribute;
		this.sectionTitleAttribute = sectionTitleAttribute;
		this.sectionDescriptionAttribute = sectionDescriptionAttribute;
		this.sectionContentAttribute = sectionContentAttribute;
		this.currentSectionAttribute = currentSectionAttribute;
		this.decoratorAttribute = decoratorAttribute;
		this.selectedClass = selectedClass;
		this.firstSelectionClass = firstSelectionClass;
		this.animateClass = animateClass;
		this.showContentClass = showContentClass;
		this.hideContentClass = hideContentClass;
		this.hideDescriptionClass = hideDescriptionClass;
		this.hideTitleClass = hideTitleClass;
		this.removeDescriptionClass = removeDescriptionClass;
		this.closeDownClass = closeDownClass;
		this.mapPadding = mapPadding;
		this.searchScrollAnimationDuration = searchScrollAnimationDuration;
		this.searchScrollOffset = searchScrollOffset;

		this.sections = new Map();
		this.currentSectionName = '';
		this.currentSection = null;
		this.busy = false;
		this.firstMapInteraction = true;
		this.trackingMapEvents = false;
	}


	prepare() {
		this.configuratorElement = this.element.closest(this.dataSelector('component', 'Configurator'));
		this.largeLayout = this.cssData(this.configuratorElement).get('largeLayout', false);
		this.isMobile = !!document.querySelector('html.mobile');
		this.isIos = this.isMobile && !!document.querySelector('html.iOs');


		const sections = this.configuratorElement.querySelectorAll(this.dataSelector(this.sectionAttribute));
		for (const section of sections) {
			const name = this.dataAttr(section).get(this.sectionAttribute);
			this.sections.set(name, {
				name: name,
				classList: this.classList(section),
				sectionNode: section,
				titleNode: section.querySelector(this.dataSelector(this.sectionTitleAttribute)),
				descriptionNode: section.querySelector(this.dataSelector(this.sectionDescriptionAttribute)),
				contentNode: section.querySelector(this.dataSelector(this.sectionContentAttribute)),
				decoratorNode: null
			});
		}

		const decorators = this.configuratorElement.querySelectorAll(this.dataSelector(this.decoratorAttribute));
		for (const decorator of decorators) {
			const name = this.dataAttr(decorator).get(this.decoratorAttribute);
			this.sections.get(name).decoratorNode = decorator;
		}

		this.map = this.getComponent('ConfiguratorMap');

		this.listeners.sectionClick = this.events.on(this.configuratorElement, this.dataSelector(this.sectionAttribute), 'click', this.onSectionClick.bind(this));
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.listeners.searchFocus = this.events.on(this.element, 'search:focus', this.onSearchFocus.bind(this));
		this.listeners.searchBlur = this.events.on(this.element, 'search:blur', this.onSearchBlur.bind(this));
	}


	onSectionClick(event, target) {
		const sectionName = this.dataAttr(target).get(this.sectionAttribute);
		this.changeSection(sectionName);
	}


	onResize(event) {
		const largeLayout = this.cssData(this.configuratorElement).get('largeLayout', false);
		if (largeLayout !== this.largeLayout) {
			if (this.currentSectionName) {
				this.updateDom(largeLayout);
			}
			this.largeLayout = largeLayout;
		}
	}


	onSearchFocus(event, target) {
		const largeLayout = this.cssData(this.configuratorElement).get('largeLayout', false);
		if (!largeLayout && this.isMobile) {
			const containerTop = this.sections.get(constants.type).sectionNode.parentNode.getBoundingClientRect().top + getScrollTop();
			const section = target.closest(this.dataSelector(this.sectionAttribute));
			const sectionName = this.dataAttr(section).get(this.sectionAttribute);
			for (const [name, entry] of this.sections) {
				if (name !== sectionName) {
					TweenMax.set(entry.sectionNode, {
						visibility: 'hidden',
						zIndex: 0
					});
				}
			}
			const rect = target.getBoundingClientRect();
			const diff = rect.top + this.searchScrollOffset;
			const scrollTop = getScrollTop();
			TweenMax.to(window, this.searchScrollAnimationDuration, {scrollTo: {y: scrollTop + diff - (this.isIos ? 0 : containerTop), autoKill: false}});
		}
	}


	onSearchBlur(event, target) {
		for (const entry of this.sections.values()) {
			entry.sectionNode.style.removeProperty('visibility');
			entry.sectionNode.style.removeProperty('z-index');
		}
	}


	changeSection(sectionName, immediate = false) {
		const largeLayout = this.cssData(this.configuratorElement).get('largeLayout', false);
		if (immediate) {
			this.currentSectionName = sectionName;
			this.updateDom(largeLayout);
			return Promise.resolve();
		}
		return (largeLayout ? this.changeSectionLarge(sectionName) : this.changeSectionSmall(sectionName));
	}


	changeSectionSmall(sectionName) {
		return new Promise((resolve) => {
			if (!this.busy && sectionName !== this.currentSectionName) {
				this.busy = true;

				const containerTop = this.sections.get(constants.type).sectionNode.parentNode.getBoundingClientRect().top + getScrollTop();
				const viewportHeight = getViewportHeight();
				const newSection = this.sections.get(sectionName);
				const currentSection = this.currentSectionName ? this.sections.get(this.currentSectionName) : null;

				for (const entry of this.sections.values()) {
					entry.sectionTop = entry.sectionNode.getBoundingClientRect().top;
					entry.titleHeight = entry.titleNode.getBoundingClientRect().height;
				}

				if (!this.currentSectionName) {
					this.classList(this.configuratorElement).add(this.firstSelectionClass);
				}

				const waitNode = this.sections.get(this.currentSectionName ? this.currentSectionName : constants.type).descriptionNode;
				// this.classList().add(this.hidingDescriptionClass);

				for (const entry of this.sections.values()) {
					entry.classList.add(this.hideDescriptionClass, this.hideTitleClass);
					if (entry.classList.contains(this.showContentClass)) {
						this.threeStateTransition(entry.sectionNode, entry.contentNode).remove(this.showContentClass);
					}
				}

				this.onTransitionEnd(waitNode).then(() => {
					if (currentSection) {
						currentSection.sectionNode.style.removeProperty('min-height');
					}
					newSection.sectionNode.style.minHeight = 'calc(100vh - ' + (newSection.titleHeight * 2 + containerTop) + 'px)';
					this.dataAttr(this.configuratorElement).set(this.currentSectionAttribute, sectionName);
					window.scrollTo(0, 0);
					this.classList(this.configuratorElement).remove(this.selectedClass);
					for (const [name, entry] of this.sections) {
						if ((name === constants.type && sectionName === name) ||
							(name === constants.area && sectionName !== constants.topic)
						) {
							this.classList(entry.titleNode).remove(this.closeDownClass);
						}
						entry.classList.add(this.removeDescriptionClass);
						let y;

						if (name === constants.type) {
							y = Math.max(entry.sectionTop, containerTop - entry.titleHeight) - containerTop;
						} else if (name === constants.area) {
							const topPosition = containerTop + this.sections.get(constants.type).titleHeight;
							if (sectionName === name || sectionName === constants.topic) {
								y = entry.sectionTop - topPosition;
							} else {
								y = Math.max(topPosition, entry.sectionTop) - (viewportHeight - this.sections.get(constants.topic).titleHeight - entry.titleHeight);
							}
						} else { // name === constants.topic
							const topPosition = containerTop + this.sections.get(constants.type).titleHeight + this.sections.get(constants.area).titleHeight;
							if (sectionName === name) {
								y = entry.sectionTop - topPosition;
							} else {
								y = Math.max(topPosition, entry.sectionTop) - (viewportHeight - entry.titleHeight);
							}
						}
						entry.titleNode.style.transform = 'translateY(' + y + 'px)';
					}
					return waitFrame();
				}).then(() => waitFrame()
				).then(() => {
					const promises = [];
					for (const entry of this.sections.values()) {
						this.classList(entry.titleNode).add(this.animateClass);
						entry.titleNode.style.removeProperty('transform');
						promises.push(this.onTransitionEnd(entry.titleNode));
					}
					return Promise.all(promises);
				}).then(() => {
					newSection.classList.remove(this.removeDescriptionClass);
					for (const [name, entry] of this.sections) {
						entry.classList.remove(this.hideTitleClass);
						this.classList(entry.titleNode).remove(this.animateClass);
						if ((name === constants.type && sectionName !== name) ||
							(name === constants.area && sectionName === constants.topic)
						) {
							this.classList(entry.titleNode).add(this.closeDownClass);
						}
					}
					return waitFrame();
				}).then(() => {
					newSection.classList.remove(this.hideDescriptionClass);
					this.threeStateTransition(newSection.sectionNode, newSection.contentNode).add(this.showContentClass);
					this.classList(this.configuratorElement).add(this.selectedClass);
					this.classList(this.configuratorElement).remove(this.firstSelectionClass);
					return this.onTransitionEnd(newSection.descriptionNode);
				}).then(() => {
					this.currentSectionName = sectionName;
					this.busy = false;
					resolve();
				});
			} else {
				resolve();
			}
		});
	}


	changeSectionLarge(sectionName) {
		return new Promise((resolve) => {
			if (!this.busy && sectionName !== this.currentSectionName) {
				this.busy = true;

				const newSection = this.sections.get(sectionName);
				const waitNode = this.sections.get(this.currentSectionName ? this.currentSectionName : constants.type).descriptionNode;

				for (const entry of this.sections.values()) {
					entry.classList.add(this.hideDescriptionClass, this.hideContentClass, this.hideTitleClass);
				}

				this.onTransitionEnd(waitNode).then(() => {
					this.dataAttr(this.configuratorElement).set(this.currentSectionAttribute, sectionName);
					return this.onTransitionEnd(this.sections.get(constants.type).decoratorNode);
				}).then(() => {
					this.classList(this.configuratorElement).add(this.selectedClass);
					for (const [name, entry] of this.sections) {
						const classes = [this.hideTitleClass];
						if (name === sectionName) {
							classes.push(this.hideDescriptionClass);
							classes.push(this.hideContentClass);
						}
						entry.classList.remove(...classes);
					}
					return this.onTransitionEnd(newSection.descriptionNode);
				}).then(() => {
					this.currentSectionName = sectionName;
					if (sectionName === constants.area) {
						this.enableMap();
					} else {
						this.disableMap();
					}

					this.busy = false;
					resolve();
				});
			} else {
				resolve();
			}
		});
	}


	updateDom(largeLayout) {
		const section = this.sections.get(this.currentSectionName);
		this.dataAttr(this.configuratorElement).set(this.currentSectionAttribute, this.currentSectionName);
		this.classList(this.configuratorElement).add(this.selectedClass);
		if (largeLayout) {
			for (const entry of this.sections.values()) {
				entry.sectionNode.style.removeProperty('min-height');

				entry.classList.toggle(this.hideContentClass, entry !== section);
				entry.classList.remove(
					this.removeDescriptionClass,
					this.hideTitleClass,
					'before' + ucFirst(this.showContentClass),
					'during' + ucFirst(this.showContentClass),
					this.showContentClass
				);
			}
			if (this.currentSectionName === constants.area) {
				this.enableMap();
			} else {
				this.disableMap();
			}
		} else {
			const containerTop = section.sectionNode.parentNode.getBoundingClientRect().top + getScrollTop();
			section.titleHeight = section.titleNode.getBoundingClientRect().height;

			for (const [name, entry] of this.sections) {
				entry.sectionNode.style.removeProperty('min-height');
				section.sectionNode.style.minHeight = 'calc(100vh - ' + (section.titleHeight * 2 + containerTop) + 'px)';

				entry.classList.toggle(this.hideDescriptionClass, entry !== section);
				entry.classList.toggle(this.removeDescriptionClass, entry !== section);
				entry.classList.toggle('before' + ucFirst(this.showContentClass), entry === section);
				entry.classList.toggle('during' + ucFirst(this.showContentClass), entry === section);
				entry.classList.toggle(this.showContentClass, entry === section);
				entry.classList.toggle(this.hideContentClass, false);

				const toggleCloseDown = (
					(name === constants.type && section.name !== name) ||
					(name === constants.area && section.name === constants.topic)
				);
				this.classList(entry.titleNode).toggle(this.closeDownClass, toggleCloseDown);
			}
		}
	}



	enableMap(reset = false) {
		this.map.enable().then(() => {
			if (reset || this.firstMapInteraction) {
				this.map.fitBounds(null, true, {animate: false});
			}
			this.updateMapBounds();
			this.map.getDriver().on('zoomend', this.updateMapBounds.bind(this));
			this.trackingMapEvents = true;
			this.firstMapInteraction = false;
		});
	}


	disableMap() {
		this.map.disable().then(() => {
			if (this.trackingMapEvents) {
				this.trackingMapEvents = false;
				this.map.getDriver().off('zoomend', this.updateMapBounds.bind(this));
			}
		});
	}


	updateMapBounds() {
		const scale = this.map.zoomToScale(this.map.getZoom());
		const margin = this.sections.get(constants.area).contentNode.getBoundingClientRect().width;
		const size = this.map.getOriginalSize();
		this.map.setMaxBounds([[-margin / scale, 0], [size.width, size.height]]);
		this.map.setZoomPadding([margin + this.mapPadding, this.mapPadding], [this.mapPadding, this.mapPadding]);
	}


}

export default ConfiguratorSections;
