import {getScrollTop} from 'get-scroll';
import queryString from 'query-string';
import PageComponent from '../component/page-component';


class Search extends PageComponent {

	constructor({
		element,
		root,
		searchOnType = true,
		useTags = false,
		waitTime = 400, // ms
		updateQueryString = true,
		qs = queryString,
		queryStringParamsNames = {
			prefix: '?',
			search: 's',
			page: 'p',
			tags: 't'
		},
		extraParams = [
			{name: 'category', type: 'string', queryStringName: 'c', default: ''}
		],
		autofocus = true,
		delayedTrack = 2000,
		inputAttribute = 'searchInput',
		actionAttribute = 'searchAction',
		busyClass = 'busy'
	}) {
		super({element: element, root: root});
		this.inputAttribute = inputAttribute;
		this.actionAttribute = actionAttribute;

		this.busyClass = busyClass;

		this.defaults.searchOnType = searchOnType;
		this.defaults.waitTime = waitTime;
		this.defaults.useTags = useTags;
		this.defaults.updateQueryString = updateQueryString;
		this.defaults.queryStringParamsNames = queryStringParamsNames;
		this.defaults.autofocus = autofocus;
		this.defaults.extraParams = extraParams;

		this.waitTimeout = null;
		this.trackTimeout = null;
		this.promise = Promise.resolve();
		this.lastValue = null;
		this.lastTags = [];
		this.tags = [];
		this.lastPage = 1;
		this.urlChanged = false;

		this.queryString = qs;
		this.extra = {};

		this.resultsComponent = null;
	}


	injectSearchEngine(searchEngine) {
		this.searchEngine = searchEngine;
	}


	injectHistory(history) {
		this.history = history;
		this.historySupported = this.history.isSupported();
	}


	prepare() {
		const data = this.dataAttr().getAll();
		if ('searchResultsId' in data) {
			this.resultsComponent = this.components.queryComponent(this.root, this.dataSelector('id', data.searchResultsId));
		}
		if (!this.resultsComponent) {
			throw Error('Search Result Component not found');
		}
		this.listeners.searchActions = this.events.on(this.getElement(), this.dataSelector(this.actionAttribute), 'click', this.onSearchAction.bind(this));
		this.listeners.resultsSearchActions = this.events.on(this.resultsComponent.getElement(), this.dataSelector(this.actionAttribute), 'click', this.onSearchAction.bind(this));
		this.input = this.element.querySelector(this.dataSelector(this.inputAttribute));

		this.baseUrl = data.baseUrl || '';
		this.searchOnType = !!data.searchOnType;
		this.waitTime = data.waitTime;
		this.useTags = data.useTags;
		this.updateQueryString = data.updateQueryString;
		this.queryStringParamsNames = data.queryStringParamsNames;
		this.autofocus = data.autofocus;
		this.extraParams = data.extraParams;
		for (const extraParam of this.extraParams) {
			this.extra[extraParam.name] = extraParam.default;
		}

		if (this.input) {
			if (this.searchOnType) {
				this.listeners.change = this.events.on(this.input, 'input', this.onChange.bind(this));
			}
			this.input.disabled = false;
		}

		// this.baseUrl = data.get('baseUrl');
		// this.texts = data.get('texts');
		// this.tagsLimit = data.get('tagsLimit');
		// this.results = element.querySelector(this.dataSelector('searchResults'));
		// this.listeners.focus = this.events.on(this.input, 'focusin', this.onFocus.bind(this), {capture: true});
		// this.listeners.blur = this.events.on(this.input, 'focusout', this.onBlur.bind(this), {capture: true});
		// this.listeners.change = this.events.on(this.input, 'input', this.onChange.bind(this));
		// this.listeners.close = this.events.on(document.body, this.dataSelector('searchClose'), 'click', this.onClose.bind(this));
		// this.listeners.click = this.events.on(element, this.dataSelector('searchResults') + ' a', 'click', this.onResultClick.bind(this), {capture: true});
		// this.listeners.pageClick = this.events.on(element, this.dataSelector('toPage'), 'click', this.onPageClick.bind(this));
		// this.listeners.tagClick = this.events.on(element, this.dataSelector('tagId'), 'click', this.onTagClick.bind(this));
		// this.listeners.navigate = this.events.on(document, 'search:navigate', this.onNavigate.bind(this));
	}


	clear() {
		// return this.disableSearch();
	}


	start() {
		this.processUrlParams(this.getUrlParams());
	}


	onChange(event) {
		if (this.waitTimeout) {
			clearTimeout(this.waitTimeout);
		}
		this.waitTimeout = setTimeout(this.submit.bind(this), this.waitTime);
	}


	onSearchAction(event, target) {
		event.preventDefault();
		const input = this.getInput();
		const actionData = this.dataAttr(target).get(this.actionAttribute);
		let page = 1;
		let force = false;
		for (const key in actionData) {
			if (actionData.hasOwnProperty(key)) {
				if (key === 'page') {
					page = actionData[key];
				} else if (key === 'search') {
					input.value = actionData[key];
				} else if (key in this.extra) {
					this.extra[key] = actionData[key];
					force = true;
				}
			}
		}
		this.submit(true, page, force).then(() => {
			if ('search' in actionData) {
				const value = input.value;
				input.value = '';
				this.input.focus();
				input.value = value;
			}
		});
	}


	submit(pushUrl = true, page = 1, force = false) {
		const value = this.getInput().value;
		let changed = force || value !== this.lastValue || page !== this.lastPage;
		const tags = this.tags;
		if (this.useTags) {
			changed = changed || this.tags.join(',') !== this.lastTags.join(',');
		}
		if (changed) {
			const params = Object.assign({}, this.extra, {
				search: value,
				page: page,
				tags: tags
			});
			// if (this.delayedTrack > 0 && this.trackTimeout) {
			// 	clearTimeout(this.trackTimeout);
			// 	this.trackTimeout = null;
			// }
			this.lastValue = value;
			this.lastTags = tags.slice();
			this.classList(this.element).add(this.busyClass);
			this.promise = this.promise
				.then(() => this.searchEngine.search(params))
				.then((results) => this.processResults(results))
				.then((results) => this.updateUrl(results, pushUrl))
				.then((results) => {
					this.classList(this.element).remove(this.busyClass);
					window.scrollTo(0, 0);
				})
			;
		}
		return this.promise;
	}


	getInput() {
		return this.input;
	}

	// onClose(event) {
	// 	if (this.eventsEnabled) {
	// 		if (this.urlChanged) {
	// 			this.urlChanged = false;
	// 			this.history.push(this.baseUrl, {}, document.title);
	// 		}
	// 		this.close();
	// 	}
	// }


	processResults(results) {
		return this.resultsComponent.processResults(results).then(() => {
			const input = this.getInput();
			if (input.value === results.params.inputSearch) {
				const hasTrailSpace = input.value.length && input.value.substr(-1) === ' ';
				input.value = results.params.sanitizedSearch + (hasTrailSpace ? ' ' : '');
			}
			this.lastPage = results.params.page;

			for (const extraParam of this.extraParams) {
				if (extraParam.name in results.params) {
					this.extra[extraParam.name] = results.params[extraParam.name];
				}
			}
			return results;

		// 	results.params.tagsLimit = this.tagsLimit;
		// 	results.params.texts = this.texts;
		// 	this.results.innerHTML = this.template.render(this.resultsTpl, results.elements, results.tags, results.params);
		// 	window.scrollTo(0, 0);
		// 	resolve();
		// 	if (this.delayedTrack > 0) {
		// 		if (this.trackTimeout) {
		// 			clearTimeout(this.trackTimeout);
		// 			this.trackTimeout = null;
		// 		}
		// 		this.trackTimeout = setTimeout(() => {
		// 			const tags = [];
		// 			for (const tag of results.tags) {
		// 				if (parseInt(tag.selected, 10)) {
		// 					tags.push(tag.id);
		// 				}
		// 			}
		// 			this.searchEngine.track(results.params.inputSearch, tags, results.params.page);
		// 		}, this.delayedTrack);
		// 	}
		// });
		});
	}


	updateUrl(results, pushUrl) {
		if (this.updateQueryString && this.historySupported) {
			const params = {};
			params[this.queryStringParamsNames.search] = results.params.sanitizedSearch;
			if (this.lastPage !== 1) {
				params[this.queryStringParamsNames.page] = this.lastPage;
			}
			if (this.useTags && this.tags.length) {
				params[this.queryStringParamsNames.tags] = this.tags.join('.');
			}
			for (const extraParam of this.extraParams) {
				const value = this.extra[extraParam.name];
				// if (extraParam.type) {

				// }
				if (value.length) {
					params[extraParam.queryStringName] = value;
				}
			}

			const qs = this.queryString.stringify(params);
			const url = this.baseUrl + (qs.length ? this.queryStringParamsNames.prefix + qs : '');
			if (pushUrl) {
				this.history.push(url, {}, document.title);
			} else {
				this.history.replace(url, {}, document.title);
			}
		}

		return results;
	}


	onPageClick(event, target) {
		const page = this.dataAttr(target).get('toPage');
		this.submit(true, page);
	}


	onTagClick(event, target) {
		const id = String(this.dataAttr(target).get('tagId'));
		const currentIndex = this.tags.indexOf(id);
		if (currentIndex >= 0) {
			this.tags.splice(currentIndex, 1);
		} else {
			this.tags.push(id);
			this.tags.sort((a, b) => a - b);
		}
		this.submit(true);
	}


	onNavigate(event) {
		const requestType = event.detail.request.navigationType;
		const params = this.getUrlParams();
		if (requestType !== 'current' && this.eventsEnabled && params !== null) {
			event.preventDefault();
			this.processUrlParams(params);
		}
	}


	processUrlParams(params) {
		const input = this.getInput();
		if (params !== null && (this.queryStringParamsNames.search in params && params[this.queryStringParamsNames.search].length || this.useTags && this.queryStringParamsNames.tags in params)) {
			const search = this.queryStringParamsNames.search in params ? params[this.queryStringParamsNames.search] : '';
			const page = params[this.queryStringParamsNames.page];
			const tags = this.queryStringParamsNames.tags in params ? params[this.queryStringParamsNames.tags].split('.') : [];
			// const state = this.historySupported ? this.history.getState() : {};

			for (const extraParam of this.extraParams) {
				if (extraParam.queryStringName in params) {
					this.extra[extraParam.name] = params[extraParam.queryStringName];
				}
			}

			input.focus();
			input.value = search;
			this.tags = tags;
			this.submit(false, page)
				.then(() => input.focus())
				// .then(() => this.enableEvents())
				// .then(() => {
				// 	if (this.historySupported && 'searchScrollTop' in state) {
				// 		window.scrollTo(0, state.searchScrollTop);
				// 		this.history.mergeState({searchScrollTop: state.searchScrollTop});
				// 	}
				// })
			;
		} else if (this.autofocus) {
			input.focus();
		}
	}


	getUrlParams() {
		const params = this.queryString.parse(location.search);
		if ((this.queryStringParamsNames.search in params) || (this.useTags && (this.queryStringParamsNames.tags in params))) {
			return params;
		}
		return null;
	}


	// stop() {
	// 	this.disableEvents();
	// 	this.disableSearch();
	// }


	onResultClick(event) {
		if (this.eventsEnabled) {
			this.disableEvents();
			this.firstStart = true;
			if (this.historySupported) {
				this.history.mergeState({searchScrollTop: Math.round(getScrollTop())});
			}

			this.close();
		} else {
			event.preventDefault();
			event.stopPropagation();
		}
	}
}


export default Search;
