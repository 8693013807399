import 'intersection-observer';
import svg4everybody from 'svg4everybody';
import picturefill from 'picturefill';
import App from '../../common/app/app';


class IspApp extends App {

	injectCurrentLinkManager(currentLinkManager) {
		this.currentLinkManager = currentLinkManager;
	}


	injectNavigationTracker(tracker) {
		this.tracker = tracker;
	}


	injectHashScroller(hashScroller) {
		this.hashScroller = hashScroller;
	}


	injectPageSlots(pageSlots) {
		this.pageSlots = pageSlots;
	}


	injectPages(pages) {
		this.pages = pages;
	}


	init() {
		svg4everybody();
		picturefill();
		this.contexts.setDefaultContext('default', false);
		return super.init();
	}


	execute() {
		this.pages.initCurrent()
			.then(() => {
				this.classList(this.root).add('loaded');
				this.getComponent('Loading').removeAnimation();
				this.contexts.getContext('default').activate();
				const hash = location.hash;
				if (hash.length) {
					this.events.trigger(document.body, 'history:hashchange', {hash: hash.substr(1)});
				}
			})
		;
	}

}

export default IspApp;
