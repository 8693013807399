import Chart from 'chart.js';

const pointHighlightCanvases = {};

const PI2 = Math.PI * 2;

function makeOffscreen(size, dataset, fillStyle) {
	const offscreen = document.createElement('canvas');
	offscreen.width = size;
	offscreen.height = size;
	const radius = size / 2;
	const ctx = offscreen.getContext('2d');
	ctx.fillStyle = fillStyle;
	ctx.arc(radius, radius, radius - 1, 0, PI2);
	ctx.fill();
	ctx.beginPath();
	// point circle
	ctx.fillStyle = dataset.rgba(1);
	ctx.strokeStyle = 'white';
	ctx.lineWidth = 1;
	ctx.arc(radius, radius, Chart.defaults.global.elements.point.radius, 0, PI2);
	ctx.fill();
	ctx.stroke();
	return offscreen;
}


/**
 * Use cached offscreen canvases to draw the hover points
 * ----
 * The indices of the hovered datasets and points are set in the onClick handler of ChartJSPlot.
 * They are stored in the plugin options Object:
 * chart.options.plugins.customHighlight.activeEls
 * and chart.options.plugins.customHighlight.clearEls for those which need to be reset
 */
const CustomHighlightPlugin = {
	id: 'customHighlight',
	beforeUpdate: function (chart) {
		const options = chart.options.plugins[this.id];
		const datasets = chart.data.datasets;
		if (options.clearEls && options.clearEls.length > 0) {
			options.clearEls.forEach(el => {
				const clearSet = datasets[el.datasetIdx];
				clearSet.pointBackgroundColor = clearSet.borderColor;
				clearSet.pointStyle = 'circle';
			});
			options.clearEls = [];
			options.hoverEls = [];
			// workaround: otherwise this plugin prevents the tooltips from disappearing
			if (chart.tooltip.tooltip) {
				chart.tooltip.tooltip.el.remove();
				chart.tooltip.tooltip = null;
			}
		}
		if (options.hoverEls && options.hoverEls.length > 0) {
			const hoverSize = options.hoverSize || 32;
			const outerFillStyle = options.outerFillStyle || 'rgba(138,147,147,0.5)';
			options.hoverEls.forEach(el => {
				const activeSet = datasets[el.datasetIdx];
				const canvasKey = options.chartType + '-' + el.datasetIdx;
				let offscreen = pointHighlightCanvases[canvasKey];
				if (!offscreen) {
					// render new hover effect canvas
					offscreen = makeOffscreen(hoverSize, activeSet, outerFillStyle);
					pointHighlightCanvases[canvasKey] = offscreen;
				}
				// set the point styles to use our offscreen canvas to render the hovered point
				activeSet.pointStyle = activeSet.data.map((pt, idx) => (idx === el.ptIdx ? offscreen : 'circle'));
			});
		}
		if (options.clearActiveEl) {
			const clearSet = datasets[options.clearActiveEl.datasetIdx];
			clearSet.pointBackgroundColor = clearSet.borderColor;
			clearSet.pointStyle = 'circle';
			options.clearActiveEl = false;
		}
		if (options.activeEl) {
			const activeSize = options.activeSize || 32;
			const el = options.activeEl;
			const activeSet = datasets[el.datasetIdx];
			if (!activeSet.visible) {
				return true;
			}
			const canvasKey = options.chartType + '-' + el.datasetIdx + '-active';
			let offscreen = pointHighlightCanvases[canvasKey];
			if (!offscreen) {
				offscreen = makeOffscreen(activeSize, activeSet, 'rgba(101,187,75,0.7)');
				pointHighlightCanvases[canvasKey] = offscreen;
			}
			const pointStyleArr = Array.isArray(activeSet.pointStyle);
			activeSet.pointStyle = activeSet.data.map((pt, idx) => (idx === el.ptIdx ? offscreen : (pointStyleArr ? activeSet.pointStyle[idx] : 'circle')));
		}
		return true;
	}

};

export default CustomHighlightPlugin;
