import PageComponent from '../../common/component/page-component';
import ordinal from '../../common/utils/ordinal';
import plotRelatedMixin from './plot-related-mixin';

class RankingGraph extends plotRelatedMixin(PageComponent) {

	constructor({
		root,
		element,
		areaIdAttribute = 'areaId',
		pointerAttribute = 'pointer',
		startDetailAttribute = 'startDetail',
		endDetailAttribute = 'endDetail',
		startValueAttribute = 'startValue',
		endValueAttribute = 'endValue',
		rankAttribute = 'rank',
		variableTitleAttribute = 'variableTitle',
		valueAttribute = 'value',
		indicatorAttribute = 'indicator',
		indicatorDetailAttribute = 'indicatorDetail',
		comparisonGraphAttribute = 'comparisonGraph',
		comparisonGraphItemAttribute = 'comparisonGraphItem',
		indicatorDetailPositions = ['first', 'middle', 'last'],
		startDetailText = 'most affected',
		endDetailText = 'least affected',
	}) {
		super({root: root, element: element});
		this.areaIdAttribute = areaIdAttribute;
		this.pointerAttribute = pointerAttribute;
		this.comparisonGraphAttribute = comparisonGraphAttribute;
		this.comparisonGraphItemAttribute = comparisonGraphItemAttribute;

		this.startDetailAttribute = startDetailAttribute;
		this.endDetailAttribute = endDetailAttribute;
		this.startValueAttribute = startValueAttribute;
		this.endValueAttribute = endValueAttribute;
		this.rankAttribute = rankAttribute;
		this.variableTitleAttribute = variableTitleAttribute;
		this.valueAttribute = valueAttribute;
		this.indicatorAttribute = indicatorAttribute;
		this.indicatorDetailAttribute = indicatorDetailAttribute;

		this.startDetailText = startDetailText;
		this.endDetailText = endDetailText;
		this.indicatorDetailPositions = indicatorDetailPositions;

		this.request = null;
		this.variable = null;
		this.rankings = null;
		this.areaRankings = null;

		this.canvasSpacings = {};

		this.sectionWidth = 0;
		this.index = 0;
		this.decimalFactor = 100;

		this.direction = '';
		this.comparisonPositions = [];

		this.convertValues = {
			minValue: 0,
			maxvalue: 0,
			rangeValue: 0,
			containerMin: 0,
			containerMax: 0,
			rangeContainer: 0,
		};
	}


	injectApi(api) {
		this.api = api;
	}


	prepare() {
		this.canvas = this.getComponent('RankingGraphCanvas');
		this.areaId = this.dataAttr().get(this.areaIdAttribute);

		// INIT NODES
		this.variableTitleNode = this.element.querySelector(this.dataSelector(this.variableTitleAttribute));
		this.valueNode = this.element.querySelector(this.dataSelector(this.valueAttribute));
		this.pointerNode = this.element.querySelector(this.dataSelector(this.pointerAttribute));
		this.rankNode = this.element.querySelector(this.dataSelector(this.rankAttribute));
		this.indicatorNode = this.element.querySelector(this.dataSelector(this.indicatorAttribute));
		this.indicatorDetailNode = this.element.querySelector(this.dataSelector(this.indicatorDetailAttribute));
		this.comparisonGraphNode = this.element.querySelector(this.dataSelector(this.comparisonGraphAttribute));
		this.comparisonGraphItemNode = this.comparisonGraphNode.querySelector(this.dataSelector(this.comparisonGraphItemAttribute));
		this.comparisonGraphNode.removeChild(this.comparisonGraphItemNode);

		this.setListeners();

		this.canvasSpacings = this.canvas.getCanvasSpacings();
		this.showBackgroundLines = this.cssData(this.element).get('showBackgroundLines', false);
	}


	setConfig(config) {
		this.config = config;
	}


	setListeners() {
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
	}


	onResize() {
		if (this.rankings) {
			this.showBackgroundLines = this.cssData(this.element).get('showBackgroundLines', false);
			this.canvasSpacings = this.canvas.getCanvasSpacings();

			this.updateValues(this.variable, this.rankings);
			this.updateAreaRankingsIndicators(this.variable, this.areaRankings);
		}
	}


	update(value) {
		this.request = this.api.execute('report/getRankingsByIds', {
			variable: value.variable.id,
			scenario: value.scenario,
			climateModel: value.climateModel,
			impactModel: value.impactModel,
			selector: value.selector,
			areaId: this.areaId
		});
		return this.request.then((response) => {
			if (!this.request.isAborted()) {
				this.request = null;
				if (response.isSuccess()) {
					if (response.output.rankings.length > 0) {
						this.variable = response.output.variable;
						if ('title' in value.variable) {
							this.variable.title = value.variable.title;
						}
						this.rankings = response.output.rankings;
						this.areaRankings = response.output.areaRankings;
						this.canvas.clearCanvas();
						this.updateValues(this.variable, this.rankings);
						this.insertGenericDetails();
						this.updateAreaRankingsIndicators(this.variable, this.areaRankings);

						return {rankings: this.rankings, variable: this.variable, areaRankings: this.areaRankings};
					}
				}
			}
			return null;
		});
	}


	updateValues(variable, rankings) {
		this.direction = variable.direction;
		const strokeWidth = 1;
		const sideStrokeWidth = 2;

		this.sectionWidth = (this.canvasSpacings.width - sideStrokeWidth) / (rankings.length - 1);

		let prevPointPosition = 0;
		let prevSectionWidthMulti = 0;

		this.convertValues.minValue = (this.rankings[rankings.length - 1].value);
		this.convertValues.maxValue = (this.rankings[0].value);
		this.convertValues.rangeValue = this.convertValues.maxValue - this.convertValues.minValue;
		this.convertValues.containerMin = 0;
		this.convertValues.containerMax = this.canvasSpacings.height;
		this.convertValues.rangeContainer = this.convertValues.containerMax - this.convertValues.containerMin;

		const canvasContext = this.canvas.getCanvasContext();
		if (canvasContext) {
			for (let i = 0; i < rankings.length; i++) {
				const value = rankings[i].value;
				const ReferenceLine = this.convertToCanvasSpacings(0);
				const point = this.convertToCanvasSpacings(value);
				const sectionWidthMulti = i * this.sectionWidth;

				this.canvas.drawFill(prevSectionWidthMulti, prevPointPosition, sectionWidthMulti, point);
				this.rankings[i].pixelPosition = sectionWidthMulti;

				if (this.showBackgroundLines) {
					if (i === 0 || i === rankings.length - 1) {
						this.canvas.drawRect(sectionWidthMulti, 0, sideStrokeWidth, this.canvasSpacings.height);
					} else {
						this.canvas.drawRect(sectionWidthMulti, 0, strokeWidth, this.canvasSpacings.height);
					}
				}

				this.canvas.drawLine(prevSectionWidthMulti, prevPointPosition, sectionWidthMulti, point, true);
				this.canvas.drawLine(prevSectionWidthMulti, ReferenceLine, sectionWidthMulti, ReferenceLine, false);

				prevPointPosition = point;
				prevSectionWidthMulti = sectionWidthMulti;
			}
		}
	}


	convertToCanvasSpacings(value) {
		return Math.abs((((value - (this.direction === 'desc' ? this.convertValues.maxValue : this.convertValues.minValue)) * this.convertValues.rangeContainer) / this.convertValues.rangeValue) + this.convertValues.containerMin);
	}


	updateAreaRankingsIndicators(variable, areaRankings) {
		if (areaRankings.length) {
			for (const areaRanking of areaRankings) {
				if (parseInt(areaRanking.selected, 10)) {
					this.updateSelectedIndicator(variable, areaRanking);
					break;
				}
			}
			this.updateAlternativeIndicators(areaRankings);
		}
	}


	updateSelectedIndicator(variable, areaRanking) {
		this.indicatorNode.style.transform = 'translateX(' + Math.round(this.sectionWidth * (areaRanking.position - 1) * 100) / 100 + 'px)';
		this.setPointerPosition(areaRanking);
		this.setIndicatorDetailPosition(areaRanking);
		this.insertSpecificDetails(variable, areaRanking);
	}


	updateAlternativeIndicators(areaRankings) {
		let lastScenarioName = null;
		let lastScenarioBeginPosition = null;
		let lastShadeColor = null;
		this.comparisonGraphNode.innerHTML = '';
		const fragment = document.createDocumentFragment();
		for (let i = 0, last = areaRankings.length - 1; i <= last; i++) {
			const entry = areaRankings[i];
			const scenarioName = entry.scenario;
			if (scenarioName in this.config.scenario) {
				const configEntry = this.config.scenario[scenarioName];
				if (!parseInt(entry.selected, 10)) {
					const color = this.parseColorValue(configEntry.color);
					const line = this.comparisonGraphItemNode.cloneNode(true);
					line.style.backgroundColor = color;
					let lineType = 'individualSimulation';
					if (entry.impactModel === '0') {
						lineType = (entry.climateModel === '0' ? 'multiModelMedian' : 'gcmMedian');
					}
					const lineSize = this.config[lineType].lineSize;
					line.style.width = lineSize + 'px';
					line.style.left = (this.sectionWidth * (parseInt(entry.position, 10) - 1)) - (lineSize > 1 ? lineSize / 2 : 0) + 'px';
					fragment.appendChild(line);
				}

				if (scenarioName !== lastScenarioName || i === last) {
					if (lastScenarioName !== null) {
						const shade = this.comparisonGraphItemNode.cloneNode(true);
						shade.style.backgroundColor = lastShadeColor;
						shade.style.left = (this.sectionWidth * (lastScenarioBeginPosition - 1)) + 'px';
						const lastPosition = parseInt((i === last ? entry.position : areaRankings[i - 1].position), 10);
						shade.style.width = (this.sectionWidth * (lastPosition - lastScenarioBeginPosition)) + 'px';
						shade.style.opacity = this.config.interModelOpacity;
						fragment.appendChild(shade);
					}
					lastScenarioName = scenarioName;
					lastScenarioBeginPosition = parseInt(entry.position, 10);
					lastShadeColor = this.parseColorValue(configEntry.color);
				}
			}
		}
		this.comparisonGraphNode.appendChild(fragment);
	}


	setPointerPosition(areaRanking) {
		const point = this.convertToCanvasSpacings(areaRanking.value);
		this.pointerNode.style.transform = 'translateY(' + Math.round(point * 100) / 100 + 'px)';
	}


	setIndicatorDetailPosition(areaRanking) {
		const rank = areaRanking.position;
		const amount = this.rankings.length - 1;
		const rankingPercent = (amount) / 100;

		for (const position of this.indicatorDetailPositions) {
			this.classList(this.indicatorDetailNode).remove(position);
		}

		if (rank > rankingPercent * 30 && rank < rankingPercent * 70) {
			this.classList(this.indicatorDetailNode).add(this.indicatorDetailPositions[1]);
		} else if (rank > rankingPercent * 70) {
			this.classList(this.indicatorDetailNode).add(this.indicatorDetailPositions[2]);
		}
	}


	insertGenericDetails() {
		const startDetailNode = this.element.querySelector(this.dataSelector(this.startDetailAttribute));
		const endDetailNode = this.element.querySelector(this.dataSelector(this.endDetailAttribute));
		const startValueNode = this.element.querySelector(this.dataSelector(this.startValueAttribute));
		const endValueNode = this.element.querySelector(this.dataSelector(this.endValueAttribute));

		if (this.direction === 'desc') {
			startDetailNode.textContent = this.startDetailText;
			endDetailNode.textContent = this.endDetailText;
			startValueNode.textContent = Math.round(this.convertValues.maxValue * this.decimalFactor) / this.decimalFactor + '%';
			endValueNode.textContent = Math.round(this.convertValues.minValue * this.decimalFactor) / this.decimalFactor + '%';
		} else {
			startDetailNode.textContent = this.endDetailText;
			endDetailNode.textContent = this.startDetailText;
			startValueNode.textContent = Math.round(this.convertValues.minValue * this.decimalFactor) / this.decimalFactor + '%';
			endValueNode.textContent = Math.round(this.convertValues.maxValue * this.decimalFactor) / this.decimalFactor + '%';
		}
	}


	insertSpecificDetails(variable, areaRanking) {
		const rank = areaRanking.position;
		const formattedValue = Math.round(areaRanking.value * this.decimalFactor) / this.decimalFactor;

		this.indicatorDetailNode.textContent = formattedValue + '%' +  ' - ' + ordinal(rank);
		this.valueNode.textContent = formattedValue + '%';
		this.rankNode.textContent = rank;
		if ('title' in variable) {
			this.variableTitleNode.textContent = variable.title;
		}
	}
}

export default RankingGraph;
