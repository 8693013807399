const clazz = 'isp-plotTooltip';

class Tooltip {
	constructor(canvas) {
		let el = this.el = document.querySelector(`.${clazz}`);
		this.canvas = canvas;
		if (!el) {
			el = this.el = document.createElement('div');
			el.classList.add(clazz);
			document.body.appendChild(el);
		}
	}

	renderSections(sections) {
		this.el.innerHTML = sections.reduce((acc, section) => (
			`<div class="${clazz}__section">
					<div class="${clazz}__content">${section.lines.map(line => `<div class="${clazz}__line">${line}</div>`).join('')}</div>
			</div>`
		), '');
	}

	render(tooltipModel) {
		const el = this.el;
		if (tooltipModel.opacity === 0) {
			el.style.opacity = 0;
			return;
		}
		const sections = tooltipModel.body.reduce((acc, b) => {
			acc.push({
				color: b.before.pop(),
				lines: b.lines
			});
			return acc;
		}, []);
		if (sections.length < 1) {
			el.style.opacity = 0;
			return;
		}
		this.renderSections(sections);
		const chartBounds = this.canvas.getBoundingClientRect();
		const left = window.pageXOffset + chartBounds.left + tooltipModel.caretX;
		const flyLeft = chartBounds.right - left < 150;
		const offset = 20;
		el.style.opacity = 1;
		if (flyLeft) {
			el.style.right = (window.innerWidth - (left - offset)) + 'px';
		} else {
			el.style.left = (left + offset) + 'px';
		}
		el.style.top = ((window.pageYOffset + chartBounds.top + tooltipModel.caretY) - 40) + 'px';
	}
}

export default Tooltip;

