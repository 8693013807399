class Request {
    constructor({remoteCall, remoteCallPromise, action, params = {}}) {
		this.remoteCall = remoteCall;
		this.remoteCallPromise = remoteCallPromise;
		this.action = action;
		this.params = params;
		this.aborted = false;
	}


	then(callback) {
		if (this.remoteCall) {
			this.remoteCallPromise = this.remoteCallPromise.then(callback);
			return this.remoteCallPromise;
		}
		return this;
	}


	catch(callback) {
		if (this.remoteCall) {
			this.remoteCallPromise = this.remoteCallPromise.catch(callback);
			return this.remoteCallPromise;
		}
		return this;
	}


	isAborted() {
		return this.aborted;
	}

	abort() {
		if (!this.aborted && this.remoteCall) {
			this.remoteCall.abort();
			this.remoteCall = null;
			this.aborted = true;
		}
	}

}

export default Request;
