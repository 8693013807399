import {TweenMax} from 'gsap';
import PageComponent from '../../common/component/page-component';



class Indicator extends PageComponent {

	constructor({
		root,
		element,
		duration = 2,
		easing = 'Cubic.easeIn',
		activeModifier = 'active',
		indicatorAttribute = 'indicator',
	}) {
		super({root: root, element: element});
		this.defaults.easing = easing;
		this.defaults.duration = duration;
		this.defaults.indicatorAttribute = indicatorAttribute;

		this.next = 0;
		this.selected = 0;
		this.progress = 0;
		this.previousProgress = 0;
		this.activeModifier = activeModifier;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		const indicatorAttribute = data.indicatorAttribute;
		this.startOffset = data.startOffset;
		this.duration = data.duration;
		this.easing = data.easing;

		this.indicatorPosition = 0;


		this.indicator = this.element.querySelector(this.dataSelector(indicatorAttribute));
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));

		this.getSpacings();
		this.createAnimation();
		this.setListeners();
	}


	createAnimation() {
		this.tween = TweenMax.fromTo(this.indicator, this.duration, {
			x: this.indicatorPosition,
			ease: 'Linear.easeNone',
		}, {
			x: 0,
			ease: 'Linear.easeNone',
			paused: true
		});
	}



	setListeners() {
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.listeners.changing = this.events.on(document, 'indicator:change', this.onChange.bind(this));
	}


	getSpacings() {
		this.indicatorPosition = -this.indicator.getBoundingClientRect().width;
	}


	onChange(event) {
		const progress = event.detail.progress;

		if (event.detail.animate) {
			TweenMax.to(this.tween, 0.5, {progress: progress, ease: this.easing});
		} else {
			this.tween.progress(progress);
		}
	}


	onResize() {
		this.getSpacings();
		this.createAnimation();
	}
}


export default Indicator;
