import DefaultContext from './default-context';
import GlossaryContext from './glossary-context';
import MenuContext from './menu-context';
import ModalContext from './modal-context';
import SearchContext from './search-context';
import PageMenuContext from './page-menu-context';


export default (di) => {
	di
		.setType({type: DefaultContext, name: 'DefaultContext', parent: 'Context'})
		.setType({type: GlossaryContext, name: 'GlossaryContext', parent: 'ModalContext', setters: {
				injectPageLoader: di.lazyNew('ServerRenderedPageLoader')
			}
		})
		.setType({type: MenuContext, name: 'MenuContext', parent: 'ModalContext'})
		.setType({type: ModalContext, name: 'ModalContext', parent: 'Context'})
		.setType({type: SearchContext, name: 'SearchContext', parent: 'ModalContext'})
		.setType({type: PageMenuContext, name: 'PageMenuContext', parent: 'Context'})
	;
};
