import PageComponent from '../../common/component/page-component';


class MapLegend extends PageComponent {

	constructor({
		root,
		element,
		barAttribute = 'legendBar',
		valueAttribute = 'legendValue',
		directionClasses = {
			asc: 'directionAsc',
			desc: 'directionDesc'
		}
	}) {
		super({root: root, element: element});
		this.barAttribute = barAttribute;
		this.valueAttribute = valueAttribute;
		this.directionClasses = directionClasses;
	}


	setConfig(config, componentType) {
		this.colors = Object.assign({}, config.colors, config[componentType + 'Colors']);
		this.element.querySelector(this.dataSelector(this.barAttribute, 'zero')).style.backgroundColor = this.parseColorValue(this.colors.zero);
		this.element.querySelector(this.dataSelector(this.barAttribute, 'noData')).style.backgroundColor = this.parseColorValue(this.colors.noData);

		for (const type of ['positive', 'negative']) {
			for (const property of ['hue', 'saturation', 'lightness']) {
				this.colors[type][property + 'RangeSize'] = this.colors[type][property + 'Range'][1] - this.colors[type][property + 'Range'][0];
			}
			const bar = this.element.querySelector(this.dataSelector(this.barAttribute, type));
			bar.style.background = 'linear-gradient(to right, ' +
				'hsl(' + [this.colors[type].hueRange[0] + 'deg', this.colors[type].saturationRange[0] * 100 + '%', this.colors[type].lightnessRange[0] * 100 + '%'].join(', ') + ') 0%, ' +
				'hsl(' + [this.colors[type].hueRange[1] + 'deg', this.colors[type].saturationRange[1] * 100 + '%', this.colors[type].lightnessRange[1] * 100 + '%'].join(', ') + ') 100%' +
			')';
		}
	}


	prepare() {
		this.values = {
			positive: this.element.querySelector(this.dataSelector(this.valueAttribute, 'positive')),
			negative: this.element.querySelector(this.dataSelector(this.valueAttribute, 'negative'))
		};
	}


	setDirection(direction) {
		this.classList().toggle(this.directionClasses.asc, direction === 'asc');
		this.classList().toggle(this.directionClasses.desc, direction === 'desc');
	}


	updateMaxRangeValue(maxRangeValue) {
		const roundedMaxRangeValue = Math.round(maxRangeValue * 100) / 100;
		this.values.positive.textContent = roundedMaxRangeValue + '%';
		this.values.negative.textContent = '-' + roundedMaxRangeValue + '%';
	}


	getColors() {
		return this.colors;
	}


	parseColorValue(value) {
		let color = '';
		const isRgb = ('rgb' in value);
		const isRgba = !isRgb && ('rgba' in value);
		if (isRgb || isRgba) {
			const name = 'rgb' + (isRgb ? '' : 'a');
			color = name + '(' + value[name].join(', ') + ')';
		}
		return color;
	}

}

export default MapLegend;
