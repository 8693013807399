import Chart from 'chart.js';
import Tooltip from './Tooltip';
import {Aspects, PlotTypes} from './../../data/plot-data';
import HistoricalOverlayPlugin from './plugins/HistoricalOverlayPlugin';
import CustomHighlightPlugin from './plugins/CustomHighlightPlugin';
import BackgroundPlugin from './plugins/BackgroundPlugin';
import './plugins/CustomFillerPlugin';

const invisible = 'rgba(0, 0, 0, 0)';

// global chart configuration
Chart.defaults.global = Object.assign(Chart.defaults.global, {
	defaultFontColor: '#3B4444',
	defaultColor: '#3B4444',
	defaultFontSize: 14,
});
Chart.defaults.global.elements.line = Object.assign(Chart.defaults.global.elements.line, {
	borderWidth: 1
});
Chart.defaults.global.elements.point = Object.assign(Chart.defaults.global.elements.point, {
	radius: 5,
	borderWidth: 1
});

const offPatterns = {};

class ChartJSPlot {

	constructor(el, plotData, config = {}) {
		this.el = el;
		this.plotData = plotData;
		this.config = config;
		this.patternFills = {};
		this.lastSelectedPoint = false;
		const styles = window.getComputedStyle(el);
		Chart.defaults.global.defaultFontFamily = styles.getPropertyValue('font-family');
		this.hasHeight = parseInt(styles.getPropertyValue('height'), 10) > 0;
		const canvas = this.canvas = document.createElement('canvas');
		el.append(canvas);
		const ctx = this.ctx = canvas.getContext('2d');
		this.chart = new Chart(ctx, this._getConfig(plotData));
		// https://stackoverflow.com/questions/3799686/clicking-inside-canvas-element-selects-text
		canvas.onmousedown = () => false;
	}

	/* Change the plotData */
	changeData(plotData, datasetsChanged) {
		this.plotData = plotData;
		plotData.datasets.forEach(set => (set.pointStyle = 'circle'));
		const config = this._getConfig(plotData);
		this.chart.data = config.data;
		this.chart.options = config.options;
		this._checkForSingle(plotData);
		if (datasetsChanged) {
			this.lastSelectedPoint = false;
		}
		if (this.lastSelectedPoint) {
			this.setSelected(this.lastSelectedPoint);
		}
		this.chart.update();
	}

	getPatternFill(el, color, bgColor) {
		const fillKey = `${color}${bgColor}`.replace(/[^0-9]/g, '-');
		if (!this.patternFills[fillKey]) {
			if (!offPatterns[fillKey]) {
				const pattern = document.createElement('canvas');
				const size = 8;
				pattern.width = size;
				pattern.height = size;
				const ctx = pattern.getContext('2d', {antialias: false});
				ctx.fillStyle = bgColor;
				const offset = 1;
				const x0 = size + offset;
				const x1 = 0 - offset;
				const y0 = 0 - offset;
				const y1 = size + offset;
				ctx.fillRect(0, 0, pattern.width, pattern.height);
				ctx.strokeStyle = color;
				ctx.lineWidth = 1;
				ctx.beginPath();
				ctx.moveTo(x0, y0);
				ctx.lineTo(x1, y1);
				ctx.moveTo(x0 - size, y0);
				ctx.lineTo(x1 - size, y1);
				ctx.moveTo(x0 + size, y0);
				ctx.lineTo(x1 + size, y1);
				ctx.stroke();
				offPatterns[fillKey] = pattern;
			}
			this.patternFills[fillKey] = this.ctx.createPattern(offPatterns[fillKey], 'repeat');
		}
		return this.patternFills[fillKey];
	}

	_prepareDatasets(plotData) {
		return plotData.datasets.map((dataset, currIdx) => {
			const {aspect} = dataset;
			const isSpreadLine = aspect === Aspects.shading_lower_border || aspect === Aspects.shading_upper_border;
			const isSelected = this.lastSelectedPoint && this.lastSelectedPoint.datasetIdx === currIdx;
			let lineOpacity = isSpreadLine ? 0.1 : 1;
			let dotOpacity = 1;
			if (!isSelected) {
				lineOpacity = this.config[dataset.isiType].notSelectedOpacity;
				dotOpacity = this.config[dataset.isiType].dotsNotSelectedOpacity;
			}
			const lineCol = dataset.rgba(lineOpacity);
			dataset.lineTension = 0;
			dataset.borderColor = lineCol;
			dataset.pointBackgroundColor = lineCol;
			dataset.pointBorderColor = `rgba(255, 255, 255, ${dotOpacity})`;
			dataset.pointBorderWidth = 1;
			dataset.pointHitRadius = 3;
			dataset.pointHoverRadius = 15;
			dataset.pointHoverBackgroundColor = invisible;
			dataset.pointHoverBorderWidth = 0;
			dataset.pointHoverBorderColor = invisible;
			dataset.fill = false;
			dataset.borderWidth = this.config[dataset.isiType].lineSize;
			if (aspect === Aspects.median) {
				if (dataset.visible) {
					// fill to median line with the same fillKey
					const nextFillTo = plotData.datasets.findIndex((d, idx) => (idx !== currIdx && d.fillKey === dataset.fillKey && d.aspect === Aspects.median && !d.hidden && d.visible));
					if (nextFillTo !== -1) {
						dataset.backgroundColor = dataset.rgba(0.1);
						dataset.fill = nextFillTo;
					}
				}
			}
			if (!dataset.visible) {
				dataset.lineCol = invisible;
				dataset.borderColor = invisible;
				dataset.pointBackgroundColor = invisible;
				dataset.pointBorderColor = invisible;
			}
			if (isSpreadLine) {
				dataset.pointBackgroundColor = invisible;
				dataset.pointBorderColor = invisible;
				const {patternOpacity, patternBackgroundOpacity} = this.config.interAnnualVariability;
				dataset.backgroundColor = this.getPatternFill(this.el, dataset.rgba(patternOpacity), dataset.rgba(patternBackgroundOpacity));
				dataset.borderColor = invisible;
				dataset.lineCol = invisible;
				dataset.fill = plotData.datasets.findIndex(d => d.spreadKey === dataset.spreadKey && d.aspect === Aspects.median);
			}
			return dataset;
		});
	}

	_getConfig(plotData) {
		const canvas = this.canvas;
		return {
			type: 'line',
			data: {
				datasets: this._prepareDatasets(plotData),
				labels: plotData.xAxis
			},
			options: {
				xAxisOriginal: plotData.xAxisOriginal,
				responsive: true,
				aspectRatio: 2.25,
				maintainAspectRatio: false, //!this.hasHeight,
				spanGaps: true,
				title: false,
				legend: false,
				scales: {
					yAxes: [{
						ticks: {
							callback: function (label, index, labels) {
								if (label > 9999) {
									return Number.parseFloat(label).toExponential(2);
								}
								return label;
							}
						},
						scaleLabel: {
							display: true,
							labelString: plotData.labelY
						},
						gridLines: {
							color: [],
							zeroLineColor: '#BBC3C3',
							zeroLineBorderDash: [4, 4]
						}
					}],
					xAxes: [{
						scaleLabel: {
							display: true,
							labelString: plotData.labelX
						},
						gridLines: {
							drawOnChartArea: false
						}
					}]
				},
				hover: {
					mode: 'point',
					animationDuration: 0
				},
				onClick: this.onClick.bind(this),
				onHover: this.onHover.bind(this),
				tooltips: {
					enabled: false,
					mode: 'point',
					positioning: 'nearest',
					intersect: true,
					filter: tooltipItem => {
						const dataset = this.chart.data.datasets[tooltipItem.datasetIndex];
						return (dataset.visible && dataset.aspect !== Aspects.shading_upper_border && dataset.aspect !== Aspects.shading_lower_border);
					},
					callbacks: {
						beforeLabel: function (tooltipItem, data) {
							return data.datasets[tooltipItem.datasetIndex].rgba();
						},
						label: (tooltipItem, data) => {
							const dataset = data.datasets[tooltipItem.datasetIndex];
							const lines = [];
							const appendLine = (label, value) => lines.push(
								`<span class="isp-plotTooltip__label">${label}</span> <span class="isp-plotTooltip__value">${value}</span>`
							);
							if (dataset.filters.scenario) {
								appendLine('Scenario:', dataset.filters.scenario);
							}
							if (dataset.filters.climateModel) {
								let label = this.config.multiModelMedian.label;
								if (dataset.filters.impactModel) {
									label = `${dataset.filters.climateModel}/${dataset.filters.impactModel}`;
								}
								appendLine('GCM/IM:', label);
							}
							appendLine('Relative Change: ', `${Number.parseFloat(tooltipItem.yLabel).toFixed(2)}%`);
							//appendLine('At: ', plotData.type === PlotTypes.INDICATOR_VS_TEMPERATURE ? `${tooltipItem.xLabel}°C` : tooltipItem.xLabel);
							return lines;
						}
					},
					custom: function (tooltipModel) {
						if (!this.tooltip) {
							this.tooltip = new Tooltip(canvas);
						}
						this.tooltip.render(tooltipModel);
					}
				},
				animation: false,
				// animation: {
				// 	duration: 250
				// },
				plugins: {
					isiBackground: {
						canvasBackgroundColor: (this.config.pdfMode ? '#FFFFFF' : '#F1F4F4'),
						chartBackgroundColor: '#FFFFFF',
					},
					historicalOverlay: {
						enabled: plotData.type === PlotTypes.INDICATOR_VS_TIMESLICES,
						historyLabel: 'historical period',
						futureLabel: 'future projections',
						endOfHistory: '2010'
					},
					filler: {
						spreadOpacity: this.config.interModelOpacity
					},
					customHighlight: {
						chartType: plotData.type,
						enabled: true
					}
				}
			},
			plugins: [BackgroundPlugin, HistoricalOverlayPlugin, CustomHighlightPlugin]
		};
	}

	_checkForSingle(plotData) {
		const visibleDatasets = plotData.datasets.filter(d => d.visible && d.aspect === Aspects.median && d.filters.scenario !== 'historical');
		const single = visibleDatasets.length === 1;
		if (single) {
			const visibleMedian = visibleDatasets[0];
			plotData.datasets.forEach(dataset => {
				if (dataset.spreadKey === visibleMedian.spreadKey) {
					dataset.hidden = false;
				}
			});
		}
		this.chart.options.plugins.filler.single = single;
	}

	onHover(ev, activeEls) {
		activeEls = activeEls.filter(el => {
			const dataset = this.chart.data.datasets[el._datasetIndex];
			return dataset.visible && dataset.aspect === Aspects.median;
		});
		let updateRequired = false;
		// take note of changes in hover state
		// these are stored in the plugin options for CustomHighlightPlugin
		// hover effect rendering performed by CustomHighlightPlugin
		const highlightOptions = this.chart.options.plugins.customHighlight;
		if (highlightOptions.hoverEls && highlightOptions.hoverEls.length > 0 && activeEls.length === 0) {
			highlightOptions.clearEls = highlightOptions.hoverEls;
			updateRequired = true;
		}
		if (activeEls.length > 0) {
			const overEls = activeEls.map(el => ({
				datasetIdx: el._datasetIndex,
				ptIdx: el._index
			}));
			// diff to reduce updates
			if (JSON.stringify(overEls) !== JSON.stringify(highlightOptions.hoverEls)) {
				highlightOptions.clearEls = highlightOptions.hoverEls;
				highlightOptions.hoverEls = overEls;
				updateRequired = true;
			}
		}
		if (updateRequired) {
			this.chart.update();
		}
	}

	onClick(ev, activeEls) {
		let selected = false;
		activeEls = activeEls.filter(el => {
			const dataset = this.chart.data.datasets[el._datasetIndex];
			return dataset.visible && dataset.aspect === Aspects.median;
		});
		if (activeEls.length > 0) {
			const activeEl = activeEls[0];
			if (this.config.onClick) {
				const dataset = this.chart.data.datasets[activeEl._datasetIndex];
				const scenario = dataset.filters.scenario || Aspects.median;
				if (dataset.filters.scenario === 'overall') {
					dataset.filters.scenario = Aspects.median;
				}
				// callback
				this.config.onClick(scenario, dataset.filters.climateModel, dataset.filters.impactModel, this.chart.options.xAxisOriginal[activeEl._index]);
			}
			selected = {
				datasetIdx: activeEl._datasetIndex,
				ptIdx: activeEl._index
			};
		}
		this.setSelected(selected);
	}

	setSelected(selected) {
		if (!selected) {
			return;
		}
		const highlightOptions = this.chart.options.plugins.customHighlight;
		if (JSON.stringify(selected) !== JSON.stringify(highlightOptions.activeEl)) {
			highlightOptions.clearActiveEl = highlightOptions.activeEl;
			highlightOptions.activeEl = selected;
			// need to update colours
			this.lastSelectedPoint = selected;
			this.chart.data.datasets = this._prepareDatasets(this.plotData);
			this.chart.update();
		}
	}

	selectPoint(scenario, climateModel, impactModel, selector) {
		// try and find matching dataset
		const datasetIdx = this.chart.data.datasets.findIndex(set => {
			let valid = set.visible && !set.hidden && set.aspect === Aspects.median;
			const {filters} = set;
			if (
				(scenario !== filters.scenario) ||
				(climateModel !== filters.climateModel) ||
				(impactModel !== filters.impactModel)
			) {
				valid = false;
			}
			return valid;
		});
		if (datasetIdx === -1) {
			console.log('No dataset found');
			return;
		}
		// try and find matching point
		selector = String(selector);
		const ptIdx = this.chart.options.xAxisOriginal.findIndex(v => String(v) === selector);
		if (ptIdx === -1) {
			console.log('No point found');
			return;
		}
		this.setSelected({
			datasetIdx: datasetIdx,
			ptIdx: ptIdx
		});
	}


	destroy() {
		if (this.chart) {
			this.chart.destroy();
		}
	}


	getImageData(minWidth) {
		this.forcePixelRatio(minWidth);
		const data = this.chart.toBase64Image();
		this.restorePixelRatio();
		return data;
	}


	forcePixelRatio(minWidth) {
		this.originalRatio = ('devicePixelRatio' in this.chart.options ? this.chart.options.devicePixelRatio : window.devicePixelRatio);
		const canvasWidth = this.canvas.width;
		const newRatio = Math.ceil(minWidth / canvasWidth);
		if (newRatio > this.originalRatio) {
			this.forcedRatio = newRatio;
			this.setPixelRatio(newRatio);
		}
	}


	restorePixelRatio() {
		if (this.originalRatio && this.forcedRatio) {
			this.setPixelRatio(this.originalRatio);
			this.forcedRatio = null;
		}
	}


	setPixelRatio(newRatio) {
		const container = this.canvas.parentNode;
		this.chart.options.devicePixelRatio = newRatio;

		if (container.style.width === '') {
			const rect = container.getBoundingClientRect();
			container.style.width = rect.width + 1 + 'px';
		} else {
			container.style.removeProperty('width');
		}
		this.chart.resize();
	}


}

export default ChartJSPlot;
