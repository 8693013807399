import DraggableContent from './draggable-content';
import Indicator from './indicator';
import GlossaryScroller from './glossary-scroller';
import HeaderContent from './header-content';
import Loading from './loading';
import ScrollIndicator from './scroll-indicator';


export default (di) => {
	di
		.setType({type: DraggableContent, name: 'DraggableContent', parent: 'PageComponent'})
		.setType({type: Indicator, name: 'Indicator', parent: 'PageComponent'})
		.setType({type: GlossaryScroller, name: 'GlossaryScroller', parent: 'PageComponent'})
		.setType({type: HeaderContent, name: 'HeaderContent', parent: 'PageComponent'})
		.setType({type: Loading, name: 'Loading', parent: 'PageComponent'})
		.setType({type: ScrollIndicator, name: 'ScrollIndicator', parent: 'PageComponent', setters: {
			injectIntersectionObservers: di.lazyGet('animation/intersectionObservers')
		}})
	;
};
