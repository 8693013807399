import 'javascript-detect-element-resize';
import {TweenMax} from 'gsap';
import 'gsap/ScrollToPlugin';
import {getScrollTop} from '../../common/utils/get-scroll';
import PageComponent from '../../common/component/page-component';


class GlossaryScroller extends PageComponent {

	constructor({
		root,
		element,
		duration = 1,
		touchMoveDuration = 0.1,
		offset = 20,
		glossaryFixedClass = 'inactive',
		idAttribute = 'id',
		navAttribute = 'nav',
		listAttribute = 'list',
		filterAttribute = 'filter',
		hiddenModifier = 'hidden',
		headerContainerAttribute = 'headerContainer',
	}) {
		super({root: root, element: element});
		this.offset = offset;
		this.duration = duration;
		this.touchMoveDuration = touchMoveDuration;
		this.glossaryFixedClass = glossaryFixedClass;
		this.idAttribute = idAttribute;
		this.navAttribute = navAttribute;
		this.listAttribute = listAttribute;
		this.filterAttribute = filterAttribute;
		this.hiddenModifier = hiddenModifier;
		this.headerContainerAttribute = headerContainerAttribute;

		this.positions = new Map;
		this.isAnimating = false;
		this.vibrationSupported = false;
	}


	prepare() {
		this.filterNode = this.element.querySelector(this.dataSelector(this.filterAttribute));
		this.headerContainerNode = this.root.querySelector(this.dataSelector(this.headerContainerAttribute));
		this.filterNodeParent = this.filterNode.parentElement.parentElement;

		this.listNode = this.element.querySelector(this.dataSelector(this.listAttribute));
		this.navNode = this.element.querySelector(this.dataSelector(this.navAttribute));
		this.listItemsNodes = this.listNode.querySelectorAll(this.dataSelector(this.idAttribute));

		this.addResizeListener = window.addResizeListener; //eslint-disable-line piggyback/no-restricted-global-extend
		this.removeResizeListener = window.removeResizeListener; //eslint-disable-line piggyback/no-restricted-global-extend

		this.navigator = navigator;
		this.setVibrationApi();

		this.setListeners();
		this.getPositions(this.listItemsNodes);
	}


	setListeners() {
		this.listeners.glossaryListTouchStart = this.events.on(this.navNode, 'click touchstart', this.onTouchStart.bind(this));
		this.listeners.glossaryListTouchEnd = this.events.on(this.navNode, 'touchend', this.onTouchEnd.bind(this));
		this.listeners.glossaryListTouchMove = this.events.on(this.navNode, 'touchmove', this.onTouchMove.bind(this));

		this.addResizeListener(this.root, this.onResize.bind(this));
		this.listeners.filter = this.events.on(this.root, 'glossary:change', this.onGlossaryChange.bind(this));
	}


	setVibrationApi() {
		if ('vibrate' in this.navigator) {
			this.navigator.vibrate = this.navigator.vibrate || this.navigator.webkitVibrate || this.navigator.mozVibrate || this.navigator.msVibrate;
			if (this.navigator.vibrate) {
				this.vibrationSupported = true;
			}
		}
	}


	onTouchStart(event, target) {
		const isButton = this.dataAttr(target).has('id');

		if (isButton) {
			if (!event.type === 'touchstart') {
				// need to fix body to avoid scrolling when we touch the navigation
				this.classList(this.root).add(this.glossaryFixedClass);
			}

			if (!this.isAnimating) {
				this.isAnimating = true;
				if (target) {
					const targetId = this.dataAttr(target).get(this.idAttribute);
					this.findScrollTarget(targetId, this.duration);
				}
			}
		}
	}


	onTouchEnd() {
		this.classList(this.root).remove(this.glossaryFixedClass);
	}


	onTouchMove(event) {
		event.preventDefault();
		event.stopPropagation();
		const targetNode = document.elementFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
		if (targetNode) {
			const targetId = this.dataAttr(targetNode).get(this.idAttribute);
			this.findScrollTarget(targetId, this.touchMoveDuration);
		}

		this.isAnimating = false;
	}


	onResize() {
		this.getPositions(this.listItemsNodes);
	}


	onGlossaryChange(event) {
		event.preventDefault();
		event.stopPropagation();
		this.isAnimating = false;
		this.positions = new Map;
		this.listItemsNodes = this.listNode.querySelectorAll(this.dataSelector(this.idAttribute));
		this.getPositions(this.listItemsNodes);
	}


	findScrollTarget(targetId, duration) {
		if (targetId !== this.previousTarget) {
			for (const [id, position] of this.positions) {
				if (targetId && targetId === id) {
					this.animateScroll(position, duration);
				}
			}
			this.previousTarget = targetId;
		} else {
			this.isAnimating = false;
		}
	}


	animateScroll(scrollPosition, duration) {
		requestAnimationFrame(() => {
			if (this.vibrationSupported) {
				this.navigator.vibrate(20);
			}
			TweenMax.to(window, duration, {
				scrollTo: {y: scrollPosition, autoKill: false},
				onComplete: () => {
					this.isAnimating = false;
				},
			});
		});
	}


	getPositions(nodes) {
		const navRect = this.navNode.getBoundingClientRect();
		const scrollTop = getScrollTop();
		const filterHeight = this.filterNode.getBoundingClientRect().height;
		const navHeight = navRect.height > navRect.width ? 0 : navRect.height;
		const headerContainerHeight = this.headerContainerNode.getBoundingClientRect().height;
		const listPosition = this.listNode.getBoundingClientRect().bottom + scrollTop;
		const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		const lowestPosition =  listPosition - viewportHeight;

		for (let i = 0; i < nodes.length; i++) {
			const isHidden = this.classList(nodes[i]).contains(this.hiddenModifier);

			if (!isHidden) {
				const nodePosition = nodes[i].getBoundingClientRect().top + scrollTop;
				const position = nodePosition - headerContainerHeight - navHeight - filterHeight - this.offset;

				const scrollPosition = position > lowestPosition ? lowestPosition : position;
				const id = this.dataAttr(nodes[i]).get(this.idAttribute);

				this.positions.set(id, scrollPosition);
			}
		}
	}


	destroy() {
		this.removeResizeListener(this.root, this.onResize);
	}
}


export default GlossaryScroller;
