import PageComponent from '../../common/component/page-component';


class GlossaryFilter extends PageComponent {

	constructor({
		root,
		element,
		hiddenModifier = 'hidden',
		disabledModifier = 'disabled',

		categoryAttribute = 'category',
		termAttribute = 'term',
		listItemAttribute = 'listItem',
		filterAttribute = 'filterButton',
		definitionAttribute = 'definition',
		listAttribute = 'list',
		navButtonAttribute = 'navButton',
	}) {
		super({root: root, element: element});
		this.hiddenModifier = hiddenModifier;
		this.disabledModifier = disabledModifier;

		this.termAttribute = termAttribute;
		this.filterAttribute = filterAttribute;
		this.definitionAttribute = definitionAttribute;
		this.categoryAttribute = categoryAttribute;
		this.listItemAttribute = listItemAttribute;
		this.listAttribute = listAttribute;
		this.navButtonAttribute = navButtonAttribute;

		this.disabled = false;
		this.filterArray = [];
	}


	prepare() {
		this.listItemNodes = this.element.querySelectorAll(this.dataSelector(this.listItemAttribute));
		this.listNode = this.element.querySelector(this.dataSelector(this.listAttribute));
		this.navButtonNodes = this.element.querySelectorAll(this.dataSelector(this.navButtonAttribute));

		this.items = new Map;
		this.createMap();

		this.listeners.filter = this.events.on(this.element, this.dataSelector('filter'), 'click', this.onFilterClick.bind(this));
	}


	onFilterClick(event, target) {
		const category = this.dataAttr(target).get('filter');
		if (!this.disabled) {
			this.disabled = true;
			this.filter(category);
		}
	}


	createMap() {
		for (const listItemNode of this.listItemNodes) {
			const termNodes = listItemNode.querySelectorAll(this.dataSelector(this.categoryAttribute));

			const entry = {
				glossaryItems: [],
				navigationNode: ''
			};
			this.items.set(listItemNode, entry);

			for (const navButtonNode of this.navButtonNodes) {
				const navItemId = this.dataAttr(navButtonNode).get('id');
				const listItemId = this.dataAttr(listItemNode).get('id');

				if (navItemId === listItemId) {
					entry.navigationNode = navButtonNode;
				}
			}

			for (const termNode of termNodes) {
				const category = this.dataAttr(termNode).get(this.categoryAttribute);


				entry.glossaryItems.push({
					node: termNode,
					category: category
				});
			}
		}
	}


	filter(category) {
		requestAnimationFrame(() => {
			window.scrollTo(0, 0);
			this.classList(this.listNode).add(this.hiddenModifier);

			this.onTransitionEnd(this.listNode).then(() => {
				for (const [node, value] of this.items) {
					let glossaryItemcounter = 0;

					// NOTE: set markers for glossaryItems to hide
					for (const glossaryItem of value.glossaryItems) {
						if (glossaryItem.category !== category) {
							this.classList(glossaryItem.node).toggle(this.hiddenModifier);
							glossaryItemcounter ++;
						}
					}

					// NOTE: set marker if parent node has all glossary items hidden
					const glossaryItemsAmount = value.glossaryItems.length;
					if (glossaryItemcounter === glossaryItemsAmount) {
						this.classList(node).toggle(this.hiddenModifier);

						// NOTE: update nav and set markers
						const navigationNode = value.navigationNode;
						this.classList(navigationNode).toggle(this.disabledModifier);
					}
				}
				this.updateGlossaryScroller();
				this.classList(this.listNode).remove(this.hiddenModifier);
				this.disabled = false;
			});
		});
	}



	updateGlossaryScroller(id) {
		this.events.trigger(this.root, 'glossary:change');
	}
}

export default GlossaryFilter;
