import {waitFrame} from '../../common/utils/wait';
import PageComponent from '../../common/component/page-component';
import constants from './constants';


class ConfiguratorSummary extends PageComponent {

	constructor({
		root,
		element,
		summaryTogglerAttribute = 'summaryToggler',
		selectedSlotAttribute = 'selectedSlot',
		selectedSectionAttribute = 'selectedSection',
		selectedValueAttribute = 'selectedValue',
		selectedRemoveAttribute = 'removeSelected',
		templateAttribute = 'selectedTemplate',
		indicatorTemplateAttribute = 'selectedIndicatorTemplate',
		createActionAttribute = 'createAction',
		indicatorListTogglerAttribute = 'indicatorListToggler',
		selectedClass = 'selected',
		openedClass = 'opened',
		readyClass = 'ready',
		expandedClass = 'expanded',
		enabledClass = 'enabled',
		indicatorsSplittedClass = 'indicatorsSplitted',
		namePattern = /\{\{name\}\}/g,
		idPattern = /\{\{id\}\}/g,
		firstIndicatorsLimit = 2,
		firstIndicatorsCollapsedLimit = 1
	}) {
		super({root: root, element: element});
		this.selectedSlotAttribute = selectedSlotAttribute;
		this.selectedSectionAttribute = selectedSectionAttribute;
		this.selectedValueAttribute = selectedValueAttribute;
		this.selectedRemoveAttribute = selectedRemoveAttribute;
		this.templateAttribute = templateAttribute;
		this.indicatorTemplateAttribute = indicatorTemplateAttribute;
		this.createActionAttribute = createActionAttribute;
		this.indicatorListTogglerAttribute = indicatorListTogglerAttribute;
		this.summaryTogglerAttribute = summaryTogglerAttribute;
		this.openedClass = openedClass;
		this.readyClass = readyClass;
		this.selectedClass = selectedClass;
		this.expandedClass = expandedClass;
		this.enabledClass = enabledClass;
		this.indicatorsSplittedClass = indicatorsSplittedClass;
		this.namePattern = namePattern;
		this.idPattern = idPattern;
		this.firstIndicatorsLimit = firstIndicatorsLimit;
		this.firstIndicatorsCollapsedLimit = firstIndicatorsCollapsedLimit;



		this.slots = {};
		this.selectedSections = {};
		this.indicatorsCount = [];
		this.busy = false;
		this.summaryOpened = false;
	}


	prepare() {
		this.configurator = this.components.getComponent(this.root, 'Configurator');
		this.configuratorElement = this.configurator.getElement();
		this.largeLayout = this.cssData(this.configuratorElement).get('largeLayout', false);

		this.template = this.element.querySelector(this.dataSelector(this.templateAttribute)).innerHTML;
		this.indicatorTemplate = this.element.querySelector(this.dataSelector(this.indicatorTemplateAttribute)).innerHTML;

		const slots = this.element.querySelectorAll(this.dataSelector(this.selectedSlotAttribute));
		for (const slot of slots) {
			const name = this.dataAttr(slot).get(this.selectedSlotAttribute);
			if (name === constants.indicator) {
				if (!(name in this.slots)) {
					this.slots[name] = [];
				}
				this.slots[name].push(slot);
			} else {
				this.slots[name] = slot;
			}
		}

		const selectedSections = this.element.querySelectorAll(this.dataSelector(this.selectedSectionAttribute));
		for (const section of selectedSections) {
			this.selectedSections[this.dataAttr(section).get(this.selectedSectionAttribute)] = section;
		}

		this.createAction = this.element.querySelector(this.dataSelector(this.createActionAttribute));

		this.listeners.summaryToggler = this.events.on(this.configuratorElement, this.dataSelector(this.summaryTogglerAttribute), 'click', this.onSummaryToggle.bind(this));
		this.listeners.indicatorListToggle = this.events.on(this.element, this.dataSelector(this.indicatorListTogglerAttribute), 'click', this.onIndicatorListToggle.bind(this));
		this.listeners.removeSelected = this.events.on(this.element, this.dataSelector(this.selectedRemoveAttribute), 'click', this.onRemoveSelected.bind(this));
	}


	onSummaryToggle(event) {
		this.toggleSummary();
	}

	onRemoveSelected(event, target) {
		const id = this.dataAttr(target).getRaw(this.selectedRemoveAttribute);
		const slot = target.closest(this.dataSelector(this.selectedSlotAttribute));
		const name = this.dataAttr(slot).get(this.selectedSlotAttribute);
		this.configurator.removeSelected(name, id);
	}


	onIndicatorListToggle(event, target) {
		event.preventDefault();
		target.blur();
		if (!this.busy) {
			this.busy = true;
			const classList =this.classList(this.summary);
			const expanded = classList.contains(this.expandedClass);
			const slot = this.slots[constants.indicator][1];
			const slotParent = slot.parentNode;
			const slotHeight = slot.getBoundingClientRect().height;
			const slotParentHeight = slotParent.getBoundingClientRect().height;
			waitFrame().then(() => {
				slotParent.style.height = slotParentHeight + 'px';
				return waitFrame();
			}).then(() => {
				slotParent.style.height = (expanded ? slotParentHeight - slotHeight : slotParentHeight + slotHeight) + 'px';
				classList.toggle(this.expandedClass, !expanded);
				return this.onTransitionEnd(slotParent);
			}).then(() => {
				slotParent.style.removeProperty('height');
				this.busy = false;
			});
		}
	}


	toggleSummary() {
		this.summaryOpened = !this.summaryOpened;
		this.classList(this.element).toggle(this.openedClass, this.summaryOpened);
	}


	toggleReady(value) {
		this.classList(this.element).toggle(this.readyClass, value);
	}


	updateSelected(selectedValues) {
		for (const [name, value] of selectedValues) {
			if (name !== constants.indicator) {
				// update summary slots
				let content = '';
				if (value !== constants.empty) {
					content = this.renderTemplate(this.template, name, value);
				}
				if (this.slots[name].innerHTML !== content) {
					this.slots[name].innerHTML = content;
				}
				this.classList(this.selectedSections[name]).toggle(this.selectedClass, content !== '');
			} else {
				// update summary slots
				const content = ['', ''];
				let empty = true;
				// const slot = this.slots[name][slotIndex];
				for (let i = 0; i < value.length; i++) {
					const singleValue = value[i];
					const slotIndex = (value.length <= this.firstIndicatorsLimit || i < this.firstIndicatorsCollapsedLimit ? 0 : 1);
					if (singleValue !== constants.empty) {
						empty = false;
						content[slotIndex] += this.renderTemplate(this.indicatorTemplate, name, singleValue);
					}
				}
				for (let i = 0; i < content.length; i++) {
					if (this.slots[name][i].innerHTML !== content[i]) {
						this.slots[name][i].innerHTML = content[i];
					}
				}
				this.classList(this.selectedSections[name]).toggle(this.selectedClass, !empty);

				this.classList(this.element).toggle(this.indicatorsSplittedClass, value.length > this.firstIndicatorsLimit);
				if (value.length <= this.firstIndicatorsLimit) {
					this.classList(this.element).remove(this.expandedClass);
				}
			}
		}
	}


	updateAction(url, valid) {
		if (valid) {
			this.createAction.setAttribute('href', url);
		}
		this.classList(this.createAction).toggle(this.enabledClass, valid);
	}


	renderTemplate(template, name, id) {
		const entry = this.configurator.getEntry(name, id);
		return template
			.replace(this.namePattern, entry.name)
			.replace(this.idPattern, entry.id)
		;
	}


}

export default ConfiguratorSummary;
