import PageComponent from '../../common/component/page-component';


class HeaderContent extends PageComponent {

	constructor({
		root,
		element,
		highlightedClass = 'highlighted'
	}) {
		super({root: root, element: element});
		this.highlightedClass = highlightedClass;
	}


	prepare() {
		// console.log('prepare header content');
	}


}


export default HeaderContent;
