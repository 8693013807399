import Api from './api';
import Request from './request';


export default (di) => {
	di
		.setType({type: Api, name: 'Api', params: {
				requestFactory: di.lazyGet('api/requestFactory'),
				baseUrl: di.lazyValue('api/baseUrl', '')
			}
		})
		.setType({type: Request, name: 'Request'})
		.set('api/requestFactory', di.newFactory('Request'))
		.set('api/api', di.lazyNew('Api'))
	;
};
