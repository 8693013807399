const BackgroundPlugin = {
	id: 'isiBackground',
	beforeDraw: function (chart) {
		const options = Object.assign({
			enabled: true
		}, chart.options.plugins[this.id]);

		if (!options.enabled) return;
		const ctx = chart.ctx;
		ctx.save();

		// draw canvas background
		if (options.canvasBackgroundColor) {
			ctx.beginPath();
			ctx.fillStyle = options.canvasBackgroundColor;
			ctx.fillRect(0, 0, chart.canvas.width, chart.canvas.height);
		}

		// draw chart area underlay
		if (options.chartBackgroundColor) {
			ctx.fillStyle = options.chartBackgroundColor;
			const box = chart.chartArea;
			ctx.fillRect(box.left, box.top, box.right - box.left, box.bottom - box.top);
			ctx.restore();
		}
	}
};

export default BackgroundPlugin;

