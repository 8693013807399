import PageComponent from '../../common/component/page-component';

class RankingGraphCanvas extends PageComponent {

	constructor({
		root,
		element,
		canvasAttribute = 'canvas',
		lineWidth = 1,
		colorSeparator = 'rgba(89, 100, 99, 0.3)',
		colorReferenceLine = '#8A9393',
		colorLine = '#8A9393',
		colorFill = 'rgba(187,195,195,0.3)',
	}) {
		super({root: root, element: element});
		this.canvasAttribute = canvasAttribute;

		this.lineWidth = lineWidth;

		this.colorSeparator = colorSeparator;
		this.colorReferenceLine = colorReferenceLine;
		this.colorLine = colorLine;
		this.colorFill = colorFill;

		this.elementWidth = 0;
		this.elementHeight = 0;
		this.canvasContext = null;
		this.canvasSpacings = {};
	}


	prepare() {
		this.canvasWrapperNode = this.element;
		this.canvasNode = this.canvasWrapperNode.querySelector(this.dataSelector(this.canvasAttribute));
		this.canvasContext = this.getCanvasContext();

		this.setListeners();

		this.canvasSpacings = this.getCanvasSpacings();
		this.setCanvasSpacings(this.canvasSpacings.width, this.canvasSpacings.height);
	}


	setListeners() {
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
	}


	clearCanvas() {
		this.canvasContext.clearRect(0, 0, this.canvasSpacings.width, this.canvasSpacings.height);
	}


	onResize() {
		this.canvasSpacings = this.getCanvasSpacings();
		this.setCanvasSpacings(this.canvasSpacings.width, this.canvasSpacings.height);
	}


	drawRect(x, y, width, height) {
		this.canvasContext.fillStyle = this.colorSeparator;
		this.canvasContext.fillRect(x, y, width, height);
	}


	drawLine(moveToX, moveToY, lineToX, lineToY, colorDefault = true) {
		this.canvasContext.strokeStyle = colorDefault ? this.colorLine : this.colorReferenceLine;
		this.canvasContext.beginPath();

		this.canvasContext.lineWidth = this.lineWidth;
		this.canvasContext.moveTo(moveToX, moveToY);
		this.canvasContext.lineTo(lineToX, lineToY);

		this.canvasContext.stroke();
	}


	drawFill(moveToX, moveToY, lineToX, lineToY) {
		this.canvasContext.fillStyle = this.colorFill;

		this.canvasContext.beginPath();
		this.canvasContext.moveTo(moveToX, moveToY);

		this.canvasContext.lineTo(lineToX, lineToY);

		this.canvasContext.lineTo(lineToX, this.canvasNode.height);

		this.canvasContext.lineTo(moveToX, this.canvasNode.height);

		this.canvasContext.lineTo(moveToX, moveToY);
		this.canvasContext.closePath();

		this.canvasContext.fill();
	}


	getCanvasNode() {
		return this.canvasNode;
	}


	getCanvasContext() {
		return 	this.canvasNode.getContext('2d');
	}


	getCanvasSpacings() {
		const elementWidth = this.element.getBoundingClientRect().width;
		const elementHeight = this.element.getBoundingClientRect().height;
		return {width: elementWidth, height: elementHeight};
	}


	setCanvasSpacings(width, height) {
		this.canvasNode.width = width;
		this.canvasNode.height = height;
	}
}

export default RankingGraphCanvas;
