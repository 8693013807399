import {TweenMax} from 'gsap';
import Draggable from 'gsap/Draggable';

import PageComponent from '../../common/component/page-component';


class DraggableContent extends PageComponent {

	constructor({
		root,
		element,
		disabled = false,
		easeDuration = 4,
		easing = 'Cubic.easeIn',
		contentAttribute = 'movingContent',
		contentItemsAttribute = 'movingContentItems',
	}) {
		super({root: root, element: element});
		this.element = element;
		this.defaults.easing = easing;
		this.defaults.easeDuration = easeDuration;
		this.defaults.contentAttribute = contentAttribute;

		this.contentItemsAttribute = contentItemsAttribute;

		this.disabled = disabled;
		this.childrenSpacings = [];
		this.contentWidth = 0;
		this.currentPosition = 0;
		this.nextPosition = 0;
		this.tween = null;
	}


	prepare(element) {
		const data = this.dataAttr().getAll();
		this.contentAttribute = data.contentAttribute;
		this.easing = data.easing;
		this.easeDuration = data.easeDuration;

		this.container = this.element;
		this.content = this.element.querySelector(this.dataSelector(data.contentAttribute));

		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));

		this.createDraggable();
		this.getSpacings();
	}


	animateTo() {
		this.tweening = true;
		this.tween = TweenMax.fromTo(this.content, this.easeDuration, {
			x: this.currentPosition,
		}, {
			x: this.nextPosition,
			ease: this.easing,
			onComplete: () => {
				this.tweening = false;
			}
		});
	}


	createDraggable() {
		this.draggable = Draggable.create(this.content, {
			type: 'x',
			bounds: {
				minX: 0,
				maxX: this.getBounds()
			},
			dragClickables: true,
			onDrag: this.onDrag.bind(this),
			onDragEnd: this.onDragEnd.bind(this)
		});
	}


	getBounds() {
		this.contentWidth = this.content.scrollWidth;
		const containerWidth = this.container.getBoundingClientRect().width;
		const maxBounds = containerWidth - this.contentWidth;
		this.maxBounds = maxBounds;
		return maxBounds;
	}


	getClosest() {
		let animatePosition = 0;
		let x = this.currentPosition;

		for (let i = 0; i < this.childrenSpacings.length; i++) {
			x = x < 0 ? -x : x;
			const triggerPos = this.childrenSpacings[i].width / 2 + this.childrenSpacings[i].position;
			const diff = triggerPos - x > 0 ? triggerPos - x : 0;

			if (diff > 0) {
				if (x < -this.maxBounds && x !== -this.maxBounds  && this.childrenSpacings[i].position < -this.maxBounds) {
					animatePosition = this.childrenSpacings[i].position;
					return animatePosition;
				} else {
					animatePosition = -this.maxBounds;
					return animatePosition;
				}
			}
		}

		return animatePosition;
	}

	getSpacings() {
		this.childrenSpacings = [];
		const childrenNodes = this.element.querySelectorAll(this.dataSelector(this.contentItemsAttribute));
		const children =  childrenNodes ? childrenNodes : this.content.children;

		for (let i = 0; i < children.length; i++) {
			const rect = children[i].getBoundingClientRect();
			const childInnerWidth = rect.width;
			const viewportDistance = this.content.getBoundingClientRect().left;
			const childStartPos = rect.left - viewportDistance;

			this.childrenSpacings.push({
				width: childInnerWidth,
				position: childStartPos,
			});
		}
	}


	onDrag() {
		this.updateProgress(false);
	}


	onDragEnd() {
		if (!this.disabled) {
			this.updatePositions();
			this.animateTo();
			this.updateProgress(true);
		}
	}


	onResize() {
		this.updateBounds();
		this.getSpacings();

		if (!this.disabled) {
			this.updatePositions();
			this.animateTo();
			this.updateProgress(true);
		}
	}


	updateBounds() {
		this.draggable[0].applyBounds({minX: 0, maxX: this.getBounds()});
	}


	updatePositions() {
		this.currentPosition = this.draggable[0].x;
		this.nextPosition = -this.getClosest();
	}


	updateProgress(animate) {
		// simplify functions to work in both cases
		const negativeX = animate ? this.nextPosition : this.draggable[0].x;
		const progress =  (100 / this.maxBounds * negativeX) / 100;
		this.events.trigger(document, 'indicator:change', {
			progress: progress,
			animate: animate
		});
	}
}


export default DraggableContent;

