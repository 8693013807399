import Context from '../../common/context/context';


class PageMenuContext extends Context {
	constructor({
		name,
		root,
		popOnKey = 'esc',
		activeClass = 'active',
		pageMenuAttribute = 'pageMenu',
		pageMenuButtonAttribute = 'pageMenuButton',
		pageMenuLinkAttribute = 'pageMenuLink',
		headerContainerAttribute = 'headerContainer',
	}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.activeClass = activeClass;

		this.pageMenuAttribute = pageMenuAttribute;
		this.pageMenuButtonAttribute = pageMenuButtonAttribute;
		this.pageMenuLinkAttribute = pageMenuLinkAttribute;
		this.headerContainerAttribute = headerContainerAttribute;
		this.linkEvent = null;

		this.pageMenuButtonPos = 0;
	}


	beforeSwitchIn(otherContext) {
		this.pageMenuNode = this.root.querySelector(this.dataSelector(this.pageMenuAttribute));
		// const headerContainer = this.root.querySelector(this.dataSelector(this.headerContainerAttribute));

		// const pageMenuPosition = this.pageMenuNode.getBoundingClientRect().top;
		// const headerContainerHeight = headerContainer.getBoundingClientRect().bottom;

		// requestAnimationFrame(() => {
		// 	console.log('start animation');
		// 	TweenMax.to(window, this.duration, {
		// 		scrollTo: {y: pageMenuPosition - headerContainerHeight, autoKill: true}
		// 	});
		// });

		this.pageMenuNode.remove();
		return otherContext;
	}


	beforeSwitchOut(otherContext) {
		return this.threeStateTransition(this.overlayNode).remove(this.activeClass).then(() => this.threeStateTransition(this.pageMenuNode).remove(this.activeClass).then(() => {
				this.pageMenuNode.remove();
				return otherContext;
			}));
		}


	switchIn(otherContext) {
		this.overlayNode = this.getElement();
		this.overlayNode.appendChild(this.pageMenuNode);
		this.threeStateTransition(this.overlayNode).add(this.activeClass).then(() => {
			this.threeStateTransition(this.pageMenuNode).add(this.activeClass).then(() => {
				this.linkListener = this.events.on(this.pageMenuNode, 'click', this.onClick.bind(this));
				return otherContext;
			});
		});

		return otherContext;
	}


	switchOut(otherContext) {
		if (this.linkListener) {
			this.linkListener.destroy();
		}
		const wrapper = this.root.querySelector(this.dataSelector('pageMenuEntryPoint'));
		wrapper.appendChild(this.pageMenuNode);
		return otherContext;
	}


	onClick(event, target) {
		const href = target.getAttribute('href');
		if (href) {
			if (this.contexts.getCurrentContext() === this) {
				if (href.charAt(0) !== '#' || !this.getElement().querySelector(href)) {
					event.preventDefault();
					this.contexts.pop().then(() => {
						target.click();
					});
				}
			}
		}
	}
}


export default PageMenuContext;
