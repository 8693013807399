import PageComponent from '../../common/component/page-component';


class ScrollIndicator extends PageComponent {


	constructor({
		root,
		element,
		markedModifier = 'marked',
		observedElementAttribute = 'observed',
		relatedItemsAttribute = 'relatedToObserved',
		relatedNestedItemsAttribute = 'relatedToObservedNested',

	}) {
		super({
			root: root,
			element: element
		});
		this.markedModifier = markedModifier;
		this.relatedItemsAttribute = relatedItemsAttribute;
		this.observedElementAttribute = observedElementAttribute;
		this.relatedNestedItemsAttribute = relatedNestedItemsAttribute;

		this.previousRelatedNode = null;
		this.previousParentRelatedNode = null;

		this.hasNested = true;

		this.intersectionObserverId = this;

		this.offsetTop = 20 + 'px ';
		this.offsetBottom = 0;
	}

	prepare() {
		this.observedNodes = this.root.querySelectorAll(this.dataSelector(this.observedElementAttribute));

		this.relatedNodes = this.element.querySelectorAll(this.dataSelector(this.relatedItemsAttribute));
		this.relatedNestedNodes = this.element.querySelectorAll(this.dataSelector(this.relatedNestedItemsAttribute));

		this.resizeListener = this.events.on(window, 'window:resize', this.onResize.bind(this));

		this.getRootBoundSizes();
		this.setObserver();
	}


	injectIntersectionObservers(io) {
		this.intersectionObservers = io;
	}


	setObserver() {
		this.intersectionObservers.initObserver(this.intersectionObserverId, 0, this.onIntersect.bind(this), this.offsetTop + '0px ' + this.offsetBottom + '0px ');
		this.intersectionObservers.observe(this.intersectionObserverId, this.observedNodes);
	}


	onIntersect(entries) {
		for (const entry of entries) {
			if (entry.isIntersecting) {
				const id = entry.target.getAttribute('id');
				this.removePreviousNodeMarker().then(() => {
					this.markRelatedNestedNodes(id);

					if (!this.hasNested) {
						this.markRelatedNodes(id);
						this.hasNested = true;
					}
				});
			}
		}
	}

	markRelatedNodes(id) {
		for (const relatedNode of this.relatedNodes) {
			const relatedId = relatedNode.getAttribute('href');

			if ('#' + id === relatedId) {
				const parentRelatedNode = relatedNode;
				this.previousParentRelatedNode = parentRelatedNode;
				requestAnimationFrame(() => {
					this.classList(parentRelatedNode).add(this.markedModifier);
				});
			}
		}
	}


	markRelatedNestedNodes(id) {
		for (const relatedNode of this.relatedNestedNodes) {
			const relatedId = relatedNode.getAttribute('href');

			if ('#' + id === relatedId) {
				const parentRelatedNode = this.findParentRelatedNode(relatedNode, 'href');
				this.previousRelatedNode = relatedNode;
				this.previousParentRelatedNode = parentRelatedNode;
				requestAnimationFrame(() => {
					this.classList(relatedNode).add(this.markedModifier);
					this.classList(parentRelatedNode).add(this.markedModifier);
				});
			} else {
				this.hasNested = false;
			}
		}
	}


	removePreviousNodeMarker() {
		// NOTE: removes only the modifiers that where set.
		// If constructor gets called because of context change etc. Remove all modifiers

		return new Promise((resolve) => {
			if (this.previousRelatedNode && this.previousParentRelatedNode) {
				this.classList(this.previousRelatedNode).remove(this.markedModifier);
				this.classList(this.previousParentRelatedNode).remove(this.markedModifier);
			} else {
				for (const relatedNode of this.relatedNodes) {
					const relatedContainsModifier = this.classList(relatedNode).contains(this.markedModifier);

					if (relatedContainsModifier) {
						this.classList(relatedNode).remove(this.markedModifier);
					}
				}

				for (const relatedNestedNode of this.relatedNestedNodes) {
					const relatedNestedContainsModifier = this.classList(relatedNestedNode).contains(this.markedModifier);

					if (relatedNestedContainsModifier) {
						this.classList(relatedNestedNode).remove(this.markedModifier);
					}
				}
			}
			resolve();
		});
	}


	findParentRelatedNode(node, attr) {
		let active = true;
		let sibling = null;

		// NOTE: go up the tree until sibling with href was found
		while (active) {
			const parent = node.parentNode;
			sibling = parent.previousElementSibling;
			if (sibling && sibling.hasAttribute(attr)) {
				active = false;
				return sibling;
			} else {
				node = parent;
			}
		}
		return sibling;
	}


	getRootBoundSizes() {
		const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		const intersectionAreaHeight = 100;
		this.offsetBottom = viewportHeight - intersectionAreaHeight;
		this.offsetBottom = -this.offsetBottom + 'px ';
	}


	onResize() {
		this.destroy();
		this.getRootBoundSizes();
		this.setObserver();
	}


	destroy() {
		this.intersectionObservers.destroyObserver(this.intersectionObserverId);
	}
}

export default ScrollIndicator;
