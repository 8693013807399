import plotRelatedMixin from './plot-related-mixin';
import CountryRankingMap from './country-ranking-map';
import DataFilters from './data-filters';
import FilterEntities from './filter-entities';
import MapLegend from './map-legend';
import RankingGraph from './ranking-graph';
import RankingInfo from './ranking-info';
import RankingMap from './ranking-map';
import RankingGraphCanvas from './ranking-graph-canvas';


export default (di) => {
	di
		.setMixin({mixin: plotRelatedMixin, name: 'plotRelatedMixin'})
		.setType({type: CountryRankingMap, name: 'CountryRankingMap', parent: 'PageComponent', mixins: ['plotRelatedMixin'], setters: {
				injectApi: di.lazyGet('api/api')
			}
		})
		.setType({type: DataFilters, name: 'DataFilters', parent: 'PageComponent', mixins: ['plotRelatedMixin']})


		.setType({type: FilterEntities, name: 'FilterEntities'})
		.setType({type: MapLegend, name: 'MapLegend', parent: 'PageComponent'})
		.setType({type: RankingGraph, name: 'RankingGraph', parent: 'PageComponent', setters: {
				injectApi: di.lazyGet('api/api')
			}
		})

		.setType({type: RankingGraphCanvas, name: 'RankingGraphCanvas', parent: 'PageComponent'})

		.setType({type: RankingInfo, name: 'RankingInfo', parent: 'PageComponent', mixins: ['plotRelatedMixin']})
		.setType({type: RankingMap, name: 'RankingMap', parent: 'PageComponent', setters: {
				injectApi: di.lazyGet('api/api'),
				injectFilterEntities: di.lazyNew('FilterEntities')
			}
		})
	;
};
