import queryString from 'query-string';
import {decamelize} from '../../common/utils/string';
import PageComponent from '../../common/component/page-component';
import plotRelatedMixin from './plot-related-mixin';


class RankingInfo extends plotRelatedMixin(PageComponent) {

	constructor({
		root,
		element,
		downloadLinkAttribute = 'downloadLink',
		baseDownloadUrlAttribute = 'baseDownloadUrl',
		labelsAttribute = 'labels',
		infoEntryAttribute = 'infoEntry',
		infoNameAttribute = 'infoName',
		infoValueAttribute = 'infoValue',
		hiddenClass = 'hidden'
	}) {
		super({root: root, element: element});
		this.downloadLinkAttribute = downloadLinkAttribute;
		this.baseDownloadUrlAttribute = baseDownloadUrlAttribute;
		this.labelsAttribute = labelsAttribute;
		this.infoEntryAttribute = infoEntryAttribute;
		this.infoNameAttribute = infoNameAttribute;
		this.infoValueAttribute = infoValueAttribute;
		this.hiddenClass = hiddenClass;
	}


	prepare() {
		const data = this.dataAttr();
		this.labels = data.get(this.labelsAttribute);
		this.baseDownloadUrl = data.get(this.baseDownloadUrlAttribute);
		this.downloadLink = this.element.querySelector(this.dataSelector(this.downloadLinkAttribute));

		this.info = {};
		const infoEntries = this.element.querySelectorAll(this.dataSelector(this.infoEntryAttribute));
		for (const entry of infoEntries) {
			const name = this.dataAttr(entry).get(this.infoEntryAttribute);
			this.info[name] = {
				entry: entry,
				name: entry.querySelector(this.dataSelector(this.infoNameAttribute)),
				value: entry.querySelector(this.dataSelector(this.infoValueAttribute))
			};
		}

		this.valueNames = this.getFilterEntities().getValueNames();
	}


	update(value) {
		const type = value.variable.type;
		this.info.selector.name.textContent = this.labels[type];
		this.classList(this.info.scenario.entry).toggle(this.hiddenClass, type === 'temperature');
		if (value.selector in this.valueNames.selector) {
			this.info.selector.value.textContent = (type === 'temperature' ?
				this.valueNames.selector[value.selector] + '° C' :
				this.valueNames.selector[value.selector].split('-').join('—')
			);
		}
		if (type !== 'temperature') {
			let scenarioName = '';
			if (value.scenario === '0') {
				 scenarioName = this.labels.scenarioMedian;
			} else if (value.scenario in this.valueNames.scenario) {
				scenarioName = this.getConfigAlternative('scenario', this.valueNames.scenario[value.scenario]);
			}
			this.info.scenario.value.textContent = scenarioName;
		}
		if (value.climateModel === '0') {
			this.info.models.value.textContent = this.labels.modelsMedian;
		} else {
			let text = '';
			if (value.climateModel in this.valueNames.climateModel) {
				text += this.getConfigAlternative('climateModel', this.valueNames.climateModel[value.climateModel]);
			}
			if (value.impactModel in this.valueNames.impactModel) {
				text += ' / ' + this.getConfigAlternative('impactModel', this.valueNames.impactModel[value.impactModel]);
			}
			this.info.models.value.textContent = text;
		}
		this.updateDownloadLink(value);
	}


	getConfigAlternative(type, name) {
		if (this.config &&
			type in this.config &&
			name in this.config[type] &&
			'label' in this.config[type][name]
		) {
			return this.config[type][name].label;
		}
		return name;
	}


	updateDownloadLink(value) {
		const params = {};
		for (const name of ['scenario', 'climateModel', 'impactModel']) {
			if (value[name] !== '0' && value[name] in this.valueNames[name]) {
				params[decamelize(name)] = this.valueNames[name][value[name]];
			}
		}
		if (value.selector !== '0' && value.selector in this.valueNames.selector) {
			params[(value.variable.type === 'temperature' ? 'temperature' : 'time')] = this.valueNames.selector[value.selector];
		}
		const url =
			this.baseDownloadUrl +
			encodeURIComponent(value.variable.name) + '.txt?' +
			queryString.stringify(params)
		;
		// console.log('url', url);
		this.downloadLink.setAttribute('href', url);
	}

}

export default RankingInfo;
