import CustomImageMap from '../../common/widget/custom-image-map';


class ConfiguratorMap extends CustomImageMap {

	constructor({
		root,
		element,
		imageUrlAttribute = 'imageUrl',
		enabledClass = 'enabled',
		selectedClass = 'selected'
	}) {
		super({root: root, element: element, imageUrlAttribute: imageUrlAttribute, enabledClass: enabledClass});
		this.selectedClass = selectedClass;
		this.items = new Map();
		this.itemsByNode = new Map();
		this.selectedItem = null;

		this.zoomPaddingTopLeft = [0, 0];
		this.zoomPaddingBottomRight = [0, 0];
	}


	prepare() {
		super.prepare();
		this.on('fileLoad').then(() => {
			this.fetchNodeItems();
		});
		this.on('load').then(() => {
			this.map.on('click', this.onClick.bind(this));
		});
	}


	clear() {
		this.map.off('click', this.onClick.bind(this));
	}


	setZoomPadding(topLeft, bottomRight) {
		this.zoomPaddingTopLeft = topLeft;
		this.zoomPaddingBottomRight = bottomRight;
	}
	// onMoveStart(event) {
	// 	console.log('move start');
	// 	this.moving = true;
	// }


	// onMoveEnd(event) {
	// 	console.log('move end');
	// 	this.moving = false;
	// }


	onClick(event) {
		const target = event.originalEvent.target.closest('.area' + this.classSelector(this.enabledClass) + ':not(' + this.classSelector(this.selectedClass) + ')');
		if (target) {
			const pk = target.getAttribute('id');
			if (this.items.has(pk)) {
				this.events.trigger(this.element, 'area:click', {pk: pk});
			}
		}
	}


	fetchNodeItems() {
		const areas = this.svg.querySelectorAll('.area');
		for (const area of areas) {
			const pk = area.getAttribute('id');
			const entry = {
				pk: pk,
				node: area,
				bounds: null,
				mainNode: area.querySelector('.main')
			};
			this.items.set(pk, entry);
			this.itemsByNode.set(area, entry);
		}
	}


	updateItem(pk, enabled, selected = null) {
		if (this.items.has(pk)) {
			const node = this.items.get(pk).node;
			const classList = this.classList(node);
			classList.toggle(this.enabledClass, enabled);
			if (selected !== null) {
				classList.toggle(this.selectedClass, selected);
				if (selected) {
					this.selectedItem = node;
				}
			}
		}
	}


	updateSelected(pk = null) {
		return this.on('load').then(() => {
			if (this.selectedItem) {
				this.classList(this.selectedItem).remove(this.selectedClass);
				this.selectedItem = null;
			}
			if (pk !== null) {
				this.updateItem(pk, true, true);
				return this.zoomItem(pk);
			}
			return Promise.resolve();
		});
	}


	zoomItem(pk) {
		return this.on('load').then(() => {
			if (this.items.has(pk)) {
				const entry = this.items.get(pk);
				if (entry.bounds === null) {
					const parentRect = this.svg.getBoundingClientRect();
					if (parentRect.width > 0) {
						const size = this.getOriginalSize();
						const scale = parentRect.width / size.width;
						const node = entry.mainNode ? entry.mainNode : entry.node;
						const rect = node.getBoundingClientRect();
						const bounds = [
							[
								(rect.left - parentRect.left) / scale,
								(parentRect.bottom - rect.top) / scale
							],
							[
								(rect.right - parentRect.left) / scale,
								(parentRect.bottom - rect.bottom) / scale
							]
						];
						entry.bounds = bounds;
					}
				}
				if (entry.bounds !== null) {
					this.fitBounds(entry.bounds, false, {
						paddingTopLeft: this.zoomPaddingTopLeft,
						paddingBottomRight: this.zoomPaddingBottomRight
					});
				}
			} else {
				this.fitBounds(null);
			}
		});
	}

}

export default ConfiguratorMap;
