import httpRequest from 'superagent';
import leaflet from 'leaflet';
import PageComponent from '../component/page-component';


class CustomImageMap extends PageComponent {

	constructor({
		root,
		element,
		imageUrlAttribute = 'imageUrl',
		enabledClass = 'enabled'
	}) {
		super({root: root, element: element});
		this.imageUrlAttribute = imageUrlAttribute;
		this.enabledClass = enabledClass;
		this.library = leaflet;
		this.map = null;

		this.enabled = false;
		this.loading = false;
		this.mapReady = false;
	}


	prepare() {
		this.openAsyncEvent('fileLoad');
		this.openAsyncEvent('load');
		this.load();
	}


	clear() {
		//
	}


	load() {
		const data = this.dataAttr();
		const mapUrl = data.get(this.imageUrlAttribute);
		// this.initMap(mapUrl);
		httpRequest
			.get(mapUrl)
			.then((response) => {
				this.element.innerHTML = response.text;
				this.svg = this.element.querySelector('svg');
				this.originalWidth = Number(this.svg.getAttribute('width'));
				this.originalHeight = Number(this.svg.getAttribute('height'));
				this.originalRatio = this.originalWidth / this.originalHeight;
				this.closeAsyncEvent('fileLoad');
				this.initMap();
				this.closeAsyncEvent('load');
			})
		;
		return this.on('load');
	}


	onClick(event, target) {
	}


	initMap() {
		if (this.mapReady) {
			return;
		}
		this.originalBounds = [this.xy(0, 0), this.xy(this.originalWidth, this.originalHeight)];
		// const bounds = [[0, 0], [1372, 2716]];

		this.map = leaflet.map(this.element, {
			center: this.xy(this.originalWidth / 2, this.originalHeight / 2),
			zoomSnap: 0.01,
			zoom: 0,
			minZoom: -10,
			maxZoom: 8,
			crs: leaflet.CRS.Simple,
			renderer: leaflet.SVG,
			maxBounds: this.originalBounds,
			zoomControl: false,
			attributionControl: false
		});

		this.overlay = leaflet.svgOverlay(this.svg, this.originalBounds, {
			interactive: true
		}).addTo(this.map);

		this.mapReady = true;

		// this.map.on('resize', () => {
		// 	console.log('resizing');
		// });

		// // this.map.fitWorld();
		// // this.map.fitBounds(bounds);
		// console.log('center', this.map.getCenter());
		// console.log('bounds', this.map.getBounds());
		// console.log('bounds zoom', this.map.getBoundsZoom(bounds));
		// console.log('size', this.map.getSize());
		// console.log('pixel bounds', this.map.getPixelBounds());
		// console.log('pixel origin', this.map.getPixelOrigin());
		// console.log(this.map.getMinZoom(), this.map.getMaxZoom(), this.map.getZoom());
		// console.log('map', this.map);
	}


	invalidateSize() {
		this.map.invalidateSize(false);
	}


	fitBounds(bounds = null, perfectFit = true, options = {}) {
		if (bounds === null) {
			bounds = this.originalBounds;
		} else {
			bounds = [this.xy(bounds[0]), this.xy(bounds[1])];
		}
		this.map.fitBounds(bounds, options);
		if (perfectFit) {
			const zoom = this.map.getBoundsZoom(bounds, true);
			this.map.setZoom(zoom);
		}
	}


	setMaxBounds(bounds, maxZoom = true) {
		bounds = [this.xy(bounds[0]), this.xy(bounds[1])];
		this.map.setMaxBounds(bounds);
		if (maxZoom) {
			const zoom = this.map.getBoundsZoom(bounds, true);
			this.map.setMinZoom(zoom);
		}
	}


	zoomToScale(zoom) {
		return this.library.CRS.Simple.scale(zoom);
	}


	scaleToZoom(scale) {
		return this.library.CRS.Simple.zoom(scale);
	}


	getOriginalSize() {
		return {
			width: this.originalWidth,
			height: this.originalHeight
		};
	}


	getZoom() {
		return this.map.getZoom();
	}


	getDriver() {
		return this.map;
	}


	getLibrary() {
		return this.library;
	}


	enable() {
		if (!this.enabled) {
			return this.on('load').then(() => {
				this.invalidateSize();
				this.classList(this.element).add(this.enabledClass);
				this.enabled = true;
			});
		}
		return Promise.resolve();
	}


	disable() {
		if (this.enabled) {
			this.classList(this.element).remove(this.enabledClass);
			this.enabled = false;
		}
		return Promise.resolve();
	}


	xy(x, y) {
		if (Array.isArray(x)) {
			return [x[1], x[0]];
		}
		return [y, x];
	}

}

export default CustomImageMap;
