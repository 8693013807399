import DataSync from './data-sync';

export default (di) => {
	di
		.setType({type: DataSync, name: 'DataSync', parent: 'PageComponent', setters: {
				injectApi: di.lazyGet('api/api')
			}
		})
	;
};
