import IspApp from './isp-app';


export default (di) => {
	di
		.setType({
			type: IspApp, name: 'IspApp', parent: 'App', params: {
				requiredStyles: ['default'],
				requiredFonts: [
					{
						name: 'IBM Plex Sans',
						options: {weight: '400'}
					},
					{
						name: 'IBM Plex Serif',
						options: {weight: '600'}
					}
				]
			}, setters: {
				injectPages: di.lazyGet('page/pages'),
				injectPageSlots: di.lazyGet('page/slots'),
				injectHashScroller: di.lazyNew('HashScroller'),
			}, postCreateCalls: [(app) => {
				const transitionRouter = di.get('router/pageTransitionRouter');
				const loaderRouter = di.get('router/pageLoaderRouter');

				transitionRouter
					// fallback to default transition
					.append(transitionRouter.newRule().always().then((subject, matches, request) => {
							// console.log('match default page transition');
							request.transition = 'default';
							return subject;
						}).setLast()
					)
				;

				loaderRouter
					// fallback to server side rendered page
					.append(loaderRouter.newRule().always().then((subject, matches, request) => {
							// console.log('match default page loader');
							request.loader = 'serverRendered';
							return subject;
						}).setLast()
					)
				;
			}]
		})
		.set('app', di.lazyNew('IspApp'))
	;
};
