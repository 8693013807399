import commonConfig from './common';

import animationConfig from '../animation/_config';
import apiConfig from '../api/_config';
import appConfig from '../app/_config';
import configuratorConfig from '../configurator/_config';
import contextConfig from '../context/_config';
import displayConfig from '../display/_config';
import galleryConfig from '../gallery/_config';
import navigationConfig from '../navigation/_config';
import pageConfig from '../page/_config';
import plotsConfig from '../plots/_config';
import rankingConfig from '../ranking/_config';
import syncConfig from '../sync/_config';


const config = (di) => {
	commonConfig(di);

	animationConfig(di);
	apiConfig(di);
	appConfig(di);
	configuratorConfig(di);
	contextConfig(di);
	displayConfig(di);
	galleryConfig(di);
	navigationConfig(di);
	pageConfig(di);
	plotsConfig(di);
	rankingConfig(di);
	syncConfig(di);

	const prefix = 'isp-';
	const classPrefix = 'isp-js-';

	di
		.setValue('dom/dataAttrPrefix', prefix)
		.setValue('dom/classPrefix', classPrefix)
		.setValue('dom/baseClassPrefix', prefix)
		;

		const bodyData = di.get('dom/dataAttrFactory')({element: document.body});
		const baseUrl = bodyData.get('baseUrl');
		di
			.setValue('baseUrl', baseUrl)
			.setValue('api/baseUrl', bodyData.get('apiUrl'))
			.setParam('PageLoader', 'baseUrl', baseUrl)
			.setParam('HashScroller', 'duration', 0.5)
			.setParam('Navigation', 'navigateSameUrl', true)
	;
};


export default config;
