class FilterEntities {

	constructor() {
		this.filters = null;
		this.valueNames = {};
		this.nameValues = {};
	}


	init(filters) {
		this.filters = filters;
		for (const type in this.filters) {
			if (this.filters.hasOwnProperty(type)) {
				const filter = this.filters[type];
				this.valueNames[type] = {};
				this.nameValues[type] = {};
				for (const entry of filter) {
					this.valueNames[type][entry.value] = entry.name;
					this.nameValues[type][entry.name] = entry.value;
				}
			}
		}
	}



	getTextValues(value) {
		const textValues = {};
		for (const name of ['scenario', 'climateModel', 'impactModel', 'selector']) {
			textValues[name] = [];
			for (let i = 0, end = value[name].length; i < end; i++) {
				textValues[name][i] = (value[name][i] === '0' ? 'median' : this.valueNames[name][value[name][i]]);
			}
		}
		return textValues;
	}


	getValueNames() {
		return this.valueNames;
	}


	getNameValues() {
		return this.nameValues;
	}

}

export default FilterEntities;
