import Configurator from './configurator';
import ConfiguratorMap from './configurator-map';
import ConfiguratorSearch from './configurator-search';
import ConfiguratorSections from './configurator-sections';
import ConfiguratorSummary from './configurator-summary';
import MiniConfigurator from './mini-configurator';


export default (di) => {
	di
		.setType({type: Configurator, name: 'Configurator', parent: 'PageComponent', setters: {
				injectApi: di.lazyGet('api/api'),
				injectHistory: di.lazyGet('navigation/history')
			}
		})

		.setType({type: ConfiguratorMap, name: 'ConfiguratorMap', parent: 'CustomImageMap'})
		.setType({type: ConfiguratorSearch, name: 'ConfiguratorSearch', parent: 'PageComponent'})
		.setType({type: ConfiguratorSections, name: 'ConfiguratorSections', parent: 'PageComponent'})
		.setType({type: ConfiguratorSummary, name: 'ConfiguratorSummary', parent: 'PageComponent'})
		.setType({type: MiniConfigurator, name: 'MiniConfigurator', parent: 'PageComponent'})
	;
};
