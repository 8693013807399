import PageComponent from '../../common/component/page-component';
import plotRelatedMixin from './plot-related-mixin';


class DataFilters extends plotRelatedMixin(PageComponent) {

	constructor({
		root,
		element,
		variablesAttribute = 'variables',
		combinationsAttribute = 'combinations'
	}) {
		super({root: root, element: element});
		this.variablesAttribute = variablesAttribute;
		this.combinationsAttribute = combinationsAttribute;

		this.selects = new Map();
		this.toggled = null;
		this.previousValue = {};
	}


	prepare() {
		const data = this.dataAttr();
		const selects = this.getComponents('CustomSelect');
		this.multi = null;
		for (const select of selects) {
			if (this.multi === null) {
				this.multi = select.isMulti();
			}
			this.selects.set(this.dataAttr(select.getElement()).get('type'), select);
		}
		this.listeners.change = this.events.on(this.element, this.dataSelector('component', 'CustomSelect'), 'select:change', this.onChange.bind(this));
		this.listeners.toggle = this.events.on(this.element, this.dataSelector('component', 'CustomSelect') + ' ' + this.dataSelector('component', 'Toggler'), 'toggler:beforetoggle', this.onToggle.bind(this));

		this.variables = data.get(this.variablesAttribute);
		this.combinations = data.get(this.combinationsAttribute);
		for (const variable of this.variables) {
			this.previousValue[variable.name] = null;
		}
		this.setVariable(this.variables[0]);
	}


	onChange(event) {
		this.update(this.variable);
	}


	onToggle(event) {
		requestAnimationFrame(() => {
			if (!event.detail.toggled) {
				if (this.toggled && this.toggled.isToggled() && this.toggled !== event.detail.component) {
					this.toggled.toggle();
				}
				this.toggled = event.detail.component;
			}
		});
	}


	setConfig(config) {
		const valueNames = this.getFilterEntities().getValueNames();
		for (const input of this.selects.get('scenario').getInputs()) {
			const id = input.value;
			if (id in valueNames.scenario)  {
				const name = valueNames.scenario[id];
				if (name in config.scenario) {
					input.parentNode.style.color = this.parseColorValue(config.scenario[name].color);
				}
			}
		}
	}


	setVariable(variable) {
		const variableChanged = (!this.variable || this.variable.name !== variable.name);
		this.variable = variable;
		this.selects.get('scenario').toggleEnable(variable.type !== 'temperature');
		this.update(variable, variableChanged);
	}


	update(variable, variableChanged = false) {
		const hasPreviousValue = (variableChanged && this.previousValue[variable.name] !== null);
		const value = (variableChanged && hasPreviousValue ? this.previousValue[variable.name] : this.getValue());
		if (!this.multi) {
			for (const name of this.selects.keys()) {
				value[name] = [value[name]];
			}
		}

		for (const select of this.selects.values()) {
			for (const input of select.getInputs()) {
				input.disabled = true;
				input.checked = false;
			}
		}

		const varName = variable.name;
		const previousValue = this.previousValue[varName];

		const processCombinations = (combs, names, forceSelect) => {
			names = names.slice();
			if (names.length) {
				const currentName = names.shift();
				const inputs = this.selects.get(currentName).getInputsMap();
				for (const id in combs) {
					if (combs.hasOwnProperty(id) && inputs.has(id)) {
						const input = inputs.get(id);
						input.disabled = false;
						const checked = (forceSelect || value[currentName].indexOf(id) >= 0);
						const justChecked = (forceSelect || (checked && previousValue !== null && currentName in previousValue && previousValue[currentName].indexOf(id) < 0));
						if (checked) {
							input.checked = true;
							if (names.length) {
								processCombinations(combs[id], names, this.multi && justChecked);
							}
						}
					}
				}
			}
		};

		const combinations = this.combinations[variable.id];
		processCombinations(combinations, ['selector', 'scenario', 'climateModel', 'impactModel'], this.multi && variableChanged && !hasPreviousValue);

		if (!this.multi) {
			for (const select of this.selects.values()) {
				// if the previously selected input has been disabled we fallback to the first not disabled input (the medians)
				if (!select.hasValue()) {
					for (const input of select.getInputs()) {
						if (!input.disabled) {
							input.checked = true;
							break;
						}
					}
				}
			}
		}
		const updatedValue = this.getValue();
		this.previousValue[varName] = updatedValue;
		this.selects.get('climateModel').toggleEnable(!(this.multi && updatedValue.scenario.length === 0));
		this.selects.get('impactModel').toggleEnable(!(this.multi && updatedValue.climateModel.length === 0) && !(!this.multi && updatedValue.climateModel === '0'));
		this.raiseValue(updatedValue);
	}


	getValue() {
		const values = {
			variable: this.variable,
			multi: this.multi
		};
		for (const [name, select] of this.selects) {
			values[name] = select.getValue();
		}
		return values;
	}


	raiseValue(value = null) {
		if (value === null) {
			value = this.getValue();
		}
		this.events.trigger(this.element, 'filters:change', {value: value});
	}

}

export default DataFilters;
