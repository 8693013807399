import LinksReference from './links-reference';
import PageMenuAccordion from './page-menu-accordion';
import PageMenuNav from './page-menu-nav';



export default (di) => {
	di
	.setType({type: LinksReference, name: 'LinksReference', parent: 'PageComponent'})
	.setType({type: PageMenuAccordion, name: 'PageMenuAccordion', parent: 'Accordion'})
	.setType({type: PageMenuNav, name: 'PageMenuNav', parent: 'PageComponent'})
	;
};
