const values = {
	type: 'type',
	area: 'area',
	topic: 'topic',
	indicator: 'indicator',
	empty: '0',
	urlParamSeparator: '.',
	urlKeySeparator: '-'
};

values.urlKeys = {
	d: values.type,
	a: values.area,
	t: values.topic,
	i: values.indicator
};

export default values;
