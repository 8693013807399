import katex from 'katex/';
import PageComponent from '../component/page-component';

class MathFormula extends PageComponent {

	constructor({
		root,
		element,
		formulaAttribute = 'formula'
	}) {
		super({root: root, element: element});
		this.formulaAttribute = formulaAttribute;
	}


	prepare() {
		const formula = this.dataAttr().getRaw(this.formulaAttribute, '');
		if (formula && formula.length) {
			requestAnimationFrame(() => {
				let html;
				try {
					html = katex.renderToString(formula);
				} catch (e) {
					if (e instanceof katex.ParseError) {
						// KaTeX can't parse the expression
						html = ("Error in LaTeX '" + formula + "': " + e.message)
							.replace(/&/g, '&amp;')
							.replace(/</g, '&lt;')
							.replace(/>/g, '&gt;')
						;
					} else {
						throw e;  // other error
					}
				}
				this.element.innerHTML = html;
			});
		}
	}

}

export default MathFormula;
