import Container from './common/di/container';
import config from './isp/config/config';

const container = new Container();
config(container);

const app = container.get('app');

app.init().then(() => app.execute());

