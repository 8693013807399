import ReportPlot from './report-plot';
import ReportPlotLegend from './report-plot-legend';
import ReportPlotManager from './report-plot-manager';


export default (di) => {
	di
		.setType({type: ReportPlot, name: 'ReportPlot', parent: 'PageComponent', setters: {
				injectApi: di.lazyGet('api/api')
			}
		})
		.setType({type: ReportPlotLegend, name: 'ReportPlotLegend', parent: 'PageComponent'})
		.setType({type: ReportPlotManager, name: 'ReportPlotManager', parent: 'PageComponent', setters: {
				injectFilterEntities: di.lazyNew('FilterEntities')
			}
		})
	;
};
