// import {TweenMax} from 'gsap';
// import 'gsap/ScrollToPlugin';
// import {trim} from '../../common/utils/string';
import PageComponent from '../../common/component/page-component';


class DataSync extends PageComponent {

	constructor({
		root,
		element,
		startActionAttribute = 'syncStart',
		syncTitleAttribute = 'syncTitle',
		syncInfoAttribute = 'syncInfo',
		syncTasksAttribute = 'syncTasks',
		syncPreviousSyncsAttribute = 'syncPreviousSyncs',
		syncItemTemplateAttribute = 'syncItemTemplate',
		syncItemNameAttribute = 'syncItemName',
		syncItemInfoAttribute = 'syncItemInfo',
		syncItemStatusAttribute = 'syncItemStatus',
		syncProgressValueAttribute = 'syncProgressValue',
		syncProgressBarAttribute = 'syncProgressBar',
		invalidatePdfCacheAttribute = 'invalidatePdfCache',
		readyClass = 'ready',
		runningClass = 'running',
		pollingInterval = 5 // sec.
	}) {
		super({root: root, element: element});
		this.startActionAttribute = startActionAttribute;
		this.syncTitleAttribute = syncTitleAttribute;
		this.syncInfoAttribute = syncInfoAttribute;
		this.syncTasksAttribute = syncTasksAttribute;
		this.syncPreviousSyncsAttribute = syncPreviousSyncsAttribute;
		this.syncItemTemplateAttribute = syncItemTemplateAttribute;
		this.syncItemNameAttribute = syncItemNameAttribute;
		this.syncItemInfoAttribute = syncItemInfoAttribute;
		this.syncItemStatusAttribute = syncItemStatusAttribute;
		this.syncProgressValueAttribute = syncProgressValueAttribute;
		this.syncProgressBarAttribute = syncProgressBarAttribute;
		this.invalidatePdfCacheAttribute = invalidatePdfCacheAttribute;
		this.readyClass = readyClass;
		this.runningClass = runningClass;
		this.pollingInterval = pollingInterval;

		this.interval = null;
	}


	injectApi(api) {
		this.api = api;
	}


	prepare() {
		this.startAction = this.element.querySelector(this.dataSelector(this.startActionAttribute));
		this.invalidatePdfCacheAction = this.element.querySelector(this.dataSelector(this.invalidatePdfCacheAttribute));
		this.template = this.element.querySelector(this.dataSelector(this.syncItemTemplateAttribute)).content;
		this.title = this.element.querySelector(this.dataSelector(this.syncTitleAttribute));
		this.info = this.element.querySelector(this.dataSelector(this.syncInfoAttribute));
		this.tasks = this.element.querySelector(this.dataSelector(this.syncTasksAttribute));
		this.previousSyncs = this.element.querySelector(this.dataSelector(this.syncPreviousSyncsAttribute));
		this.progressValue = this.element.querySelector(this.dataSelector(this.syncProgressValueAttribute));
		this.progressBar = this.element.querySelector(this.dataSelector(this.syncProgressBarAttribute));

		this.listeners.start = this.events.on(this.startAction, 'click', this.onStart.bind(this));
		this.listeners.start = this.events.on(this.invalidatePdfCacheAction, 'click', this.onInvalidatePdfCache.bind(this));
		this.checkStatus();
	}


	clear() {
	}


	onStart(event) {
		this.startAction.disabled = true;
		this.startSync();
	}


	onInvalidatePdfCache(event) {
		this.invalidatePdfCache();
	}


	checkStatus() {
		return this.api.execute('sync/status', {}).then((response) => {
			this.processStatus(response);
		});
	}


	invalidatePdfCache() {
		this.invalidatePdfCacheAction.disabled = true;
		return this.api.execute('sync/invalidatePdfCache', {}).then(() => {
			this.invalidatePdfCacheAction.disabled = false;
		});
	}


	startSync() {
		this.title.textContent = 'Starting Sync process...';
		return this.invalidatePdfCache().then(() =>
			this.api.execute('sync/start', {})).then((response) =>
			this.processStatus(response))
		;
	}


	nextTask() {
		return this.api.execute('sync/nextTask', {}).then((response) => this.processStatus(response));
	}


	processStatus(response) {
		if ('sync' in response.output) {
			const sync = response.output.sync;
			this.startAction.disabled = !!sync;
			this.updateSync(sync);
			this.updateTasks(response.output.tasks);
			this.updatePreviousSyncs(response.output.previousSyncs);
			if (sync) {
				if (!('skipped' in response.output)) {
					this.nextTask();
				}
				if (!this.interval) {
					this.interval = setInterval(() => this.nextTask(), this.pollingInterval * 1000);
				}
			} else {
				if (this.interval) {
					clearInterval(this.interval);
					this.interval = null;
				}
			}
		} else {
			console.log('error', response);
		}
	}


	updateSync(sync) {
		const classList = this.classList();
		classList.add(this.readyClass);
		if (sync) {
			this.title.textContent = 'Process #' + sync.id;
			this.info.textContent = sync.created;
			classList.add(this.runningClass);
		} else {
			this.title.textContent = 'No running Sync processes';
			classList.remove(this.runningClass);
		}
	}


	updateTasks(tasks) {
		const fragment = document.createDocumentFragment();
		let total = 0;
		let completed = 0;
		for (const task of tasks) {
			const node = this.template.cloneNode(true);
			node.querySelector(this.dataSelector(this.syncItemNameAttribute)).textContent = '#' + task.id + ': ' + task.type + ' — ' + task.variable_name;
			node.querySelector(this.dataSelector(this.syncItemInfoAttribute)).textContent =
				task.started +
				(task.status === 'complete' || task.status === 'failed' ? ' — ' + task.completed : '')
			;
			node.querySelector(this.dataSelector(this.syncItemStatusAttribute)).textContent = task.status;
			fragment.appendChild(node);
			if (task.type !== 'preparation') {
				const size = parseInt(task.size, 10);
				total += size;
				if (task.status === 'complete' || task.status === 'failed') {
					completed += size;
				}
			}
		}
		requestAnimationFrame(() => {
			const percent = (total > 0 ? Math.round(completed / total * 100) : '--');
			this.progressValue.textContent = percent + '%';
			if (total > 0) {
				this.progressBar.style.transform = 'translateX(' + percent  + '%)';
			}
			this.tasks.innerHTML = '';
			this.tasks.appendChild(fragment);
		});
	}


	updatePreviousSyncs(syncs) {
		const fragment = document.createDocumentFragment();
		for (const sync of syncs) {
			const node = this.template.cloneNode(true);
			node.querySelector(this.dataSelector(this.syncItemNameAttribute)).textContent = 'Process #' + sync.id;
			node.querySelector(this.dataSelector(this.syncItemInfoAttribute)).textContent = sync.created + ' — ' + sync.completed;
			fragment.appendChild(node);
		}
		requestAnimationFrame(() => {
			this.previousSyncs.innerHTML = '';
			this.previousSyncs.appendChild(fragment);
		});
	}


}

export default DataSync;
