import PageComponent from '../../common/component/page-component';


class ReportPlotManager extends PageComponent {

	constructor({
		root,
		element,
		configAttribute = 'plotConfig',
		variablesAttribute = 'variables',
		filtersAttribute = 'filters',
		defaultValueAttribute = 'defaultValue',
		plotSwitchViewAttribute = 'plotSwitchView',
		plotSwitchAttribute = 'plotSwitch',
		plotSwitchLabelAttribute = 'plotSwitchLabel',
		expandAttribute = 'plotExpand',
		downloadAttribute = 'plotDownload',
		pdfModeAttribute = 'pdfMode',
		dataLinkAttribute = 'dataLink',
		dataLinkRaw = 'raw',
		dataLinkProcessed = 'processed',
		expandedClass = 'expanded',
		showMapClass = 'showMap',
		minDownloadWidth = 2400 //px, ~= 8in * 300dpi
	}) {
		super({root: root, element: element});
		this.configAttribute = configAttribute;
		this.variablesAttribute = variablesAttribute;
		this.filtersAttribute = filtersAttribute;
		this.defaultValueAttribute = defaultValueAttribute;
		this.plotSwitchViewAttribute = plotSwitchViewAttribute;
		this.plotSwitchAttribute = plotSwitchAttribute;
		this.plotSwitchLabelAttribute = plotSwitchLabelAttribute;
		this.expandAttribute = expandAttribute;
		this.downloadAttribute = downloadAttribute;
		this.pdfModeAttribute = pdfModeAttribute;
		this.dataLinkAttribute = dataLinkAttribute;
		this.dataLinkRaw = dataLinkRaw;
		this.dataLinkProcessed = dataLinkProcessed;
		this.expandedClass = expandedClass;
		this.showMapClass = showMapClass;
		this.minDownloadWidth = minDownloadWidth;

		this.variables = {};
		this.variablesIndex = {};
		this.previousValue = {};
		this.previousRawValue = {};
		this.currentVariableIndex = 0;

		this.plotConfig = null;
	}


	injectFilterEntities(filterEntities) {
		this.filterEntities = filterEntities;
	}


	prepare() {
		const data = this.dataAttr();
		if (data.has(this.configAttribute)) {
			this.plotConfig = data.get(this.configAttribute, {});
		} else {
			const configProviderComponent = this.components.queryComponent(this.root, this.dataSelector(this.configAttribute));
			if (configProviderComponent) {
				this.plotConfig = configProviderComponent.getPlotConfig();
			}
		}

		this.pdfMode = !!data.get(this.pdfModeAttribute, false);

		const filters = data.get(this.filtersAttribute);
		this.filterEntities.init(filters);
		const variables = data.get(this.variablesAttribute, []);
		this.defaultValue = data.get(this.defaultValueAttribute);
		this.defaultRawValue = {};
		let firstVariable = null;
		let i = 0;
		const valueNames = this.filterEntities.getValueNames();
		const nameValues = this.filterEntities.getNameValues();
		for (const variable of variables) {
			if (firstVariable === null) {
				firstVariable = variable.name;
			}
			this.defaultRawValue[variable.name] = {};
			this.previousValue[variable.name] = null;
			this.variables[variable.name] = variable;
			this.variablesIndex[variable.name] = i;
			for (const type in this.defaultValue[variable.name]) {
				if (this.defaultValue[variable.name].hasOwnProperty(type)) {
					const value = this.defaultValue[variable.name][type];
					const typeName = ((variable.type === 'temperature' && type === 'temperature') || (variable.type !== 'temperature' && type === 'time') ? 'selector' : type);
					if (value === '') {
						this.defaultValue[variable.name][typeName] = '0';
					} else if (typeName in nameValues && value in nameValues[typeName]) {
						this.defaultValue[variable.name][typeName] = nameValues[typeName][value];
					}
					this.defaultRawValue[variable.name][typeName] = (this.defaultValue[variable.name][typeName] === '0' ? 'median' : valueNames[typeName][this.defaultValue[variable.name][typeName]]);
				}
			}
			delete this.defaultValue[variable.name].time;
			delete this.defaultValue[variable.name].temperature;
			i++;
		}

		this.dataFilters = this.getComponent('DataFilters');
		this.plot = this.getComponent('ReportPlot');
		this.rankingGraph = this.getComponent('RankingGraph');
		this.rankingInfo = this.getComponent('RankingInfo');
		this.countryRankingMap = this.getComponent('CountryRankingMap');
		this.mapLegend = this.getComponent('MapLegend');
		this.plotLegend = this.getComponent('ReportPlotLegend');

		if (this.plotConfig) {
			this.dataFilters.setConfig(this.plotConfig.linePlot);
			if (this.plot) {
				this.plot.setConfig(this.plotConfig.linePlot);
			}
			if (this.rankingInfo) {
				this.rankingInfo.setConfig(this.plotConfig.linePlot);
			}
			if (this.rankingGraph) {
				this.rankingGraph.setConfig(this.plotConfig.linePlot);
			}
		}
		if (this.countryRankingMap) {
			this.countryRankingMap.setConfig(this.plotConfig.maps);
			if (this.mapLegend) {
				this.countryRankingMap.setMapLegend(this.mapLegend);
				this.mapLegend.setConfig(this.plotConfig.maps, 'countryMap');
			}
		}

		this.rawDataLink = this.element.querySelector(this.dataSelector(this.dataLinkAttribute, this.dataLinkRaw));
		this.processedDataLink = this.element.querySelector(this.dataSelector(this.dataLinkAttribute, this.dataLinkProcessed));

		this.listeners.viewSwitch = this.events.on(this.element, this.dataSelector(this.plotSwitchViewAttribute), 'change', this.onPlotSwitchViewChange.bind(this));
		this.listeners.plotSwitch = this.events.on(this.element, this.dataSelector(this.plotSwitchAttribute), 'change', this.onPlotSwitchChange.bind(this));
		this.listeners.filtersChange = this.events.on(this.dataFilters.getElement(), 'filters:change', this.onFiltersChange.bind(this));
		this.listeners.plotSelect = this.events.on(this.plot.getElement(), 'plot:select', this.onPlotSelect.bind(this));
		this.listeners.expansion = this.events.on(this.element, this.dataSelector(this.expandAttribute), 'click', this.onTogglePlotSize.bind(this));
		this.listeners.download = this.events.on(this.element, this.dataSelector(this.downloadAttribute), 'click', this.onDownload.bind(this));

		const filterValue = this.dataFilters.getValue();
		const textValues = this.filterEntities.getTextValues(filterValue);
		this.plot.updateFilters(filterValue.variable, textValues);

		if (this.pdfMode) {
			this.plot.load();
			this.plot.setAllowedToRender(true);
		}


		this.fetchPlotsInfo().then(() => {
			this.updateSwitchLabels(this.plotsInfo);
			this.sharePreviousValue(this.variables[firstVariable]);
			if (this.plotsInfo.length && 'rawUrl' in this.plotsInfo[0]) {
				this.rawDataLink.setAttribute('href', this.plotsInfo[0].rawUrl);
			}
			this.listeners.dataLinkProcessed = this.events.on(this.processedDataLink, 'click', this.onDownloadData.bind(this));
		});
	}


	onTogglePlotSize(event) {
		this.classList().toggle(this.expandedClass);
	}


	onDownload(event) {
		const link = document.createElement('a');
		link.style.position = 'absolute';
		document.body.appendChild(link);
		const fileNames = this.plot.getFileNames();
		link.setAttribute('download', fileNames[this.currentVariableIndex] + '.png');
		link.setAttribute('href', this.plot.getImageData(this.minDownloadWidth).replace('image/png', 'image/octet-stream'));
		link.click();
		document.body.removeChild(link);
	}


	onDownloadData(event) {
		this.plot.load().then(() => {
			const data = this.plot.getFileData()[this.currentVariableIndex];
			const link = document.createElement('a');
			link.style.position = 'absolute';
			document.body.appendChild(link);
			const fileNames = this.plot.getFileNames();
			link.setAttribute('download', fileNames[this.currentVariableIndex] + '.json');
			link.setAttribute('href', 'data:application/json,' + JSON.stringify(data));
			link.click();
			document.body.removeChild(link);
		});
	}


	onPlotSwitchViewChange(event, target) {
		if (this.countryRankingMap) {
			this.classList().toggle(this.showMapClass, target.value === 'map');
			this.countryRankingMap[(target.value === 'map' ? 'show' : 'hide')]();
		}
	}


	onPlotSwitchChange(event, target) {
		const varName = target.value;
		const variable = this.variables[varName];
		this.currentVariableIndex = this.variablesIndex[varName];
		this.dataFilters.setVariable(variable);
		this.plotLegend.setVariable(variable);
		this.sharePreviousValue(variable);
		if (this.plotsInfo.length >= this.currentVariableIndex + 1 && 'rawUrl' in this.plotsInfo[this.currentVariableIndex]) {
			this.rawDataLink.setAttribute('href', this.plotsInfo[this.currentVariableIndex].rawUrl);
		}
	}


	onFiltersChange(event) {
		const value = event.detail.value;
		this.plot.updateFilters(value.variable, this.filterEntities.getTextValues(value));
	}


	onPlotSelect(event) {
		const nameValues = this.filterEntities.getNameValues();
		const value = Object.assign({}, event.detail, this.getVariableInfo(event.detail.variable.name));
		let ready = true;
		for (const name of ['scenario', 'climateModel', 'impactModel', 'selector']) {
			const label = String(value[name]);
			const lcLabel = label.toLowerCase();
			if (value[name] === false || lcLabel === 'median' || lcLabel === 'overall' || lcLabel === 'mean') {
				value[name] = '0';
			} else {
				if (label in nameValues[name]) {
					value[name] = nameValues[name][label];
				} else {
					ready = false;
					console.log('matchin id not found', name, value[name], nameValues[name]);
					break;
				}
			}
		}
		if (ready && this.rankingGraph) {
			this.rankingGraph.update(value);
			this.rankingInfo.update(value);
			if (this.countryRankingMap) {
				this.countryRankingMap.update(value);
			}
		}
		this.previousValue[value.variable.name] = value;
	}


	sharePreviousValue(variable) {
		if (this.rankingGraph) {
			const source = (this.previousValue[variable.name] !== null ? this.previousValue[variable.name] : this.defaultValue[variable.name]);
			if (source.selector !== '0') {
				const value = Object.assign(this.getVariableInfo(variable.name), source);
				this.rankingGraph.update(value);
				this.rankingInfo.update(value);
				if (this.countryRankingMap) {
					this.countryRankingMap.update(value);
				}
			}
			const rawSource = (this.previousValue[variable.name] !== null ? this.previousRawValue[variable.name] : this.defaultRawValue[variable.name]);
			if (rawSource) {
				this.plot.selectPoint(rawSource.scenario, rawSource.climateModel, rawSource.impactModel, rawSource.selector);
			}
		}
	}


	getVariableInfo(name) {
		const index = this.variablesIndex[name];
		return {variable: Object.assign({title: this.plotsInfo[index].title}, this.variables[name])};
	}


	getPlotConfig() {
		return this.plotConfig;
	}


	getFilterEntities() {
		return this.filterEntities;
	}


	fetchPlotsInfo() {
		return this.plot.getPlotsInfo().then((info) => {
			this.plotsInfo = info;
			return info;
		});
	}


	updateSwitchLabels(plotsInfo) {
		for (let i = 0; i < plotsInfo.length; i++) {
			this.element.querySelector(this.dataSelector(this.plotSwitchLabelAttribute, i)).textContent = plotsInfo[i].unitLabel;
		}
	}

}

export default ReportPlotManager;
