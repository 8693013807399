import DefaultPage from './default-page';
import ReportPage from './report-page';
import DefaultPageTransition from './default-page-transition';


export default (di) => {
	di
		.setType({type: DefaultPage, name: 'DefaultPage', parent: 'Page'})
		.setType({type: ReportPage, name: 'ReportPage', parent: 'DefaultPage', setters: {
				injectIntersectionObservers: di.lazyGet('animation/intersectionObservers')
			}
		})

		.setType({type: DefaultPageTransition, name: 'DefaultPageTransition', parent: 'PageTransition'})
	;
};
