import PageComponent from '../../common/component/page-component';


class LinksReference extends PageComponent {

	constructor({
		root,
		element,
		selectorsAttribute = 'selectors',
		linksAttribute = 'links',
		templateAttribute = 'linkTemplate',
		referenceTemplateAttribute = 'referenceTemplate',
		referenceAttribute = 'linkReference',
		labelAttribute = 'label',
		urlAttribute = 'url',
		avoidDuplicates = true
	}) {
		super({root: root, element: element});
		this.defaults.avoidDuplicates = avoidDuplicates;

		this.selectorsAttribute = selectorsAttribute;
		this.linksAttribute = linksAttribute;
		this.templateAttribute = templateAttribute;
		this.referenceTemplateAttribute = referenceTemplateAttribute;
		this.referenceAttribute = referenceAttribute;
		this.labelAttribute = labelAttribute;
		this.urlAttribute = urlAttribute;
	}


	prepare() {
		const data = this.dataAttr();
		this.avoidDuplicates = data.get('avoidDuplicates');
		const selectors = data.get(this.selectorsAttribute, []);
		this.template = this.element.querySelector(this.dataSelector(this.templateAttribute)).content;
		const referenceTemplate = this.element.querySelector(this.dataSelector(this.referenceTemplateAttribute)).content;
		this.reference = referenceTemplate.querySelector(this.dataSelector(this.referenceAttribute));
		this.linksContainer = this.element.querySelector(this.dataSelector(this.linksAttribute));
		this.count = 0;
		this.linksMap  = {};
		requestAnimationFrame(() => this.processLinks(selectors));
	}


	processLinks(selectors) {
		const fragment = document.createDocumentFragment();
		const labelSelector = this.dataSelector(this.labelAttribute);
		const urlSelector = this.dataSelector(this.urlAttribute);
		for (const selector of selectors) {
			const links = this.root.querySelectorAll(selector);
			for (const link of links) {
				const label = link.textContent;
				const href = link.getAttribute('href');
				const url = link.href;
				if (label.length && href.indexOf('#') !== 0 && (!this.avoidDuplicates || (!(url in this.linksMap) || this.linksMap[url].indexOf(label) === -1))) {
					this.count++;
					if (!(url in this.linksMap)) {
						this.linksMap[url] = [];
					}
					this.linksMap[url].push(label);
					const reference = this.template.cloneNode(true);
					reference.querySelector(labelSelector).textContent = label;
					reference.querySelector(urlSelector).textContent = url;
					fragment.appendChild(reference);
					const referenceNode = this.reference.cloneNode(true);
					referenceNode.textContent = this.count;
					link.insertAdjacentElement('afterend', referenceNode);
				}
			}
		}
		this.linksContainer.appendChild(fragment);
	}

}


export default LinksReference;
