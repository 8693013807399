import ModalContext from './modal-context';


class GlossaryContext extends ModalContext {

	constructor({name, root, popOnKey = 'esc', fixedClass = 'fixed', activeClass = 'active', contentAttribute = 'glossaryContent', pageUrlAttribute = 'pageUrl', blocksAttribute = 'blocks', loadedClass = 'loaded'}) {
		super({name: name, root: root, popOnKey: popOnKey, fixedClass: fixedClass, activeClass: activeClass});
		this.contentAttribute = contentAttribute;
		this.pageUrlAttribute = pageUrlAttribute;
		this.blocksAttribute = blocksAttribute;
		this.loadedClass = loadedClass;
		this.pageLoaded = false;
	}


	injectPageLoader(pageLoader) {
		this.pageLoader = pageLoader;
	}


	beforeSwitchIn(otherContext) {
		if (!this.pageLoaded) {
			const element = this.getElement();
			const pageUrl = this.dataAttr(element).get(this.pageUrlAttribute, '');
			this.pageLoader.requestPage({url: pageUrl, navigationType: null, query: {parent: 'glossary'}}).then((response) => {
				const div = document.createElement('div');
				div.innerHTML = response.content;
				const blocks = div.querySelector(this.dataSelector(this.blocksAttribute));
				if (blocks) {
					const contentElement = element.querySelector(this.dataSelector(this.contentAttribute));
					blocks.parentNode.removeChild(blocks);
					contentElement.appendChild(blocks);
					this.classList(contentElement).add(this.loadedClass);
					this.pageLoaded = true;
				}
			});
		}
		return otherContext;
	}


}


export default GlossaryContext;
