import PageComponent from '../../common/component/page-component';


class MiniConfigurator extends PageComponent {

	constructor({
		root,
		element,
		inputPkAttribute = 'pk',
		actionAttribute = 'miniConfiguratorAction',
		baseReportUrlAttribute = 'baseReportUrl',
		typePkAttribute = 'typePk',
		areaPkAttribute = 'areaPk',
		topicPkAttribute = 'topicPk',
		disabledClass = 'disabled'
	}) {
		super({root: root, element: element});
		this.inputPkAttribute = inputPkAttribute;
		this.actionAttribute = actionAttribute;
		this.baseReportUrlAttribute = baseReportUrlAttribute;
		this.typePkAttribute = typePkAttribute;
		this.areaPkAttribute = areaPkAttribute;
		this.topicPkAttribute = topicPkAttribute;

		this.disabledClass = disabledClass;

		this.baseReportUrl = '';
	}


	prepare() {
		const dataAttr = this.dataAttr();
		this.baseReportUrl =
			dataAttr.get(this.baseReportUrlAttribute) +
			'/' + dataAttr.get(this.typePkAttribute) +
			'.' + dataAttr.get(this.areaPkAttribute) +
			'.' + dataAttr.get(this.topicPkAttribute)
		;
		this.action = this.element.querySelector(this.dataSelector(this.actionAttribute));
		this.inputs = this.element.querySelectorAll(this.dataSelector(this.inputPkAttribute) + ':not(:disabled)');
		this.listeners.inputChange = this.events.on(this.element, this.dataSelector(this.inputPkAttribute), 'change', this.onInputChange.bind(this));
		this.updateAction();
	}


	onInputChange(event) {
		this.updateAction();
	}


	updateAction() {
		let indicatorsAmount = 0;
		let url = this.baseReportUrl;
		for (const input of this.inputs) {
			if (input.checked) {
				indicatorsAmount++;
				url += '.' + this.dataAttr(input).get(this.inputPkAttribute);
			}
		}
		this.action.setAttribute('href', url);
		this.classList(this.action).toggle(this.disabledClass, indicatorsAmount === 0);
	}

}

export default MiniConfigurator;
