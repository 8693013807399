import httpRequest from 'superagent';
import ordinal from '../../common/utils/ordinal';
import PageComponent from '../../common/component/page-component';


class RankingMap extends PageComponent {

	constructor({
		root,
		element,
		configAttribute = 'plotConfig',
		filtersAttribute = 'filters',
		imageUrlAttribute = 'imageUrl',
		imageContainerAttribute = 'imageContainer',
		tooltipAttribute = 'tooltip',
		tooltipColorAttribute = 'tooltipColor',
		tooltipNameAttribute = 'tooltipName',
		tooltipPositionAttribute = 'tooltipPosition',
		tooltipValueAttribute = 'tooltipValue',
		showClass = 'show',
		tooltipHideWaitTime = 150, // ms
		useGlobalRange = true
		// enabledClass = 'enabled',
		// selectedClass = 'selected'
	}) {
		super({root: root, element: element});
		this.configAttribute = configAttribute;
		this.filtersAttribute = filtersAttribute;
		this.imageUrlAttribute = imageUrlAttribute;
		this.imageContainerAttribute = imageContainerAttribute;
		this.tooltipAttribute = tooltipAttribute;
		this.tooltipColorAttribute = tooltipColorAttribute;
		this.tooltipNameAttribute = tooltipNameAttribute;
		this.tooltipPositionAttribute = tooltipPositionAttribute;
		this.tooltipValueAttribute = tooltipValueAttribute;
		this.showClass = showClass;
		this.tooltipHideWaitTime = tooltipHideWaitTime;

		this.useGlobalRange = useGlobalRange;
		this.rankings = [];
		this.tooltip = null;
		this.tooltipTimeout = null;
		this.leavingArea = true;
		this.request = null;
		this.currentAreaId = null;

		this.maxRangeValue = null;
	}


	injectApi(api) {
		this.api = api;
	}


	injectFilterEntities(filterEntities) {
		this.filterEntities = filterEntities;
	}


	prepare() {
		const data = this.dataAttr();
		if (data.has(this.configAttribute)) {
			this.plotConfig = data.get(this.configAttribute, {});
		} else {
			const configProviderComponent = this.components.queryComponent(this.root, this.dataSelector(this.configAttribute));
			if (configProviderComponent) {
				this.plotConfig = configProviderComponent.getPlotConfig();
			}
		}
		const filters = data.get(this.filtersAttribute);
		this.filterEntities.init(filters);

		this.filters = this.getComponent('DataFilters');
		this.mapLegend = this.getComponent('MapLegend');
		this.rankingInfo = this.getComponent('RankingInfo');
		if (this.plotConfig) {
			this.mapLegend.setConfig(this.plotConfig.maps, 'rankingMap');
			this.rankingInfo.setConfig(this.plotConfig.linePlot);
		}

		this.imageUrl = data.get(this.imageUrlAttribute);
		this.imageContainer = this.element.querySelector(this.dataSelector(this.imageContainerAttribute));

		const tooltip = this.element.querySelector(this.dataSelector(this.tooltipAttribute));
		if (tooltip) {
			this.tooltip = {
				node: tooltip,
				name: tooltip.querySelector(this.dataSelector(this.tooltipNameAttribute)),
				position: tooltip.querySelector(this.dataSelector(this.tooltipPositionAttribute)),
				value: tooltip.querySelector(this.dataSelector(this.tooltipValueAttribute)),
				color: tooltip.querySelector(this.dataSelector(this.tooltipColorAttribute))
			};
			this.listeners.enterArea = this.events.on(tooltip, 'mouseover', this.onMouseEnterTooltip.bind(this));
			this.listeners.leaveArea = this.events.on(tooltip, 'mouseout', this.onMouseLeaveArea.bind(this));
		}

		this.update(this.filters.getValue());
		this.listeners.change = this.events.on(this.filters.getElement(), 'filters:change', this.onFiltersChange.bind(this));
		this.openAsyncEvent('load');
		this.load();
	}


	onFiltersChange(event) {
		const value = event.detail.value;
		if (this.request) {
			this.request.abort();
		}
		this.update(value);
	}


	onMouseEnterArea(event, target) {
		let id = target.getAttribute('id');
		if (!this.rankings) {
			return;
		}
		if (!(id in this.rankings)) {
			target = target.parentNode.closest('.area[id]');
			if (target) {
				id = target.getAttribute('id');
			}
		}
		if (!target || !(id in this.rankings)) {
			console.log('country fail', id, target);
			return;
		}
		if (this.tooltip && this.rankings && (id in this.rankings) && id !== this.currentAreaId) {
			this.leavingArea = false;
			if (this.tooltipTimeout) {
				clearTimeout(this.tooltipTimeout);
			}
			const entry = this.rankings[id];
			const mapEntry = this.paths.get(id);
			target = mapEntry.main !== null ? mapEntry.main : target;
			this.tooltip.name.textContent = entry.name;
			this.tooltip.position.textContent = ordinal(entry.position);
			this.tooltip.value.textContent = Math.round(entry.value * 100) / 100;
			this.tooltip.color.style.backgroundColor = entry.color;
			const targetRect = target.getBoundingClientRect();
			const containerRect = this.imageContainer.getBoundingClientRect();
			const centerX = targetRect.left - containerRect.left + (targetRect.width / 2);
			const centerY = targetRect.top - containerRect.top + (targetRect.height / 2);
			this.classList(this.tooltip.node).add(this.showClass);
			this.tooltip.node.style.transform = 'translateX(' + centerX + 'px) translateY(' + centerY + 'px) translateX(-50%) translateY(-150%)';
		}
	}


	onMouseLeaveArea(event, target) {
		this.leavingArea = true;
		this.tooltipTimeout = setTimeout(() => {
			if (this.leavingArea) {
				this.classList(this.tooltip.node).remove(this.showClass);
			}
		}, this.tooltipHideWaitTime);
	}


	onMouseEnterTooltip(event) {
		this.leavingArea = false;
		if (this.tooltipTimeout) {
			clearTimeout(this.tooltipTimeout);
		}
	}


	load() {
		httpRequest
			.get(this.imageUrl)
			.then((response) => {
				this.imageContainer.innerHTML = response.text;
				this.svg = this.imageContainer.querySelector('svg');
				const container = this.svg.querySelector('#container');
				if (container) {
					container.setAttribute('fill', this.mapLegend.parseColorValue(this.mapLegend.getColors().noData));
				}
				this.paths = new Map();
				const areas = this.svg.querySelectorAll('.area');
				for (const area of areas) {
					const id = area.getAttribute('id');
					const paths = Array.prototype.slice.call(area.querySelectorAll('path'));
					if (area.tagName.toLowerCase() === 'path') {
						paths.push(area);
					}
					this.paths.set(id, {
						main: (area.classList.contains('main') ? area : area.querySelector('.main')),
						paths: paths
					});
				}
				// remove titles, we are using custom tooltips
				const titles = this.svg.querySelectorAll('title');
				for (const title of titles) {
					title.parentNode.removeChild(title);
				}

				this.listeners.enterArea = this.events.on(this.svg, '.area', 'mouseover', this.onMouseEnterArea.bind(this));
				this.listeners.leaveArea = this.events.on(this.svg, '.area', 'mouseout', this.onMouseLeaveArea.bind(this));

				this.closeAsyncEvent('load');
			})
		;
		return this.on('load');
	}


	update(value) {
		this.request = this.api.execute('report/getRankingsByIds', {
			variable: value.variable.id,
			scenario: value.scenario,
			climateModel: value.climateModel,
			impactModel: value.impactModel,
			selector: value.selector,
			requireMaxValue: (this.maxRangeValue === null),
			selectorBasedMax: !!this.plotConfig.maps.rankingMapSelectorBasedMax
		});
		this.rankingInfo.update(value);
		return this.request.then((response) => {
			if (!this.request.isAborted()) {
				this.request = null;
				if (response.isSuccess()) {
					if (this.maxRangeValue === null) {
						this.maxRangeValue = parseFloat(response.output.maxValue);
						this.mapLegend.setDirection(response.output.variable.direction);
						this.mapLegend.updateMaxRangeValue(this.maxRangeValue);
					}
					this.updateValues(response.output.variable, response.output.rankings, this.maxRangeValue);
				}
			}
		});
	}


	updateValues(variable, rankings, maxRangeValue) {
		this.on('load').then(() => {
			if (!rankings.length) {
				return;
			}
			this.rankings = {};
			const colors = this.mapLegend.getColors();
			if (!this.useGlobalRange) {
				const firstValue = rankings[0].value;
				const lastValue = rankings[rankings.length - 1].value;
				maxRangeValue = Math.max(Math.abs(firstValue), Math.abs(lastValue));
			}
			for (const ranking of rankings) {
				let color;
				if (parseFloat(ranking.value) === 0) {
					color = this.mapLegend.parseColorValue(colors.zero);
				} else {
					const valueRatio = Math.min(1, Math.abs(ranking.value) / maxRangeValue);
					const colorDirection = ((ranking.value >= 0 && variable.direction === 'desc') || (ranking.value < 0 && variable.direction === 'asc') ? 'positive' : 'negative');
					const colorDef = colors[colorDirection];
					const colorProps = {};
					for (const property of ['hue', 'saturation', 'lightness']) {
						colorProps[property] = colorDef[property + 'RangeSize'] * valueRatio + colorDef[property + 'Range'][0];
					}
					color = 'hsl(' + [Math.round(colorProps.hue) + 'deg', Math.round(colorProps.saturation * 10000) / 100 + '%', Math.round(colorProps.lightness * 10000) / 100 + '%'].join(', ') + ')';
				}
				this.rankings[ranking.area_pk] = Object.assign({color: color}, ranking);
				if (this.paths.has(ranking.area_pk)) {
					// console.log(ranking.area_pk, ranking.name, ranking.value, valueRatio, color);
					for (const path of this.paths.get(ranking.area_pk).paths) {
						path.style.fill = color;
					}
				}
			}
		});
	}


	getPlotConfig() {
		return this.plotConfig;
	}


	getFilterEntities() {
		return this.filterEntities;
	}

}

export default RankingMap;
