import PageComponent from '../component/page-component';


class CustomSelect extends PageComponent {

	constructor({
		root,
		element,
		multi = false,
		changeEvent = 'select:change',
		emptyValue = '',
		disabledClass = 'disabled'
	}) {
		super({root: root, element: element});
		this.defaults.multi = multi;
		this.defaults.changeEvent = changeEvent;
		this.defaults.emptyValue = emptyValue;
		this.disabledClass = disabledClass;
		this.toggler = null;
		this.inputs = [];
		this.inputsMap = new Map();
		this.enabled = true;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.toggler = this.getComponent('Toggler');
		this.inputs = this.element.querySelectorAll('input');
		for (const input of this.inputs) {
			this.inputsMap.set(input.value, input);
		}
		this.multi = !!data.multi;
		this.changeEvent = data.changeEvent;
		this.emptyValue = data.emptyValue;
		this.enabled = !this.classList().contains(this.disabledClass);

		this.listeners.change = this.events.on(this.element, 'input', 'change', this.onInputChange.bind(this));
	}


	onInputChange(event) {
		this.events.trigger(this.element, this.changeEvent, {value: this.getValue()});
		if (!this.multi) {
			this.toggler.toggle();
		}
	}


	getValue() {
		let value;
		if (this.multi) {
			value = [];
			for (const input of this.inputs) {
				if (input.checked) {
					value.push(input.value);
				}
			}
		} else {
			value = this.emptyValue;
			const checkedInput = this.element.querySelector('input:checked');
			if (checkedInput) {
				value = checkedInput.value;
			}
		}
		return value;
	}


	setValue(value) {
		for (const input of this.inputs) {
			input.checked = (this.multi ? value.indexOf(input.value) >= 0 : value === input.value);
		}
	}


	hasValue() {
		const value = this.getValue();
		return ((this.multi && value.length) || (!this.multi && value !== this.emptyValue));
	}


	getInputs() {
		return this.inputs;
	}


	getInputsMap() {
		return this.inputsMap;
	}


	isMulti() {
		return this.multi;
	}


	disable() {
		if (this.enabled) {
			this.enabled = false;
			this.classList().add(this.disabledClass);
			this.toggler.disable();
		}
	}


	enable() {
		if (!this.enabled) {
			this.enabled = true;
			this.classList().remove(this.disabledClass);
			this.toggler.enable();
		}
	}


	toggleEnable(value = null) {
		if (value === null) {
			value = !this.enabled;
		}
		const method = value ? 'enable' : 'disable';
		this[method]();
	}


	isEnabled() {
		return this.enabled;
	}

}

export default CustomSelect;
