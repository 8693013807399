import 'scroll-restoration-polyfill';
import {getScrollTop} from '../utils/get-scroll';
import {trimUrl} from '../utils/string';
import domMixin from '../dom/dom-mixin';

//import data from '../utils/data';

/*eslint-disable piggyback/no-restricted-global-extend*/
// const ga = window.ga;

class Navigation extends domMixin() {

	constructor({history,
		baseUrl,
		scrollPollingDelay = 350,
		externalUrlOnBlank = true,
		navigateSameUrl = false,
		fileExtensions = ['pdf', 'zip', 'jpg', 'jpeg', 'gif', 'png', 'svg', 'txt']
	}) {
		super();
		this.history = history;
		this.baseUrl = baseUrl;
		this.scrollPollingDelay = scrollPollingDelay;
		this.fileExtensions = fileExtensions;
		this.externalUrlOnBlank = externalUrlOnBlank;
		this.navigateSameUrl = navigateSameUrl;
	}

	init() {
		if (this.history.isSupported()) {
			this.scrollTop = -1;
			this.initPageNavigation();
			this.history.mergeState({title: document.title});
		}
	}


	getHistory() {
		return this.history;
	}


	isHistoryApiSupported() {
		return this.history.isSupported();
	}


	isLocalUrl(url) {
		return (url.indexOf(this.baseUrl) === 0);
	}


	isFileUrl(url) {
		url = url.split('?')[0];
		const dotPos = url.lastIndexOf('.');
		if (dotPos > -1) {
			const ext = url.substr(dotPos + 1).toLowerCase();
			return this.fileExtensions.indexOf(ext) > -1;
		}
		return false;
	}


	initPageNavigation() {
		window.history.scrollRestoration = 'manual';

		let popping = false;
		this.scrollPollingInterval = setInterval(() => {
			if (!popping) {
				const scrollTop = getScrollTop();
				if (scrollTop !== this.scrollTop) {
					this.history.mergeState({scrollTop: scrollTop});
					this.scrollTop = scrollTop;
				}
			}
		}, this.scrollPollingDelay);

		// this.events.on(window, 'window:scroll', (event) => {
		// 	if (scrollTimeout) {
		// 		clearTimeout(scrollTimeout);
		// 	}
		// 	scrollTimeout = setTimeout(onEndScroll, this.scrollPollingDelay);
		// });

		this.events.on(document, 'history:navigate', (event) => {
			if (event.detail.navigationType === 'pop') {
				popping = false;
			}
		});

		this.events.on(document, 'history:popstate', (event) => {
			const customData = {
				url: trimUrl(location.href.split('#')[0]),
				state: this.history.getState(),
				navigationType: 'pop',
				hash: location.hash.length ? location.hash.substr(1) : null
			};
			popping = true;
			this.events.trigger(document.body, 'history:navigate', customData);
		}, {capture: true});

		this.events.on(document, 'a', 'click', (event, target) => {
			let ret = true;
			if (!event.defaultPrevented) {
				let url = trimUrl(event.delegateTarget.href);
				if (!this.isLocalUrl(url)) {
					if (this.externalUrlOnBlank && !target.hasAttribute('target')) {
						target.setAttribute('target', '_blank');
					}
					if (!target.hasAttribute('rel')) {
						target.setAttribute('rel', 'noopener');
					}
				}
				if (
					// event.button check needed to avoid to catch right button clicks on FF
					event.button === 0 &&
					!event.ctrlKey &&
					!event.metaKey &&
					this.isLocalUrl(url) &&
					!this.isFileUrl(url) &&
					!this.classList(event.delegateTarget).contains('download') &&
					!event.delegateTarget.classList.contains('download') &&
					!event.delegateTarget.hasAttribute('target') &&
					!this.classList(event.delegateTarget).contains('noHistoryApi') &&
					!event.delegateTarget.classList.contains('noHistoryApi')
				) {
					let hash = null;
					let raiseNavigateEvent = true;
					[url, hash] = url.split('#');
					const currentUrl = trimUrl(location.href.split('#')[0]);
					url = trimUrl(url);
					if (url === currentUrl && (hash || !this.navigateSameUrl)) {
						this.history.push(currentUrl + (hash ? '#' + hash : ''), {title: document.title}, document.title);
						this.events.trigger(document.body, 'history:hashchange', {hash: hash});
						raiseNavigateEvent = false;
						event.preventDefault();
					}
					if (raiseNavigateEvent) {
						const customData = {
							originalEvent: event,
							originalTarget: event.delegateTarget,
							previousUrl: location.href,
							url: url,
							hash: hash ? hash : null,
							state: {},
							navigationType: 'link'
						};
						this.events.trigger(document.body, 'history:navigate', customData);
					}

					ret = false;
				}
			}
			return ret;
		}, {capture: false});

		return this;
	}


	redirect(url, state = {}, requestParams = {}) {
		if (url.indexOf('http') !== 0 && url.indexOf('/') !== 0) {
			url = this.baseUrl + (url.length ? '/' + url : '');
		}
		let callback;
		if (this.isHistoryApiSupported()) {
			callback = () => {
				const currentUrl = trimUrl(location.href.split('#')[0]);
				url = trimUrl(url);
				let hash = null;
				[url, hash] = url.split('#');
				if (url === currentUrl) {
					this.redirectHash(hash);
				} else {
					const customData = Object.assign({
						previousUrl: location.href,
						url: url,
						hash: hash ? hash : null,
						state: state,
						navigationType: 'redirect'
					}, requestParams);
					this.events.trigger(document.body, 'history:navigate', customData);
				}
			};
		} else {
			callback = () => {
				location.href = url;
			};
		}
		setTimeout(callback, 0);
		return this;
	}


	redirectHash(hash, skipHistory = false) {
		const url = trimUrl(location.href.split('#')[0]);
		if (skipHistory === false) {
			this.history.push(url + (hash ? '#' + hash : ''), {title: document.title}, document.title);
		}
		this.events.trigger(document.body, 'history:hashchange', {hash: hash});
	}


	triggerCurrentPageNavigationEvent() {
		const customData = {
			url: trimUrl(location.href),
			state: this.history.getState(),
			navigationType: 'current',
			hash: location.hash.length ? location.hash.substr(1) : null
		};
		this.events.trigger(document.body, 'history:navigate', customData);
		return this;
	}


	push(url, state = {}, title = null) {
		if (url.indexOf('http') !== 0 && url.indexOf('/') !== 0) {
			url = this.baseUrl + (url.length ? '/' + url : '');
		}
		if (this.history.isSupported()) {
			this.history.push(url, state, title);
		} else {
			setTimeout(() => {
				location.href = url;
			}, 10);
		}
		return this;
	}

}


export default Navigation;
