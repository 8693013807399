import {ucFirst} from '../../common/utils/string';
import {getScrollTop} from '../../common/utils/get-scroll';
import Context from '../../common/context/context';


class DefaultContext extends Context {

	constructor({name, root, popOnKey = false, outClass = 'covered', stickyMenuAttribute = 'stickyMenu', coveredByClass = 'coveredBy'}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.prevScrollPos = 0;
		this.outClass = outClass;
		this.stickyMenuAttribute = stickyMenuAttribute;
		this.coveredByClass = coveredByClass;
		this.elements = null;
		this.stickyMenus = [];
	}


	getElements() {
		if (!this.elements) {
			this.elements = this.root.querySelectorAll(this.dataSelector('context', 'default') + ' > *:not(' + this.dataSelector('context') + ')');
		}
		return this.elements;
	}


	getStickyMenus() {
		this.stickyMenus = this.root.querySelectorAll(this.dataSelector(this.stickyMenuAttribute));
		return this.stickyMenus;
	}


	switchIn(otherContext) {
		for (const node of this.getElements()) {
			if (node) {
				this.classList(node).remove(this.outClass, this.coveredByClass + ucFirst(otherContext.getName()));
				node.style.removeProperty('top');
			}
		}
		if (this.stickyMenus) {
			for (const node of this.stickyMenus) {
				this.classList(node).remove(this.outClass);
				node.style.removeProperty('top');
			}
			this.stickyMenus = [];
		}
		window.scrollTo(0, this.prevScrollPos);
		return otherContext;
	}


	switchOut(otherContext) {
		const scrollPos = getScrollTop();
		this.prevScrollPos = scrollPos;
		const tops = [];
		for (const node of this.getElements()) {
			if (node) {
				const rect = node.getBoundingClientRect();
				tops.push(rect.top);
			}
		}
		const stickyMenus = this.getStickyMenus();
		const stickyMenusTop = new Map();
		for (const node of stickyMenus) {
			stickyMenusTop.set(node, node.getBoundingClientRect().top);
		}

		let i = 0;
		for (const node of this.getElements()) {
			if (node) {
				this.classList(node).add(this.outClass, this.coveredByClass + ucFirst(otherContext.getName()));
				node.style.top = tops[i] + 'px';
			}
			i++;
		}
		for (const [node, top] of stickyMenusTop) {
			this.classList(node).add(this.outClass);
			node.style.top = top + 'px';
		}
		window.scrollTo(0, 0);
		return otherContext;
	}


}


export default DefaultContext;
