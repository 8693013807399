import Chart from 'chart.js';

/**
 * This is to allow us to fill the area enclosed by multiple datasets at a constant opacity without overlaps.
 * We postpone drawing on the chart.js canvas, instead drawing the fills at 100% opacity on offscreen canvases
 * to get the boolean “union”.
 * There is one canvas per “fillKey”.
 * Then, when we come to the last dataset, we draw the offscreen canvases onto the chart.js at the required opacity.
 */

// get the original plugin
const fillerPlugin = Chart.plugins._plugins.find(pl => pl.id === 'filler');

// a reference to the original beforeDraw
const originalBeforeDatasetDraw = fillerPlugin.beforeDatasetDraw;
const originalBeforeDatasetsDraw = fillerPlugin.beforeDatasetsDraw;

// our cache of offscreen fill canvases
let offscreenFills = {};

fillerPlugin.beforeDatasetsDraw = function (chart) {
	offscreenFills = {};
	if (originalBeforeDatasetsDraw) {
		originalBeforeDatasetsDraw(chart);
	}
};

fillerPlugin.beforeDatasetDraw = function (chart, args) {
	if (chart.options.plugins.filler.single) {
		originalBeforeDatasetDraw(chart, args);
		return;
	}
	const dataset = chart.data.datasets[args.index];
	const {fillKey, visible} = dataset;
	if (fillKey && visible) {
		let offscreen = offscreenFills[fillKey];
		if (!offscreen) {
			offscreen = document.createElement('canvas');
			offscreen.width = chart.canvas.width;
			offscreen.height = chart.canvas.height;
		}
		const view = args.meta.$filler.el._view;
		const colorWas = view.backgroundColor;
		view.backgroundColor = dataset.rgba(1);
		const offCtx = offscreen.getContext('2d');
		const was = chart.ctx;
		chart.ctx = offCtx;
		originalBeforeDatasetDraw(chart, args);
		chart.ctx = was;
		view.backgroundColor = colorWas;
		offscreenFills[fillKey] = offscreen;
	}
	if (args.index === 0) {
		// the last dataset, now draw the offscreen canvases at low opacity
		for (const k of Object.keys(offscreenFills)) {
			chart.ctx.save();
			chart.ctx.globalAlpha = chart.options.plugins.filler.spreadOpacity || 0.1;
			chart.ctx.drawImage(offscreenFills[k], 0, 0);
			chart.ctx.restore();
		}
	}
};

fillerPlugin.afterDatasetsDraw = function (chart) {
	offscreenFills = {};
};
